<template>
    <div id="dashboard">
        <div v-show="!details" class="px-0 mx-0" style="height:100%; width:100%;">
            <DefaultPageComponent
            :loading="loading"
            :title="positionDate ? 'FGC - Data posição: '+formatDateBR(positionDate) : 'FGC'"
            :haveBackButton="true"
            @onBack="$router.push({name: 'dashboard'})"
            :haveContentMargin="true"
            >

            <template #filters>
                <b-row class="mx-0">
                    <b-col class="px-1">
                        <div class="form-group" :style="!advisor.id ? 'opacity:0.5;' : ''">
                            <label for="">Visão:</label>
                            <select :disabled="fieldFilters.type"
                            class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;" v-model="filterBy" @change="advisorId = ''; cellId = ''; getCells(); getAdvisors();">
                                <option value="company" v-if="user.role == 1">WFLOW</option>
                                <option value="cell">Equipe</option>
                                <option value="advisor" v-if="user.role == 1">Assessor</option>
                                <option value="finder" v-if="user.hunter_ids.length > 1">Finder</option>
                            </select>
                        </div>
                    </b-col>
                    <b-col class="px-1">
                        <div class="form-group" :style="!advisor.id ? 'opacity:0.5;' : ''">
                            <label for="">Equipe:</label>
                            <select :disabled="fieldFilters.team || filterBy === 'finder'"
                            class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;" v-model="cellId" @change="advisorId = ''; getAdvisors(cellId);">
                                <option value="">Selecione</option>
                                <option v-for="cell in cells" :key="cell.id" :value="cell.id">{{ cell.cell }}</option>
                            </select>
                        </div>
                    </b-col>
                    <b-col class="px-1">
                        <div class="form-group" :style="!advisor.id ? 'opacity:0.5;' : ''">
                            <label for="">Assessor:</label>
                            <select :disabled="fieldFilters.advisor"
                            class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;" v-model="advisor" @change="getSelectOptions(advisor.id)">
                                <option :value="null">Selecione</option>
                                <option v-for="advisor in advisors" :key="advisor.id" :value="advisor">{{ advisor.first_name + " " + advisor.last_name }} </option>
                            </select>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mx-0 mt-4">
                    <b-col class="px-1" style="max-width:10%">
                        <div v-if="!advisor.id" @click="verifyAdvisor" style="width:100%; height:100%; position:absolute; z-index:99999;"></div>
                        <div class="form-group" :style="!advisor.id ? 'opacity:0.5;' : ''">
                            <label for="">Cliente:</label>
                            <select @change="getIssuers(customer)" @click="verifyAdvisor"
                            v-model="customer" :disabled="!advisor.id" class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;">
                                <option :value="null"> Todos </option>
                                <option :value="item.CUSTOMER" v-for="(item,idx) in customers" :key="idx"> {{item.CUSTOMER}} </option>
                            </select>
                        </div>
                    </b-col>
                    <b-col class="px-1">
                        <div v-if="!advisor.id" @click="verifyAdvisor" style="width:100%; height:100%; position:absolute; z-index:99999;"></div>
                        <div class="form-group" :style="!advisor.id ? 'opacity:0.5;' : ''">
                            <label for="">Emissor:</label>
                            <select @change="getCustomers(issuer)"  @click="verifyAdvisor"
                            v-model="issuer" :disabled="!advisor.id" class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;">
                                <option :value="null"> Todos </option>
                                <option :value="item.ISSUER" v-for="(item,idx) in issuers" :key="idx"> {{item.ISSUER}} </option>
                            </select>
                        </div>
                    </b-col>
                    <b-col class="px-1" style="max-width:13%">
                        <div v-if="!advisor.id" @click="verifyAdvisor" style="width:100%; height:100%; position:absolute; z-index:99999;"></div>
                        <InputDatePicker
                        :inputId="'inputDueDateStart'"
                        @click="verifyAdvisor"
                        @input="(value)=>{ dueDateStart=value; getMinDate(dueDateStart) }"
                        :style="!advisor.id ? 'opacity:0.5;' : ''"
                        label="Data de vencimento de:"
                        placeholder="Selecione"
                        :value="dueDateStart"
                        :initialDate="dueDateEnd"
                        :maxDate="maxDate"
                        :disabled="!advisor.id"
                        />
                        <!-- <div class="form-group" :style="!advisor.id ? 'opacity:0.5;' : ''">
                            <label for="">Data de vencimento de:</label>
                            <input @change="getMinDate(dueDateStart)" v-model="dueDateStart" type="date" @click="verifyAdvisor" :disabled="!advisor.id" class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;"
                            :max="maxDate"/>
                        </div> -->
                    </b-col>
                    <b-col class="px-1" style="max-width:13%">
                        <div v-if="!advisor.id" @click="verifyAdvisor" style="width:100%; height:100%; position:absolute; z-index:99999;"></div>
                        <InputDatePicker
                        :inputId="'inputDueDateEnd'"
                        @click="verifyAdvisor"
                        @input="(value)=>{ dueDateEnd=value; getMaxDate(dueDateEnd) }"
                        :style="!advisor.id ? 'opacity:0.5;' : ''"
                        label="Data de vencimento até:"
                        placeholder="Selecione"
                        :value="dueDateEnd"
                        :initialDate="dueDateStart"
                        :minDate="minDate"
                        :disabled="!advisor.id"
                        />
                        <!-- <div class="form-group" :style="!advisor.id ? 'opacity:0.5;' : ''">
                            <label for="">Data de vencimento até:</label>
                            <input @change="getMaxDate(dueDateEnd)" v-model="dueDateEnd" type="date" @click="verifyAdvisor" :disabled="!advisor.id" class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;"
                            :min="minDate"/>
                        </div> -->
                    </b-col>
                    <b-col class="px-1 d-flex justify-content-end" style="max-width:240px;">
                        <GlassButton
                        style="width:100%;"
                        :loading="loading"
                        :disabled="!advisor.id"
                        @click="applyFilter"
                        :width="'100%'"
                        > 
                            <img src="@/assets/search.png" />
                            Pesquisar
                        </GlassButton>
                        <!-- <div class="form-group">
                            <button 
                                style="min-width: 100%; min-height: 56px; font-size: 16px; font-weight: 600; color: #EB694A; background: rgba(235, 105, 74, 0.12); border: none; border-radius: 10px;"
                                @click="applyFilter"
                            >
                                <span v-if="!loading">
                                    <img src="@/assets/search.png" />
                                    Pesquisar
                                </span>
                                <span v-else>
                                    <b-spinner small />
                                </span>
                            </button>
                        </div> -->
                    </b-col>
                    <!-- <b-col class="px-1">
                        <div class="form-group">
                            <label for="">Data da posição:</label>
                            <input :value="formatDateBR(positionDate)" type="text" class="form-control form-control-sm" id="inputFilterDate" style="box-shadow: none; min-height:56px; text-align:end; padding-right:20px;" disabled>
                        </div>
                    </b-col> -->
                    <!-- <b-col class="d-flex flex-column">
                        <label for="">Assessor:</label>
                        <select class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; max-width: 240px;" v-model="advisor.id" @change="getFGC()">
                            <option value="">Selecione</option>
                            <option v-for="advisor in advisors" :key="advisor.id" :value="advisor.id">{{ advisor.first_name + " " + advisor.last_name }} </option>
                        </select>
                    </b-col>
                    <div class="d-flex flex-column mr-3">
                        <label for="">Data da posição:</label>
                        <input type="text" class="form-control form-control-sm" id="inputFilterDate" style="box-shadow: none; max-width: 240px;" disabled>
                    </div> -->
                </b-row>
            </template>

            <!-- CONTENT -->
            <b-table class="custom-table" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" borderless sticky-header :fields="columns" :items="diversifications" sort-icon-left show-empty>
                <template #empty="">
                    <b-col v-if="advisor.id || loading" class="text-center">
                        Não há dados disponíveis
                    </b-col>
                    <b-col v-else class="text-center">
                        Não há um assessor vinculado a esse usuário
                    </b-col>
                </template>
                <template #head(ADVISOR)="data">
                    <b-col class="text-left p-0">
                        {{data.label}}
                    </b-col>
                </template>
                <template #head(CUSTOMER)="data">
                    <b-col class="text-right p-0" style="min-width:max-content;">
                        {{data.label}}
                        <b-icon icon="arrow-up-short" :style="'transition:0.25s;' + (sortBy==data.column&&!sortDesc ? 'rotate: 180deg;' : '')"></b-icon>
                    </b-col>
                </template>
                <template #head(PRODUCT)="data">
                    <b-col class="text-left p-0">
                        {{data.label}}
                    </b-col>
                </template>
                <template #head(SUBPRODUCT)="data">
                    <b-col class="text-left p-0">
                        {{data.label}}
                    </b-col>
                </template>
                <template #head(DUE_DATE)="data">
                    <b-col class="text-right p-0" style="min-width:max-content;">
                        {{ data.label }}
                        <b-icon icon="arrow-up-short" :style="'transition:0.25s;' + (sortBy==data.column&&!sortDesc ? 'rotate: 180deg;' : '')"></b-icon>
                    </b-col>
                </template>
                <template #head(ISSUER)="data">
                    <b-col class="text-left p-0" style="min-width:max-content;">
                        {{data.label}}
                        <b-icon icon="arrow-up-short" :style="'transition:0.25s;' + (sortBy==data.column&&!sortDesc ? 'rotate: 180deg;' : '')"></b-icon>
                    </b-col>
                </template>
                <template #head(AMOUNT)="data">
                    <b-col class="text-right p-0">
                        {{data.label}}
                        <b-icon icon="arrow-up-short" :style="'transition:0.25s;' + (sortBy==data.column&&!sortDesc ? 'rotate: 180deg;' : '')"></b-icon>
                    </b-col>
                </template>
                <template #head(FGC)="data">
                    <b-col class="text-right p-0">
                        {{data.label}}
                    </b-col>
                </template>
                <template #head(actions)="data">
                    <b-col class="text-center p-0">
                        {{data.label}}
                    </b-col>
                </template>


                <!-- <template #cell(CUSTOMER)="row">
                    <b-col class="text-right p-0">
                        {{row.item.CUSTOMER}}
                    </b-col>
                </template> -->
                <!-- <template #cell(DUE_DATE)="row">
                    <b-col class="text-right p-0">
                        {{ formatDateBR(row.item.DUE_DATE) }}
                    </b-col>
                </template> -->
                <template #cell(AMOUNT)="row">
                    <b-col class="text-right p-0">
                        {{ 'R$' }} {{ Number(row.item.AMOUNT).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                    </b-col>
                </template>
                <template #cell(FGC)="row">
                    <b-col class="text-right p-0">
                        {{ 'R$' }} {{ Number(row.item.FGC).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                    </b-col>
                </template>
                <template #cell(actions)="row">
                    <b-row class="m-0" align-h="center">
                        <b-button 
                            style="color: #EB694A; width: 39px; height: 40px; background-color: #242323; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252; box-shadow: none;"
                            @click="showDetails(row.item)"
                        >
                            <b-icon icon="eye-fill"></b-icon>
                        </b-button>
                    </b-row>
                </template>
            </b-table>

            <template #footer>
                <div></div>
            </template>

            </DefaultPageComponent>

        </div>
        <FGCDetailsView v-if="details"
        :selected="selected"
        :dueDateStart="dueDateStart"
        :dueDateEnd="dueDateEnd"
        @back="details=false;"
        />
    </div>
</template>

<script>
import FGCDetailsView from './FGCDetailsView.vue';
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import GlassButton from '@/components/GlassButton.vue';
import InputDatePicker from '@/components/InputDatePicker.vue';
import { mapState } from 'vuex';
export default {
    name: 'FGCView',
    components:{
        DefaultPageComponent,
        FGCDetailsView,
        GlassButton,
        InputDatePicker,
    },
    data () {
        return {
            details: false,
            sortBy: null,
            sortDesc: null,
            selected: {},
            filterBy: 'advisor',
            diversifications: [],
            columns: [
                {
                    'ADVISOR': 
                    {
                        label: 'Assessor',
                        class: 'text-left',
                        sortable: false,
                        // thStyle: {
                        //     width: '20%'
                        // }
                    }
                },
                {
                    'CUSTOMER': 
                    {
                        label: 'Cliente',
                        class: 'text-right',
                        sortable: true,
                        // thStyle: {
                        //     width: '20%'
                        // }
                    }
                },
                {
                    'PRODUCT': 
                    {
                        label: 'Produto',
                        class: 'text-left',
                        sortable: false,
                        // thStyle: {
                        //     width: '10%'
                        // }
                    }
                },
                {
                    'SUBPRODUCT': 
                    {
                        label: 'Subproduto',
                        class: 'text-left',
                        sortable: false,
                        // thStyle: {
                        //     width: '20%'
                        // }
                    }
                },
                {
                    'DUE_DATE': 
                    {
                        label: 'Data Vencimento',
                        class: 'text-right',
                        sortable: true,
                        formatter: (value) =>{
                            if(value) return this.formatDateBR(value);
                            else return value;
                        }
                        // thStyle: {
                        //     width: '10%'
                        // }
                    }
                },
                {
                    'ISSUER': 
                    {
                        label: 'Emissor',
                        class: 'text-left',
                        sortable: true,
                        // thStyle: {
                        //     width: '10%'
                        // }
                    }
                },
                {
                    'AMOUNT': 
                    {
                        label: 'Saldo',
                        class: 'text-right',
                        sortable: true,
                        // thStyle: {
                        //     width: '10%'
                        // }
                    }
                },
                {
                    'FGC': 
                    {
                        label: 'FGC',
                        class: 'text-right',
                        sortable: false,
                        // thStyle: {
                        //     width: '10%'
                        // }
                    }
                },
                {
                    'actions': 
                    {
                        label: 'Ações',
                        class: 'text-center',
                        sortable: false,
                    }
                },
            ],
            positionDate: '',
            customers: [],
            issuers: [],
            customer: '',
            issuer: '',
            dueDateStart: '',
            dueDateEnd: '',
            advisors: [],
            cellId: '',
            advisor: {},
            loading: false,
            minDate: null,
            maxDate: null,
            fieldFilters: {
                type: true,
                team: true,
                advisor: true,
                fee: true,
                period: true,
            },
            cells: [],
        }
    },
    async mounted(){
        this.saveLog();
        this.removeClass();
        await this.getAdvisors('');
        await this.getCells();
        const intervalId = setInterval(() => {
            if(this.user){
                clearInterval(intervalId);
                this.getUserAdvisor();
                this.getSelectOptions();
                if(!this.advisor){
                    return this.$swal({
                        title: 'Atenção',
                        text: 'Não há um assessor vinculado a esse usuário',
                        icon: 'warning',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#28A745',
                        allowOutsideClick: false,
                        customClass: {
                            confirmButton: 'custom-confirm-button-class',
                        }
                    });
                }
                if (!this.user.role) {
                    if (this.user.advisor_id) {
                        this.filterBy = 'cell';
                        this.cellId = this.user.cell_id;
                        this.advisorId = this.user.advisor_id;
                        this.fieldFilters.fee = false;
                        this.fieldFilters.period = false;
                        if (this.user.cells_leader_id.length > 0) {
                            this.fieldFilters.team = false;
                            this.cells = this.cells.filter(e => this.user.cells_leader_id.includes(e.id) || e.id == this.user.cell_id);

                            if (this.user.cells_leader_id.includes(this.user.cell_id)) {
                                this.fieldFilters.advisor = false;
                            }

                            if (this.cellId > 0) {
                                this.getAdvisors(this.cellId);
                            }
                        }

                        if (this.user.hunter_ids.length > 0) {
                            this.fieldFilters.type = false;
                        }
                    } else {
                        this.filterBy = 'cell';
                        this.fieldFilters.type = true;
                        this.fieldFilters.team = true;
                        this.fieldFilters.advisor = true;
                        this.fieldFilters.fee = true;
                        this.fieldFilters.period = true;
                        return this.$swal({
                            title: 'Atenção',
                            text: 'Não há um assessor vinculado a esse usuário',
                            icon: 'warning',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#28A745',
                            allowOutsideClick: false,
                        });
                    }
                } else {
                    if (this.user.advisor_id) {
                        this.filterBy = 'cell';
                        this.cellId = this.user.cell_id;
                        if (this.cellId > 0) {
                            this.getAdvisors(this.cellId);
                        }
                        this.advisorId = this.user.advisor_id;
                        this.fieldFilters.type = false;
                        this.fieldFilters.team = false;
                        this.fieldFilters.advisor = false;
                        this.fieldFilters.fee = false;
                        this.fieldFilters.period = false;

                        
                    } else {
                        this.filterBy = 'company';
                        this.fieldFilters.type = false;
                        this.fieldFilters.team = false;
                        this.fieldFilters.advisor = false;
                        this.fieldFilters.fee = false;
                        this.fieldFilters.period = false;
                    }
                }
                this.applyFilter();
            }
        }, 0);
    },
    computed: {
        ...mapState('user', {
            user: (state) => state.user,
        }),
    },
    methods: {
        getUserAdvisor(){ this.advisor = this.advisors.find(e=>e.id==this.user.advisor_id) },
        removeClass() {
            setInterval(()=>{
                const allElements = document.querySelectorAll('*');
                allElements.forEach(element => {
                    if (element.classList.contains('position-relative') && !element.classList.contains('b-overlay-wrap')){
                        element.classList.remove('position-relative');
                    }
                });
            }, 250)
        },
        showDetails(item){
            this.selected = item;
            this.selected.CUSTOMER_OBJ = {
                id: item.CUSTOMER,
                name: item.CUSTOMER_NAME
            }
            this.selected.ADVISOR_OBJ = this.advisor;
            this.selected.AMOUNT_FORMAT = 'R$ ' + Number(item.AMOUNT).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            this.selected.FGC_FORMAT = 'R$ ' + Number(item.FGC).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

            this.details=true;
        },

        async getPositionDate(){
            this.$axios.get('fgc/position-date?advisor='+this.advisor.id)
            .then((response)=>{this.positionDate = response.data.positionDate;})
        },
        async getSelectOptions(){
            if(!this.advisor.id) return;
            this.$axios.get('fgc/position-date?advisor='+this.advisor.id)
            .then((response)=>{this.positionDate = response.data.positionDate})
            this.$axios.get('fgc/customers?advisor='+this.advisor.id)
            .then((response)=>{this.customers = response.data.customers})
            this.$axios.get('fgc/issuers?advisor='+this.advisor.id)
            .then((response)=>{this.issuers = response.data.issuers})
            
            this.customer = null;
            this.issuer = null;
        },
        async getCustomers(issuer){
            if(!issuer) return;
            this.$axios.get('fgc/customers?advisor='+this.advisor.id+'&issuer='+issuer)
            .then((response)=>{this.customers = response.data.customers})
        },
        async getIssuers(customer){
            if(!customer) return;
            this.$axios.get('fgc/issuers?advisor='+this.advisor.id+'&customer='+customer)
            .then((response)=>{this.issuers = response.data.issuers})
        },
        
        verifyAdvisor(){
            if(!this.advisor?.id) {
                this.$swal({
                    title: 'Assessor não selecionado',
                    text: 'Para utilizar os demais filtros é necessário a seleção de um assessor',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return false;
            }
            else return true;
        },

        async applyFilter(){
            if(!this.verifyAdvisor()) return;

            if((!this.dueDateStart && this.dueDateEnd) || (this.dueDateStart && !this.dueDateEnd)) {
                this.$swal({
                    title: 'Datas não selecionadas',
                    text: 'Para realizar a pesquisa, é necessário que ambas as datas ("Data de vencimento de" e "Data de vencimento até") sejam informadas ou que nenhuma delas seja informada.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            }

            try{
                this.loading=true;

                var queries = '';
                var listQuery = [];

                if(this.advisor.id || this.customer || this.issuer || (this.dueDateStart && this.dueDateEnd)) 
                    queries += '?';
                
                if(this.advisor.id)
                    listQuery.push('advisor='+this.advisor.id);

                if(this.customer)
                    listQuery.push('customer='+this.customer);
            
                if(this.issuer)
                    listQuery.push('issuer='+this.issuer);

                if(this.dueDateStart && this.dueDateEnd){
                    listQuery.push('due_date_start='+this.dueDateStart);
                    listQuery.push('due_date_end='+this.dueDateEnd);
                }

                queries += listQuery.join('&');
                
                const response = await this.$axios.get('fgc' + queries);
                this.diversifications = response.data.diversifications;
            }
            catch(error){
                error;
            }
            finally{ this.loading=false; }
        },
        
        async getMinDate(date){
            this.minDate = new Date(date).toISOString().split('T')[0];
        },
        async getMaxDate(date){
            this.maxDate = new Date(date).toISOString().split('T')[0];
        },

        // getFGC () {
        //     let url = "fgc";
        //     if (this.cellId != '' && this.filterBy == 'cell') {
        //         url += `?cell_id=${this.cellId}`;
        //     }

        //     if (this.advisor.id != '' && this.filterBy == 'advisor') {
        //         url += `?advisor_id=${this.advisor.id}`;
        //     }

        //     if(url != "fgc") {
        //         this.$axios.get(url).then((response) => {
        //             this.diversifications = response.data.diversifications;
        //             const date = this.diversifications[0].POSITION_DATE;
        //             const [year, month, day] = date.split("-");
        //             this.positionDate = `${day}/${month}/${year}`;
        //         });
        //     }
        // },
        async getAdvisors (cellId = '') {
            const user = {...this.user};
            const response = await this.$axios.get('advisors')
            this.advisors = response.data.data;
            if (cellId != '')
                this.advisors = this.advisors.filter(advisor => {
                    return advisor.cellHistories.length > 0 && advisor.cellHistories[0].cell.id === cellId
                });
            if (user.hunter_ids.length > 0 && this.filterBy == 'finder')
                this.advisors = this.advisors.filter(advisor => {
                    return user.hunter_ids.includes(advisor.id)
                });
                this.fieldFilters.advisor = false;

            if (cellId != '' && user.cells_leader_id.includes(cellId)) {
                this.fieldFilters.advisor = false;
            } else {
                if (!user.role && this.filterBy !== 'finder') {
                    this.fieldFilters.advisor = true;
                    this.advisorId = user.advisor_id;
                }
            }
        },
        formatDateBR(dateString) {
            if (!dateString) {
                return '';
            }

            const [year, month, day] = dateString.split('-');
            const formattedDate = `${day}/${month}/${year}`;

            return formattedDate;
        },
        saveLog () {
            let objLog = {
                description: 'FGC'
            }

            this.$axios.post('logs', objLog).then((response) => {
                if(response.status != 201) {
                    console.log(response);
                }
            }).catch((error) => {
                console.log(error);
            });
        },
        async getCells () {
            const user = {...this.user};
            const response = await this.$axios.get('cells');
            this.cells = response.data.data;

            if (this.filterBy === 'finder' || !user.role) {
                this.cellId = user.cell_id;
                this.getAdvisors(this.cellId);
            }

            if (this.filterBy === 'cell') {
                this.getUserAdvisor();
            }
        },
    }
}
</script>

<style>
    #dashboard {
        height: calc(100vh - 125px);
    }

    .form-control {
        background-color: #262625 !important;
        border: 1px solid #535252 !important;
        color: #E5E2E1 !important;
        border-radius: 10px !important;
    }

    table thead tr th {
        background: #535252 !important;
        color: #F3F0EF !important;
        padding: 2px 20px !important;
        font-size: 20px;
        font-weight: 600;
        text-align: right;
    }

    table thead tr th:first-child {
        /* border-radius: 10px 0px 0px 0px !important; */
        text-align: left;
    }

    /* table thead tr th:last-child {
        border-radius: 0px 10px 0px 0px !important;
    } */

    table tbody tr td {
        background-color: #242323 !important;
        color: #F3F0EF !important;
        padding: 0px 20px !important;
        padding-top: 15px !important;
        font-weight: 600 !important;
    }

    #tableDetalhes tbody tr td {
        background-color: #242323f0 !important;
    }

    table tbody tr {
        border-bottom: 1px solid #535252 !important;
    }

    table tbody tr:last-child {
        border-bottom: 0px solid #535252 !important;
    }

    table tbody tr th {
        background-color: #363636f0 !important;
        color: #F3F0EF !important;
        /* padding: 0px 20px !important; */
        padding-top: 15px !important;
        font-weight: 600 !important;
    }
</style>
