<template>
    <div>
        <form class="form-signin mt-4" style="background: #000; border-radius: 8px; border-width: 4px; border-style: solid; border-color: #AC5037;">
            <div class="text-center mb-3 mt-3">
                <img class="mb-2" src="@/assets/logo.svg" alt="Logo WFLOW" height="48">
            </div>
            <div style="margin-left: 12px; height:22px; color: #C7300C;">
                {{ showMessageError ? 'E-mail e/ou senha inválido(s)' : '' }}
            </div>
            <div class="form-label-group">
                <input id="inputEmail" ref="inputEmail" type="email" class="form-control" v-model="credentials.email" :disabled="loading">
                <label for="inputEmail">E-mail</label>
            </div>
            <div class="form-label-group">
                <input id="inputPassword" :type="visible ? 'text' : 'password'" class="form-control" v-model="credentials.password" :disabled="loading">
                <label for="inputPassword">Senha</label>
                <b-button variant="link" @click="()=>{ visible=!visible; }"
                style="color:black; position:absolute; top:6px; right:5px;">
                    <b-icon :icon="visible ? 'eye' : 'eye-slash'"></b-icon>
                </b-button>
            </div>
            <div class="checkbox ml-4 mb-3 d-flex align-items-center" style="color: #FFF; font-size: 10px;">
                <input v-if="false" type="checkbox" class="form-check-input" id="remember">
                <label v-if="false" for="remember" class="form-check-label ml-1 mt-1" style="cursor: pointer;">Lembrar-me</label>
            </div>
            <button style="background: #AC5037; color: white" class="btn btn-lg btn-block" @click="handleSubmit" :disabled="loadingSubmit">
                <template v-if="loadingSubmit">
                    <b-spinner small class="mr-2" />
                </template>
                <template v-else>
                    Acessar
                </template>
            </button>
            <div class="checkbox mb-3 text-center mt-4">
                <b-link to="/recover">
                    Esqueci minha senha
                </b-link>
                <!-- <a @click="" title="Esqueci minha senha" style="cursor:pointer; color: #2190FF; text-decoration: none;">
                    Esqueci minha senha
                </a> -->
            </div>
            <p class="mt-5 mb-3 text-center" style="color: #DEDEDE; font-size: 12px;">© WFLOW - {{ new Date().getFullYear() }} <br>
                wflow@wflowinvest.com.br
            </p>
        </form>
    </div>
</template>

<script>
import Cookie from '@/service/cookie'

export default {
    name: 'LoginView',
    data() {
        return {
            loading: true,
            loadingSubmit: false,
            credentials: {
                email: "",
                password: "",
            },
            visible: false,
            showMessageError: false,
        };
    },
    created() {
        setTimeout(() => {
            this.loading = false;
            this.credentials.email = "";
            this.credentials.password = "";
            this.$nextTick(() => {
                if(this.$refs.inputEmail)
                    this.$refs.inputEmail.focus();
            });
        }, 1000);
    },
    methods: {
        handleSubmit() {
            this.showMessageError = false;
            this.loadingSubmit = true;

            this.$axios.post('login', this.credentials).then((response) => {
                const token = `${response.data.token_type} ${response.data.access_token}`;
                Cookie.setToken(token);
                this.$store.commit('user/STORE_USER', response.data.data);
                this.saveLog();
                this.$router.push({ name: 'dashboard' });
            }).catch(() => {
                this.loadingSubmit = false;

                this.showMessageError = true;
            });
        },
        saveLog () {
            let objLog = {
                description: 'Login'
            }

            this.$axios.post('logs', objLog).then((response) => {
                if(response.status != 201) {
                    console.log(response);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    },
}
</script>

<style>
    .form-signin {
        width: 370px;
        height: 500px;
        padding: 15px;
        margin: auto;
    }

    .form-label-group {
        position: relative;
        margin-bottom: 1rem;
    }

    .form-label-group>input,
    .form-label-group>label {
        height: 3.125rem;
        padding: .75rem;
    }

    .form-label-group>label {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        margin-bottom: 0;
        line-height: 1.5;
        color: #FFF;
        pointer-events: none;
        cursor: text;
        border: 1px solid transparent;
        border-radius: .25rem;
        transition: all .1s ease-in-out;
    }

    .form-label-group input::-webkit-input-placeholder {
        color: transparent;
    }

    .form-label-group input:-ms-input-placeholder {
        color: transparent;
    }

    .form-label-group input::-moz-placeholder {
        color: transparent;
    }

    .form-label-group input::placeholder {
        color: transparent;
    }

    .form-label-group input:not(:placeholder-shown) {
        padding-top: 1.25rem;
        padding-bottom: .25rem;
    }

    .form-label-group input:not(:placeholder-shown)~label {
        padding-top: .25rem;
        padding-bottom: .25rem;
        font-size: 12px;
        color: #FFF;
    }

    .form-label-group>input {
        background-color: #FFF !important;
        color: #000 !important;
        border: 2px solid #5F5F5F !important;
        box-shadow: none !important;
    }

    .form-label-group>label {
        color: #000 !important;
    }

    @supports (-ms-ime-align: auto) {
        .form-label-group>label {
            display: none;
        }

        .form-label-group input::-ms-input-placeholder {
            color: #FFF;
        }
    }

    @media all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
        .form-label-group>label {
            display: none;
        }

        .form-label-group input:-ms-input-placeholder {
            color: #FFF;
        }
    }
</style>
