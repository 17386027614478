<template>
    <div class="card-header d-flex align-items-center justify-content-between mx-4 px-0 py-0 pb-3" style="border-bottom: 1px solid #535252">
        <div class="form-group row m-0">
            <b-col class="p-0 mr-2" :class="subtitle ? 'mt-1' : ''" style="max-width:fit-content;" align-self="center">
                <a v-if="backButton" @click="$emit('onBack')" class="px-1" style="font-size: 20px; line-height:20px; cursor: pointer; border:1px solid white; color:white; border-radius:4px; margin-right:10px;">
                    <b-icon icon="arrow-left-short"></b-icon>
                </a>
            </b-col>
            <b-col cols="8">
                <b-row>
                    <span style="font-weight:600; font-size:20px;" v-html="title"></span>
                </b-row>
                <b-row>
                    <span style="font-weight:400; font-size:14px;">{{ subtitle }}</span>
                </b-row>
            </b-col>
        </div>

        <slot></slot>

        <div v-if="nextButton" class="form-group mb-0" style="max-width:260px;">
            <!-- :loading="loading" -->
            <CustomButton
            :disabled="disabled"
            @click="$emit('onTap')"
            :width="'100%'"
            > {{ nextButtonText }}
            </CustomButton>
        </div>
    </div>
</template>

<script>
import CustomButton from './CustomButton.vue'
export default {
    name: 'DefaultHeader',
    components:{
        CustomButton,
    },
    props:{
        title: String,
        subtitle: String,
        backButton: Boolean,
        backRoute: String,
        nextButton: Boolean,
        nextButtonText: String,
        nextRoute: String,
        disabled: Boolean,
        loading: Boolean,
    }
}
</script>

<style>

</style>