<template>
    <div id="customer">
        
        <DefaultPageComponent
        :loading="loading"
        :title="'Novo cliente'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'customer'})"
        >

        <!-- CONTENT -->
        <b-col class="py-3">
            <b-row>
                <b-col>
                    <span style="font-size: 24px; font-wight: 700;">Dados pessoais</span>
                </b-col>
            </b-row>
            <b-row class="my-3">
                <div class="form-group">
                    <b-col>
                        <label for="inputCPF">CPF / CNPJ</label>
                        <input @input="validateDocument(customer.n_document)" type="text" class="form-control form-control-sm" id="inputCPF" style="box-shadow: none; height: 56px;" v-mask="['###.###.###-##', '##.###.###/####-##']" v-model="customer.n_document">
                        <div v-if="!customer.n_document" style="font-size:14px;"><span style="color:red;"> Campo obrigatório. </span></div>
                        <div v-else-if="!isDocumentValid" style="font-size:14px;"><span style="color:red;"> CPF / CNPJ inválido. </span></div>
                    </b-col>
                </div>
            </b-row>
            <b-row class="my-3">
                <div class="d-flex w-100">
                    <div class="form-group">
                        <b-col>
                            <label for="inputFirstName">Nome</label>
                            <input type="text" class="form-control form-control-sm" id="inputFirstName" style="box-shadow: none; height: 56px;" v-model="customer.first_name">
                            <div v-if="!customer.first_name" style="font-size:14px;"><span style="color:red;"> Campo obrigatório. </span></div>
                        </b-col>
                    </div>
                    <div class="form-group">
                        <b-col>
                            <label for="inputLastName">Sobrenome</label>
                            <input type="text" class="form-control form-control-sm" id="inputLastName" style="box-shadow: none; height: 56px;" v-model="customer.last_name">
                            <div v-if="!customer.last_name" style="font-size:14px;"><span style="color:red;"> Campo obrigatório. </span></div>
                        </b-col>
                    </div>
                </div>
            </b-row>
            <b-row class="my-3">
                <div class="d-flex w-100">
                    <div class="form-group">
                        <b-col>
                            <InputDatePicker
                            :inputId="'inputBirthDate'"
                            @input="(value)=>{ customer.birthday=value; }"
                            label="Data de nascimento"
                            placeholder="Selecione"
                            :value="customer.birthday"
                            :maxDate="maxDate"
                            />
                            <!-- <label for="">Data de nascimento</label>
                            <input type="date" class="form-control form-control-sm" id="inputUserName" style="box-shadow: none; height: 56px;" v-model="customer.birthday"> -->
                        </b-col>
                    </div>
                    <div class="form-group">
                        <b-col>
                            <label for="inputBank">Banco</label>
                            <select class="form-control form-control-sm" id="inputBank" style="box-shadow: none; height: 56px;" v-model="customer.bank_id">
                                <option value="null" key=""></option>
                                <option v-for="bank in banks" :value="bank.id" :key="bank.id">{{ bank.code }} - {{ bank.name }}</option>
                            </select>
                        </b-col>
                    </div>
                </div>
            </b-row>
            <b-row class="my-3">
                <div class="d-flex w-100">
                    <b-row class="m-0 w-50">
                        <b-col class="form-group">
                            <label for="inputEmail">E-mail</label>
                            <input type="text" class="form-control form-control-sm" id="inputEmail" style="box-shadow: none; height: 56px;" v-model="customer.email">
                        </b-col>
                    </b-row>
                    <b-row class="m-0 w-50">
                        <b-col class="form-group" style="max-width:30%;">
                            <label for="inputPhone">Telefone</label>
                            <input type="text" class="form-control form-control-sm" id="inputPhone" style="box-shadow: none; height: 56px;" v-mask="['(##) ####-####', '(##) #####-####']" v-model="customer.phone_number">
                        </b-col>
                        <b-col class="form-group" style="max-width:70%;">
                            <label for="inputWhatsApp">WhatsApp Grupo</label>
                            <input type="text" class="form-control form-control-sm" id="inputWhatsApp" style="box-shadow: none; height: 56px;" v-model="customer.group_name">
                        </b-col>
                    </b-row>
                </div>
            </b-row>
            <b-row class="my-3">
                <b-col>
                    <span style="font-size: 24px; font-wight: 700;">Endereço</span>
                </b-col>
            </b-row>
            <b-row class="my-3">
                <div class="d-flex w-100">
                    <div class="form-group flex-grow-1">
                        <b-col>
                            <label for="inputCep">CEP</label>
                            <input type="text" class="form-control form-control-sm" id="inputCep" style="box-shadow: none; height: 56px;" v-mask="'##.###-###'" v-model="customer.zip_code" @blur="searchZipCode">
                        </b-col>
                    </div>
                    <div class="form-group flex-grow-1">
                        <b-col>
                            <label for="inputAddress">Endereço</label>
                            <input type="text" class="form-control form-control-sm" id="inputAddress" style="box-shadow: none; height: 56px;" v-model="customer.address">
                        </b-col>
                    </div>
                    <div class="form-group" style="width: 870px;">
                        <b-col>
                            <label for="inputAddressNumber">Número</label>
                            <input type="text" class="form-control form-control-sm" id="inputAddressNumber" style="box-shadow: none; height: 56px;" v-model="customer.number">
                        </b-col>
                    </div>
                </div>
            </b-row>
            <b-row class="my-3">
                <div class="d-flex w-100">
                    <div class="form-group flex-grow-1">
                        <b-col>
                            <label for="inputDistrict">Bairro</label>
                            <input type="text" class="form-control form-control-sm" id="inputDistrict" style="box-shadow: none; height: 56px;" v-model="customer.neighborhood">
                        </b-col>
                    </div>
                    <div class="form-group flex-grow-1">
                        <b-col>
                            <label for="inputCity">Cidade</label>
                            <input type="text" class="form-control form-control-sm" id="inputCity" style="box-shadow: none; height: 56px;" v-model="customer.city">
                        </b-col>
                    </div>
                    <div class="form-group" style="width: 570px;">
                        <b-col>
                            <label for="inputUF">UF</label>
                            <select class="form-control form-control-sm" id="inputUF" style="box-shadow: none; height: 56px;" v-model="customer.state_id">
                                <option value="null" key=""></option>
                                <option v-for="state in states" :value="state.id" :key="state.id">{{ state.acronym }}</option>
                            </select>
                        </b-col>
                    </div>
                </div>
            </b-row>
            <!-- <b-row class="mt-4 py-3 row d-flex align-items-center justify-content-between mb-3 mx-0" style="border-bottom:1px solid #535252">
                <b-col class="p-0">
                    <span style="font-size: 24px; font-wight: 700;">Assessor vinculado</span>
                </b-col>
                <b-col class="d-flex justify-content-end p-0">
                </b-col>
            </b-row>
            <b-row class="my-4">
                <b-col class="form-group">
                    <label for="inputAdvisorBase">Assessor Base</label>
                    <input disabled type="text" class="form-control form-control-sm" id="inputDistrict" style="box-shadow: none; height: 56px;">
                </b-col>
                <b-col class="form-group">
                    <label for="inputAdvisorFinder">Assessor Finder</label>
                    <input disabled type="text" class="form-control form-control-sm" id="inputDistrict" style="box-shadow: none; height: 56px;">
                </b-col>
                <b-col class="form-group">
                    <label for="inputAdvisorFinal">Assessor Final</label>
                    <input disabled type="text" class="form-control form-control-sm" id="inputDistrict" style="box-shadow: none; height: 56px;">
                </b-col>
            </b-row> -->
            <b-row class="mt-4 py-3 row d-flex align-items-center justify-content-between mb-3 mx-0" style="border-bottom:1px solid #535252">
                <b-col class="p-0">
                    <span style="font-size: 24px; font-wight: 700;">Serviços</span>
                </b-col>
                <b-col class="d-flex justify-content-end p-0">
                    <GlassButton
                    :loading="loading"
                    @click="showModalCommercialPartnerForm()"
                    > 
                        <img src="@/assets/add_orange.png" class="mr-2">
                        Adicionar
                    </GlassButton>
                </b-col>
            </b-row>
            <div v-if="renderComponent" class="row d-flex justify-content-center align-items-center">
                <div class="col mt-2">
                    <b-table striped borderless small sticky-header :fields="columns" :items="commercialPartnersInMemory" style="max-height: 60vh !important;" show-empty empty-text="Sem registros para mostrar no momento..." head-variant="dark">
                        <template #cell(actions)="row">
                            <div class="d-flex justify-content-end align-items-center">
                                <b-button 
                                    size="sm"
                                    @click="showModalCommercialPartnerForm(row.item, row.index)" class="px-2"
                                    style="color: #EB694A; border:1px solid #474646; border-radius:4px; box-shadow: none; background-color: transparent; font-size:16px;"
                                >
                                    <b-icon icon="pencil-fill"></b-icon>
                                </b-button>
                                <div style="width:10px;"></div>
                                <b-button 
                                    size="sm"
                                    @click="removeCommercialPartner(row.index)" class="px-2"
                                    style="color: #EB694A; border:1px solid #474646; border-radius:4px; box-shadow: none; background-color: transparent; font-size:16px;"
                                >
                                    <b-icon icon="trash-fill"></b-icon>
                                </b-button>
                            </div>
                        </template>
                    </b-table>
                </div>
            </div>
        </b-col>

        <template #footer>
            <b-row>
                <b-col class="d-flex justify-content-end">
                    <CancelButton
                    @click="$router.push('/customers')"
                    >
                        Cancelar
                    </CancelButton>
                    <CustomButton
                    :loading="loading || loadingSubmit"
                    :disabled="!isFormValid"
                    @click="saveCustomer"
                    > 
                        Salvar
                    </CustomButton>
                </b-col>
            </b-row>
        </template>

        </DefaultPageComponent>
        
        <!-- <div style="position: fixed; top: 30%; left: 20%; width: 60vw; height: min-content; background: #1C1B1B; z-index: 10000; border-radius: 25px;" class="p-4" v-if="modals.addCommercialPartner">
            <b-row>
                <b-col style="font-size: 28px; font-weight: 600;">
                    Adicionar parceiros comerciais
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <div class="d-flex w-100">
                    <div class="form-group flex-grow-1">
                        <b-col>
                            <label for="">Código</label>
                            <input type="text" class="form-control form-control-sm" id="inputUserName" style="box-shadow: none; height: 56px;">
                        </b-col>
                    </div>
                    <div class="form-group flex-grow-1">
                        <b-col>
                            <InputDatePicker
                            :inputId="'inputOpen'"
                            label="Data abertura"
                            placeholder="Selecione"
                            />
                        </b-col>
                    </div>
                    <div class="form-group flex-grow-1">
                        <b-col>
                            <InputDatePicker
                            :inputId="'inputActive'"
                            label="Data ativação"
                            placeholder="Selecione"
                            />
                        </b-col>
                    </div>
                    <div class="form-group" style="width: 200%;">
                        <b-col>
                            <label for="">Nome</label>
                            <select class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;">
                                <option value="null" key=""></option>
                                <option v-for="commercialPartner in commercialPartners" :value="commercialPartner.id" :key="commercialPartner.id">{{ commercialPartner.corporate_name }}</option>
                            </select>
                        </b-col>
                    </div>
                </div>
            </b-row>
            <b-row class="mt-4">
                <b-col class="d-flex justify-content-end">
                    <CancelButton
                    @click="modals.addCommercialPartner = false"
                    > 
                        Cancelar
                    </CancelButton>
                    <CustomButton
                    :loading="loading"
                    @click="saveCustomer"
                    > 
                        Adicionar
                    </CustomButton>
                </b-col>
            </b-row>
        </div>
        <div class="modal-overlay" v-if="modals.addCommercialPartner"></div> -->

        <card-modal :title="type=='post' ? 'Adicionar serviço' : 'Editar serviço'" ref="modalCommercialPartnerForm">
            <div class="row d-flex justify-content-center align-items-center">
                <div class="col mt-2">
                    <b-form @submit.stop.prevent="onSubmitCommercialPartnerForm" >
                        <div class="row d-flex justify-content-left mx-0">
                            <!-- <div class="col-md-2 px-2"> -->
                            <div class="col-md-4 px-2">
                                <b-form-group id="inputGroupCode" label="Código" label-for="inputGroupCode">
                                    <b-form-input
                                        id="inputCommercialPartnerCode"
                                        name="inputCommercialPartnerCode"
                                        v-model="commercialPartner.code"
                                        style="min-height:56px;"
                                        v-validate="{ required: true }"
                                        :state="validateState('inputCommercialPartnerCode')"
                                        aria-describedby="inputCommercialPartnerCodeFeedback"
                                        data-vv-as="Código"
                                        :disabled="loading"
                                    ></b-form-input>

                                    <b-form-invalid-feedback id="inputCommercialPartnerCodeFeedback">{{ veeErrors.first('inputCommercialPartnerCode') }}</b-form-invalid-feedback>
                                </b-form-group>
                            </div>
                            <!-- <div class="col-md-3 px-2">
                                <b-form-group id="inputGroupOpenedDate" label="Data de abertura" label-for="inputGroupOpenedDate">
                                    <b-form-input
                                        id="inputOpenedDate"
                                        name="inputOpenedDate"
                                        type="date"
                                        v-model="commercialPartner.openedDate"
                                        style="min-height:56px;"
                                        v-validate="{ required: true }"
                                        class="form-control"
                                        :state="validateState('inputOpenedDate')"
                                        aria-describedby="inputOpenedDateFeedback"
                                        data-vv-as="Unidade"
                                        :disabled="loading"
                                    >
                                    </b-form-input>

                                    <b-form-invalid-feedback id="inputOpenedDateFeedback">{{ veeErrors.first('inputOpenedDate') }}</b-form-invalid-feedback>
                                </b-form-group>
                            </div>
                            <div class="col-md-3 px-2">
                                <b-form-group id="inputGroupActiveDate" label="Data de ativação" label-for="inputGroupActiveDate">
                                    <b-form-input
                                        id="inputActiveDate"
                                        name="inputActiveDate"
                                        type="date"
                                        v-model="commercialPartner.activeDate"
                                        style="min-height:56px;"
                                        v-validate="{ required: true }"
                                        class="form-control"
                                        :state="validateState('inputActiveDate')"
                                        aria-describedby="inputActiveDateFeedback"
                                        data-vv-as="Unidade"
                                        :disabled="loading"
                                    >
                                    </b-form-input>

                                    <b-form-invalid-feedback id="inputActiveDateFeedback">{{ veeErrors.first('inputActiveDate') }}</b-form-invalid-feedback>
                                </b-form-group>
                            </div> -->
                            <!-- <div class="col-md-4 px-2"> -->
                            <div class="col-md-8 px-2">
                                <b-form-group id="inputGroupCommercialPartnerId" label="Nome" label-for="inputGroupCommercialPartnerId">
                                    <b-form-select
                                        @change="clearAdvisorsInput"
                                        id="inputCommercialPartnerId"
                                        name="inputCommercialPartnerId"
                                        v-model="commercialPartner.id"
                                        style="min-height:56px;"
                                        v-validate="{ required: true }"
                                        class="form-control"
                                        :state="validateState('inputCommercialPartnerId')"
                                        aria-describedby="inputCommercialPartnerIdFeedback"
                                        data-vv-as="Unidade"
                                        :disabled="loading"
                                    >
                                        <b-form-select-option v-for="commercialPartner in commercialPartners" :value="commercialPartner.id" :key="commercialPartner.id">{{ commercialPartner.trade_name }}</b-form-select-option>
                                    </b-form-select>

                                    <b-form-invalid-feedback id="inputCommercialPartnerIdFeedback">{{ veeErrors.first('inputCommercialPartnerId') }}</b-form-invalid-feedback>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-left mx-0 mt-3">
                            <div class="col-md-4 px-2">
                                <b-form-group id="inputGroupAdvisorBaseId" label="Assessor Base" label-for="inputGroupAdvisorBaseId">
                                    <b-form-select
                                        @change="getAdvisorFinderFinals"
                                        id="inputAdvisorBaseId"
                                        name="inputAdvisorBaseId"
                                        v-model="commercialPartner.advisorBase"
                                        style="min-height:56px;"
                                        v-validate="{ required: true }"
                                        class="form-control"
                                        :state="commercialPartner.advisorBase ? true : commercialPartner.id ? false : null"
                                        aria-describedby="inputAdvisorBaseIdFeedback"
                                        data-vv-as="Assessor Base"
                                        :disabled="loading || !commercialPartner.id"
                                    >
                                        <b-form-select-option v-for="item in advisorBases" :value="item" :key="item.id">{{ item.name }}</b-form-select-option>
                                    </b-form-select>

                                    <b-form-invalid-feedback id="inputAdvisorBaseIdFeedback">{{ 'Campo obrigatório.' }}</b-form-invalid-feedback>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 px-2">
                                <b-form-group id="inputGroupAdvisorFinderId" label="Assessor Finder" label-for="inputGroupAdvisorFinderId">
                                    <b-form-input
                                        id="inputAdvisorFinderId"
                                        name="inputAdvisorFinderId"
                                        v-model="commercialPartner.advisorFinder.hunter_name"
                                        style="min-height:56px;"
                                        v-validate="{ required: true }"
                                        class="form-control"
                                        :state="commercialPartner.advisorFinder.hunter_id ? true : commercialPartner.id ? false : null"
                                        aria-describedby="inputAdvisorFinderIdFeedback"
                                        data-vv-as="Assessor Finder"
                                        :disabled="true"
                                    />

                                    <b-form-invalid-feedback id="inputAdvisorFinderIdFeedback">{{ 'Campo obrigatório.' }}</b-form-invalid-feedback>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 px-2">
                                <b-form-group id="inputGroupAdvisorFinalId" label="Assessor Final" label-for="inputGroupAdvisorFinalId">
                                    <b-form-input v-if="advisorFinals.length==0"
                                        id="inputAdvisorFinalId"
                                        name="inputAdvisorFinalId"
                                        v-model="commercialPartner.advisorFinal.farmer_name"
                                        style="min-height:56px;"
                                        v-validate="{ required: true }"
                                        class="form-control"
                                        :state="commercialPartner.advisorFinal.farmer_id ? true : commercialPartner.id ? false : null"
                                        aria-describedby="inputAdvisorFinalIdFeedback"
                                        data-vv-as="Assessor Final"
                                        :disabled="true"
                                    />
                                    <b-form-select v-else
                                        id="inputAdvisorFinalId"
                                        name="inputAdvisorFinalId"
                                        v-model="commercialPartner.advisorFinal"
                                        style="min-height:56px;"
                                        v-validate="{ required: true }"
                                        class="form-control"
                                        :state="commercialPartner.advisorFinal ? true : commercialPartner.id ? false : null"
                                        aria-describedby="inputAdvisorFinalIdFeedback"
                                        data-vv-as="Assessor Final"
                                    >
                                        <b-form-select-option v-for="item in advisorFinals" :value="item" :key="item.id">{{ item.farmer_name }}</b-form-select-option>
                                    </b-form-select>

                                    <b-form-invalid-feedback id="inputAdvisorFinalIdFeedback">{{ 'Campo obrigatório.' }}</b-form-invalid-feedback>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row d-flex align-items-center justify-content-end mx-2 mt-3">
                            <CancelButton
                            @click="$refs.modalCommercialPartnerForm.closeModal()"
                            >
                                Cancelar
                            </CancelButton>
                            <CustomButton
                            @click="onSubmitCommercialPartnerForm"
                            :loading="loadingSubmit"
                            :disabled="!isModalValid"
                            > 
                                {{ type=='post' ? 'Adicionar' : 'Salvar' }}
                            </CustomButton>
                        </div>
                    </b-form>
                </div>
            </div>
        </card-modal>
    </div>  
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import GlassButton from '@/components/GlassButton.vue';
import CustomButton from '@/components/CustomButton.vue';
import CancelButton from '@/components/CancelButton.vue';
import InputDatePicker from '@/components/InputDatePicker.vue';
import CardModal from './CardModal.vue';
// import DefaultHeader from '@/components/DefaultHeader.vue';
export default {
    name: 'FormCustomerView',
    components:{
        'card-modal': CardModal,
        DefaultPageComponent,
        GlassButton,
        CustomButton,
        CancelButton,
        InputDatePicker,
        // DefaultHeader
    },
    data () {
        return {
            loading: false,
            loadingSubmit: false,
            isDocumentValid: false,
            maxDate: null,
            banks: [],
            states: [],
            customer: {
                n_document: '',
                first_name: '',
                last_name: '',
                birthday: '',
                bank_id: '',
                email: '',
                phone_number: '',
                area_code: '',
                group_name: '',
                zip_code: '',
                address: '',
                number: '',
                neighborhood: '',
                city: '',
                state_id: '',
                status: 1,
            },
            modals: {
                addCommercialPartner: false,
            },
            commercialPartners: [],
            commercialPartner: {
                code: '',
                id: '',
                name: '',
                advisorBase: {},
                advisorFinder: {},
                advisorFinal: {},
            },
            commercialPartnersInMemory: [],
            renderComponent: true,
            type: 'post',
            editIndex: null,
            advisorBases: [],
            advisorFinderFinals: [],
            advisorFinders: [],
            advisorFinals: [],
            columns: [
                {
                    'code': 
                    {
                        label: 'Código',
                        class: 'pb-2 text-right'
                    }
                },
                {
                    'name': 
                    {
                        label: 'Parceiro comercial',
                        class: 'pb-2 text-left'
                    }
                },
                {
                    'advisorBase.name': 
                    {
                        label: 'Assessor Base',
                        class: 'pb-2 text-left'
                    }
                },
                {
                    'advisorFinder.hunter_name': 
                    {
                        label: 'Assessor Finder',
                        class: 'pb-2 text-left'
                    }
                },
                {
                    'advisorFinal.farmer_name': 
                    {
                        label: 'Assessor Final',
                        class: 'pb-2 text-left'
                    }
                },
                // {
                //     'openedDate': 
                //     {
                //         label: 'Data de abertura',
                //         class: 'text-left',
                //         formatter: (value)=>{
                //             if(!value) return '---';
                //             return value.substring(8,10)+'/'+value.substring(5,7)+'/'+value.substring(0,4)
                //         }
                //     }
                // },
                // {
                //     'activeDate': 
                //     {
                //         label: 'Data de ativação',
                //         class: 'text-left',
                //         formatter: (value)=>{
                //             if(!value) return '---';
                //             return value.substring(8,10)+'/'+value.substring(5,7)+'/'+value.substring(0,4)
                //         }
                //     }
                // },
                {
                    'actions': 
                    {
                        label: '',
                        class: 'text-right',
                    }
                },
            ],
        }
    },
    created() {
        this.$validator.localize('en', {
            custom: {
                inputCommercialPartnerId: {
                    required: 'Campo obrigatório.',
                },
                inputCommercialPartnerCode: {
                    required: 'Campo obrigatório.',
                },
                // inputAdvisorBaseId: {
                //     required: 'Campo obrigatório.',
                // },
                // inputAdvisorFinderId: {
                //     required: 'Campo obrigatório.',
                // },
                // inputAdvisorFinalId: {
                //     required: 'Campo obrigatório.',
                // },
            },
        });
    },
    mounted () {
        this.getBanks();
        this.getStates();
        this.getCommercialPartners();
        this.getAdvisorBases();
        
        this.maxDate = new Date().toISOString().split('T')[0];
    },
    computed: {
        isFormValid(){
            return (
                this.isDocumentValid
                && this.customer.first_name
                && this.customer.last_name
            )
        },
        isModalValid(){
            return (
                this.commercialPartner.code
                && this.commercialPartner.id
                && this.commercialPartner.advisorBase.id
                && this.commercialPartner.advisorFinder.hunter_id
                && this.commercialPartner.advisorFinal.farmer_id
            )
        },
    },
    methods: {
        async forceRender(){
            this.renderComponent = false;
            await this.$nextTick();
            this.renderComponent = true;
        },
        validateDocument(value){
            var isValid = false;
            var str = value.replace(/\D/g, '');
            
            if(str.length==11 || str.length==14)
                if(this.$options.filters.validateCPF(str) || this.$options.filters.validateCNPJ(str))
                    isValid = true;
            
            this.isDocumentValid = isValid;
            return this.isDocumentValid;
        },
        async getBanks() {
            this.loading = true;
            this.$axios.get('banks').then((response) => {
                this.banks = response.data.data;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar bancos.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
                return;
            })
            .finally(()=>{
                this.loading = false;
            })
        },
        async getStates() {
            this.loading = true;
            this.$axios.get('states').then((response) => {
                this.states = response.data.data;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar os estados.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            })
            .finally(()=>{
                this.loading = false;
            })
        },
        async searchZipCode () {
            await this.$axios.get(`https://viacep.com.br/ws/${this.customer.zip_code.replace(/\D/g, '')}/json`).then((response) => {
                this.customer.address = response.data.logradouro;
                this.customer.neighborhood = response.data.bairro;
                this.customer.city = response.data.localidade;
                this.states.forEach(state => {
                    if (state.acronym === response.data.uf) {
                        this.customer.state_id = state.id;
                    }
                });
            });
        },
        async getCommercialPartners() {
            this.loading = true;
            this.$axios.get('commercial-partner').then((response) => {
                this.commercialPartners = response.data.data;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar os parceiros comerciais.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            })
            .finally(()=>{
                this.loading = false;
            })
        },
        async getAdvisorBases(){
            const response = await this.$axios.get('advisors');
            this.advisorBases = response.data.data;
            this.advisorBases.forEach(e=>e.name = e.first_name+' '+e.last_name);
        },
        clearAdvisorsInput(){
            this.commercialPartner.advisorBase = {};
            this.commercialPartner.advisorFinder = {};
            this.commercialPartner.advisorFinal = {};
        },
        async getAdvisorFinderFinals(){
            if(this.commercialPartner.advisorBase){
                const response = await this.$axios.get('advisors/relationship-hunter-farmer/'+this.commercialPartner.advisorBase.id+'/'+this.commercialPartner.advisorBase.advisorCommercialPartner[0].hunter_relationship_farmer[0].commercial_partner_id);
                this.advisorFinderFinals = response.data;
                if(this.advisorFinderFinals.length>0){
                    this.advisorFinals = response.data;
                    this.commercialPartner.advisorFinder = this.advisorFinderFinals[0];
                    this.commercialPartner.advisorFinal = '';
                    if (this.advisorFinderFinals.length == 1) {
                        this.commercialPartner.advisorFinder = this.advisorFinderFinals[0];
                        this.commercialPartner.advisorFinal = this.advisorFinderFinals[0];
                    }
                }
            }
        },
        showModalCommercialPartnerForm(item, idx) {
            if (this.commercialPartners.length == 0) {
                this.$swal({
                    title: 'Informação',
                    text: 'Sem parceiros disponíveis para selecionar, todos já estão selecionados.',
                    icon: 'info',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            }
            if(item){
                this.type = 'put';
                this.editIndex = idx;
                this.commercialPartner = {...item};
            }
            else{
                this.type = 'post';
                this.commercialPartner.code = "";
                this.commercialPartner.id = "";
                this.commercialPartner.name = "";
                this.commercialPartner.advisorBase = {};
                this.commercialPartner.advisorFinder = {};
                this.commercialPartner.advisorFinal = {};
                // this.commercialPartner.openedDate = "";
                // this.commercialPartner.activeDate = "";
            }
            this.$refs.modalCommercialPartnerForm.openModal();
        },
        async removeCommercialPartner(idx) {
            this.$swal({
                title: 'Excluir parceiro comercial ?',
                text: `Deseja realmente excluir do cadastro do assessor o código ${this.commercialPartnersInMemory[idx].code} do parceiro comercial ${this.commercialPartnersInMemory[idx].name} ?`,
                // text: `Deseja realmente excluir o parceiro comercial ${this.commercialPartnersInMemory[idx].name}?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Cancelar',
                confirmButtonColor: '#C7300C',
                cancelButtonText: 'Excluir',
                cancelButtonColor: '#28A745',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'custom-confirm-button-class',
                    cancelButton: 'custom-confirm-button-class',
                }
            }).then(async (result) => {
                if (!result.isConfirmed) {
                    this.commercialPartnersInMemory.splice(idx, 1);
                    this.commercialPartners = [];
                    await this.getCommercialPartners();
                    for (let i = 0; i < this.commercialPartnersInMemory.length; i++) {
                        let idx = this.commercialPartners.findIndex(obj => obj.id === this.commercialPartnersInMemory[i].id);

                        if (idx > -1) {
                            this.commercialPartners.splice(idx, 1);
                        }
                    }
                }
            });
        },
        async onSubmitCommercialPartnerForm() {
            if(!this.isModalValid){
                this.$swal({
                    title: 'Atenção',
                    text: 'O cadastro de Parceiros Comerciais e Relacionamento de Assessor Base está incompleto. Por favor, verifique.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            }
            this.$validator.validateAll(
                    [
                        'inputCommercialPartnerCode', 
                        'inputCommercialPartnerId', 
                        // 'inputAdvisorBaseId', 
                        // 'inputAdvisorFinderId', 
                        // 'inputAdvisorFinalId', 
                    ]
                ).then(async result => {
                if (!result) {
                    return;
                }
                
                let idx = this.commercialPartners.findIndex(obj => obj.id === this.commercialPartner.id);
                this.commercialPartner.name = this.commercialPartners[idx].trade_name;
                
                if(this.type=='post'){
                    this.commercialPartnersInMemory.push({
                        code: this.commercialPartner.code,
                        id: this.commercialPartner.id,
                        name: this.commercialPartner.name,
                        advisorBase: this.commercialPartner.advisorBase,
                        advisorFinder: this.commercialPartner.advisorFinder,
                        advisorFinal: this.commercialPartner.advisorFinal,
                        // openedDate: this.commercialPartner.openedDate,
                        // activeDate: this.commercialPartner.activeDate,
                    });
                }
                else if(this.type=='put'){
                    this.commercialPartnersInMemory[this.editIndex].code = this.commercialPartner.code;
                    this.commercialPartnersInMemory[this.editIndex].id = this.commercialPartner.id;
                    this.commercialPartnersInMemory[this.editIndex].name = this.commercialPartner.name;
                    this.commercialPartnersInMemory[this.editIndex].advisorBase = this.commercialPartner.advisorBase;
                    this.commercialPartnersInMemory[this.editIndex].advisorFinder = this.commercialPartner.advisorFinder;
                    this.commercialPartnersInMemory[this.editIndex].advisorFinal = this.commercialPartner.advisorFinal;
                }

                // this.commercialPartners.splice(idx, 1);
                await this.forceRender();

                this.$refs.modalCommercialPartnerForm.closeModal();
            });
        },
        async saveCustomer () {
            if(!this.isFormValid){
                this.$swal({
                    title: 'Atenção',
                    text: 'Por favor, preencha todos os campos obrigatórios para salvar.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            }
            
            this.loading = true;
            this.loadingSubmit = true;

            let customer = this.customer;

            customer.n_document = customer.n_document.replace(/\D/g, '');
            customer.zip_code = customer.zip_code.replace(/\D/g, '');
            var phone = new String(customer.phone_number.replace(/\D/g, ''));
            customer.phone_number = phone.substring(2, phone.length);
            customer.area_code = phone.substring(0, 2);
            customer.type = customer.n_document.length==14 ? 1 : 0;
            customer.customer_commercial_partner = this.commercialPartnersInMemory.map(e=>{
                return {
                    commercial_partner_id: e.id,
                    code: e.code,
                    customer_relationship_advisor: [
                        {
                            commercial_partner_id: e.id,
                            hunter_master_id: e.advisorBase.id,
                            hunter_id: e.advisorFinder.hunter_id,
                            farmer_id: e.advisorFinal.farmer_id,
                        }
                    ],
                }
            })

            this.$axios.post('customers', customer).then(() => {
                this.$swal({
                    title: 'Sucesso',
                    text: 'Cliente cadastrado com sucesso.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                    focusConfirm: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                this.$router.push('/customers');
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível cadastrar o cliente.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            })
            .finally(()=>{
                this.loading = false;
                this.loadingSubmit = false;
            })
        },
        validateState(ref) {
            if (
                this.veeFields[ref] &&
                (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
    }
}
</script>

<style>
    #customer {
        height: calc(100vh - 125px);
    }
</style>
