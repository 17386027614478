<template>
    <b-overlay
      :show="loading"
      opacity="0.75"
      bg-color="black"
      spinner-small
      spinner-variant="light"
      :style="{
        'border-radius': '10px',
        'width': width || '200px',
      }"
    >
        <b-button
        class="d-flex align-items-center justify-content-center"
        :variant="variant || 'secondary'"
        :type="type || 'button'"
        :disabled="readonly"
        :style="
        disabled 
        ? {
            'border-radius': '10px',
            'min-height': '56px',
            'line-height': '0px',
            'width': width || '200px',
            'font-size': fontSize || '18px',
            'font-weight': fontWeight || '600',
            'border': '1px solid ' + (borderColor || '#EB694A'),
            'background-color': 'transparent',
            'color': textColor || '#EB694A',
        }
        : not_clickable ? {
            'cursor': 'default !important',
            'box-shadow': 'none !important',
            'border-radius': '10px',
            'min-height': '56px',
            'line-height': '0px',
            'width': width || '200px',
            'font-size': fontSize || '18px',
            'font-weight': fontWeight ||'600',
            'border': '1px solid ' + (borderColor || '#EB694A'),
            'background-color': bgColor || '#EB694A',
            'color': 'white',
        }
        : {
            'border-radius': '10px',
            'min-height': '56px',
            'line-height': '0px',
            'width': width || '200px',
            'font-size': fontSize || '18px',
            'font-weight': fontWeight ||'600',
            'border': '1px solid ' + (borderColor || '#EB694A'),
            'background-color': bgColor || '#EB694A',
            'color': 'white',
        }"
        @click.prevent="click"
        >

            <slot></slot>

        </b-button>
    </b-overlay>
</template>

<script>
export default {
    name: 'CustomButton',
    props: {
        loading: Boolean,
        type: String,
        variant: String,
        borderColor: String,
        bgColor: String,
        textColor: String,
        width: String,
        fontSize: String,
        fontWeight: String,
        disabled: Boolean,
        readonly: Boolean,
        not_clickable: Boolean,
    },
    methods:{
        click(){
            this.$emit('click')
        },
    }
}
</script>

<style scoped>
* /deep/ .b-overlay div {
    border-radius: 9px;
}
</style>