<template>
    <b-dropdown ref="custom_dropdown" variant="link" right class="dropdown-custom-menu" toggle-class="dropdown-custom text-decoration-none box-shadow-none" no-caret>
        <template #button-content>
            <div class="d-flex justify-content-center align-items-center header-button" style="position:relative;" :style="notifications.length>0 ? 'color:#C34D2F;' : 'color:white;'">
                <div v-if="newNotifications.length>0" class="notification-dot" :style="newNotifications.length>9&&newNotifications.length<=99 ? 'font-size:11px;' : newNotifications.length>99 ? 'font-size:9px;' : ''"> 
                        {{ newNotifications.length<=99 ? newNotifications.length : '+99'  }} </div>
                <b-icon scale="1" icon="bell" style="margin:0px" alt=""></b-icon>
            </div>
        </template>
        <b-dropdown-text style="color:black; min-width:100% !important">
            <b-row class="mx-0 pt-2" align-h="center" style="background-color: #C34D2F;">
                <h6 class="p-0" style="font-size:20px; font-weight:500; color:white;">Notificações</h6>
            </b-row>
        </b-dropdown-text>
        <b-dropdown-divider></b-dropdown-divider>
        <div v-if="notifications.length==0">
            <b-dropdown-text>
                <b-row class="mx-3" align-h="center" style="text-align:center;">
                    <h6 class="py-3" style="font-size:18px; font-weight:500;">Nenhuma notificação no momento.</h6>
                </b-row>
            </b-dropdown-text>
        </div>
        <div v-for="(notification, idx) in notifications" :key="idx">
            <b-dropdown-item
            @click="$emit('showDetail', notification.id)"
            >
                <b-row no-gutters style="font-size:14px">
                    <b-col align-self="start" style="height:25px; max-width:15px; margin-right:10px; display:flex; justify-content:end; align-items:center;" no-gutters>
                        <div style="font-size:14px; border-radius:100%" :style="notification.is_read ? 'color:#00000050;' : 'color:#C34D2F;'">
                            <b-icon icon="info-circle"></b-icon>
                        </div>
                    </b-col>
                    <b-col style="max-width:300px;" align-self="start" no-gutters>
                        <b-row no-gutters style="max-width:300px; white-space: normal; word-wrap: break-word;"><span style="max-width:300px; font-size:15px; font-weight:600;">{{ notification.title }}</span></b-row>
                        <b-row no-gutters style="max-width:300px; white-space: normal; word-wrap: break-word;"><span style="max-width:300px; font-size:13px; font-weight:500; color:#00000090;">{{ formatDateTimeBR(notification.created_at) }}</span></b-row>
                    </b-col>
                    <b-col style="max-width:fit-content !important" align-self="start" no-gutters>
                        <b-button 
                        size="sm"
                        @click.stop.prevent="removeNotification(idx)"
                        style="color: #EB694A; border:1px solid #474646; border-radius:4px; box-shadow: none; background-color: transparent; font-size:14px; padding:3px 5px; z-index:100;"
                        >
                            <b-icon icon="trash-fill"></b-icon>
                        </b-button>
                    </b-col>
                </b-row>
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
        </div>
    </b-dropdown>
</template>

<script>
export default {
    name: 'NotificationComponent',
    props:{
        userId: Number,
    },
    data(){
        return {
            notifications: [],
        }
    },
    computed: {
        newNotifications(){
            return this.notifications.filter(e=>!e.is_read);
        },
    },
    created(){
        var stop = false;
        setInterval(()=>{
            if(this.$route.name=='dashboard' && !stop){
                stop=true;
                this.getNotifications();
            }
            else if(this.$route.name!='dashboard' && stop)
                stop=false;
        }, 1000)
    },
    methods: {
        async getNotifications(){
            const response = await this.$axios.get('notification?user_id='+this.userId)
            if(response.data[0]) this.notifications = response.data[0].filter(e=>!e.is_deleted);
        },
        removeNotification(idx){
            this.$swal({
                title: 'Excluir notificação?',
                text: `Deseja realmente excluir a notificação?`,
                icon: 'question',
                showDenyButton: true,
                confirmButtonText: 'Cancelar',
                confirmButtonColor: '#C7300C',
                denyButtonText: 'Excluir',
                denyButtonColor: '#28A745',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'custom-confirm-button-class',
                    denyButton: 'custom-confirm-button-class',
                }
            }).then(async (result) => {
                this.$nextTick(()=>{
                    this.$refs.custom_dropdown.hide();
                    this.$refs.custom_dropdown.show();
                })
                if (!result.isConfirmed) {
                    await this.$axios.put('notification/'+this.notifications[idx].id, { is_deleted: 1 })
                    this.notifications.splice(idx,1)
                    this.getNotifications();
                }
            });
            
            setTimeout(()=>{
                this.$nextTick(()=>{
                    this.$refs.custom_dropdown.hide();
                    this.$refs.custom_dropdown.show();
                })
            }, 100)
        },
        formatDateTimeBR(value){
            if(!value) return '---';
            var day = value.substring(8,10);
            var month = value.substring(5,7);
            var year = value.substring(0,4);
            var hour = value.substring(11,13);
            var minute = value.substring(14,16);
            var dateBR = day+'/'+month+'/'+year+' '+hour+':'+minute;
            return dateBR;
        },
    },
}
</script>

<style scoped>
    .header-button {
        font-size: 20px;
    }

    .notification-dot {
        position:absolute; 
        top:-5px; right:-7px; 
        border-radius:100%; 
        border: 1px solid #C34D2F;
        background-color: black; 
        width:18px; height:18px;
        color: #C34D2F;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .qtd-notification{
        background-color: #689AEC;
        color: white;
        /* font-family: "Quicksand"; */
        font-weight: 500;
        border-radius:100%;
        min-width: fit-content;
        width:22px;
        height:22px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    * /deep/ .dropdown-custom{
        border-radius: 12px !important;
        max-width: fit-content;
        max-height: fit-content;
        padding: 0px 10px !important;
    }

    * /deep/ .dropdown-menu{
        border-radius: 4px !important;
        min-width: 317px !important;
        max-width: 317px !important;
        padding: 0px !important;
    }
    * /deep/ .b-dropdown-text{
        padding: 0px !important;
    }
    * /deep/ .dropdown-custom-menu .dropdown-menu{
        border-radius: 4px !important;
        min-width: 306px !important;
        max-width: 306px !important;
        padding: 0px !important;
    }
    * /deep/ .dropdown-item{
        text-decoration: none !important;
        padding: 10px 20px;
    }
    * /deep/ .dropdown-item:active{
        background: #C34D2F30;
        color: black;
    }
    * /deep/ .dropdown-divider{
        margin-block-start: 0px;
        margin-block-end: 0px;
    }

    * /deep/ a{
        text-decoration: none !important;
    }
</style>