<template>
    <div id="default-messages">
        <DefaultPageComponent
        :loading="loading"
        :title="'Mensagens Padrões'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'dashboard'})"
        :haveNextButton="true"
        :buttonText="'Nova mensagem'"
        @onTap="$router.push('/default-messages/add')"
        :haveContentMargin="true"
        >

        <template #header>
            
        </template>

        <template #filters>
            <b-row>
                <b-col cols="3">
                    <div class="form-group">
                        <label for="">Título:</label>
                        <input type="text" class="form-control form-control-sm" id="inputUserName" placeholder="Pesquise pelo título" style="box-shadow: none; height: 56px;" v-model="filters.filter" @change="filterDefaultMessages">
                    </div>
                </b-col>
            </b-row>
        </template>

        <!-- CONTENT -->
        <div style="height:100%; width:100%;">
            <b-table class="custom-table" borderless :fields="columns" :items="filteredDefaultMessages" sticky-header>
                <template #head(title)="data">
                    <b-col class="text-left p-0">
                        {{data.label}}
                    </b-col>
                </template>
                <template #head(n_attachments)="data">
                    <b-col class="text-left p-0">
                        {{data.label}}
                    </b-col>
                </template>
                <template #head(actions)="data">
                    <b-col class="text-center p-0">
                        {{data.label}}
                    </b-col>
                </template>
                <template #cell(title)="row">
                    <b-col class="mt-2">
                        {{ row.item.title }}
                    </b-col>
                </template>
                <template #cell(n_attachments)="row">
                    <b-col>
                        <button
                            style="width: 39px; height: 40px; background-color: #242323; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border: 1px solid; border-radius:4px; border-color: #535252; box-shadow: none;"
                            class="mr-2"
                            @click="showAttachments(row.item.id)"
                        >
                            <span class="m-4 px-1" style="background-color: #EB694A; color: #000; border-radius:2px;">
                                {{ row.item.n_attachments }}
                            </span>
                        </button>
                    </b-col>
                </template>
                <template #cell(actions)="row">
                    <div class="d-flex justify-content-end align-items-center">
                        <div style="display: flex; justify-content: center; align-itens: center;" class="text-center pb-3">
                            <div style="display: flex; justify-content: center; align-items: center;">
                                <b-button 
                                    style="width: 39px; height: 40px; background-color: #242323; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252; box-shadow: none;"
                                    class="mr-2"
                                    @click="showDefaultMessage(row.item.id)"
                                >
                                    <img src="@/assets/visibility.png" />
                                </b-button>
                                <b-button 
                                    style="width: 39px; height: 40px; background-color: #242323; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252; box-shadow: none;"
                                    @click.prevent="editDefaultMessage(row.item.id)"
                                >
                                    <img src="@/assets/pencil.png" />
                                </b-button>
                            </div>
                        </div>
                    </div>
                </template>
            </b-table>
        </div>

        <template #footer>
            <div></div>
        </template>

        </DefaultPageComponent>

        <div style="position: fixed; top: 10%; left: 20%; width: 60vw; height: min-content; background: #1C1B1B; z-index: 10000; border-radius: 25px;" class="p-4" v-if="modals.showDefaultMessage">
            <b-row style="position: sticky; top:0px;">
                <b-col style="font-size: 16px; font-weight: 400;" class="text-right">
                    <span style="cursor: pointer;" @click="modals.showDefaultMessage = false;">x</span>
                </b-col>
            </b-row>
            <b-row style="min-height: 60vh;" class="px-3">
                <b-col>
                    <div>
                        Título
                    </div>
                    <div class="mt-3 mb-3">
                        <b-input style="box-shadow: none;" v-model="defaultMessage.title" disabled></b-input>
                    </div>
                    <div>
                        Mensagem
                    </div>
                    <div style="height: 100%;" class="mt-2">
                        <div style="white-space:pre-wrap; height: calc(100% - 125px); box-shadow: none; border: 1px #535252 solid;" class="px-2" v-html="defaultMessage.message"></div>
                        <!-- <b-form-textarea style="height: calc(100% - 125px); box-shadow: none;" v-model="defaultMessage.message" disabled no-resize></b-form-textarea> -->
                    </div>
                </b-col>
                <b-col>
                    <div>
                        Anexos
                    </div>
                    <div style="height: 93%; border: 1px solid #535252; overflow-y: auto;" class="mt-3">
                        <div style="max-height: 100%; height: 57vh;">
                            <div v-for="attachment in attachments" :key="attachment.id">
                                <div class="p-3">
                                    {{ attachment.title }}
                                </div>
                                <div class="text-center">
                                    <div class="py-3">
                                    <img :src="attachment.file_path" alt="Attachment Image" width="350">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div style="position: fixed; top: 10%; left: 20%; width: 60vw; height: min-content; background: #1C1B1B; z-index: 10000; border-radius: 25px;" class="p-4" v-if="modals.showAttachments">
            <b-row>
                <b-col class="pl-4">
                    Anexos
                </b-col>
                <b-col style="font-size: 16px; font-weight: 400;" class="text-right">
                    <span style="cursor: pointer;" @click="modals.showAttachments = false;">x</span>
                </b-col>
            </b-row>
            <b-row style="min-height: 60vh;" class="px-3">
                <b-col>
                    
                    <div style="height: 93%; border: 1px solid #535252; overflow-y: auto;" class="mt-3">
                        <div style="max-height: 100%; height: 57vh;">
                            <div v-for="attachment in attachments" :key="attachment.id">
                                <div class="p-3">
                                    {{ attachment.title }}
                                </div>
                                <div class="text-center">
                                    <div class="py-3">
                                    <img :src="attachment.file_path" alt="Attachment Image" width="350">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div class="modal-overlay" v-if="modals.showDefaultMessage || modals.showAttachments"></div>
    </div>  
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
export default {
    name: 'DefaultMessageView',
    components:{
        DefaultPageComponent,
    },
    data () {
        return {
            columns: [
                {
                    'title': 
                    {
                        label: 'Título',
                    }
                },
                {
                    'n_attachments': 
                    {
                        label: 'Anexo',
                    }
                },
                {
                    'actions': 
                    {
                        label: '',
                        class: 'text-right',
                    }
                },
            ],
            loading: false,
            defaultMessages: [],
            filteredDefaultMessages: [],
            filters: {
                filter: '',
            },
            defaultMessage: {},
            tags: [],
            attachments: [],
            modals: {
                showDefaultMessage: false,
                showAttachments: false,
            },
        }
    },
    async mounted () {
        this.loading=true;
        await this.getTags();
        this.getDefaultMessages();
    },
    methods: {
        async getDefaultMessages() {
            this.loading=true;
            this.$axios.get('default-messages').then((response) => {
                this.defaultMessages = response.data.data;
                this.filteredDefaultMessages = this.defaultMessages;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar as mensagens padrões.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            })
            .finally(()=>{
                this.loading=false;
            })
        },
        async showAttachments(defaultMessageID) {
            await this.getDefaultMessageById(defaultMessageID);

            if (this.attachments.length > 0) {
                this.modals.showAttachments = true;
            } else {
                this.$swal({
                    title: 'Aviso',
                    text: 'Mensagem não tem nenhum anexo vinculado.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            }
        },
        showDefaultMessage(defaultMessageID) {
            this.getDefaultMessageById(defaultMessageID);
            this.modals.showDefaultMessage = true;
        },
        async getDefaultMessageById(defaultMessageID) {
            try {
                this.attachments = [];
                const response = await this.$axios.get(`default-messages/${defaultMessageID}`);
                this.defaultMessage = response.data.data;
                if(this.tags.length>0) this.defaultMessage.message = this.replaceTags(this.defaultMessage.message, this.tags);
                if (this.defaultMessage.attachments.length > 0) {
                    await Promise.all(this.defaultMessage.attachments.map(attachment => this.getAttachmentById(attachment.attachment_id)));
                }
            } catch (error) {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar a mensagem padrão.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            }
        },
        async getTags(){
            const response = await this.$axios.get('tags');
            this.tags = response.data.data;
        },
        replaceTags(message, tags) {
            tags.sort((a,b)=> b.tag.replace(/\D/g, '') - a.tag.replace(/\D/g, '') )
            tags.forEach(tag => {
                const regex = new RegExp(tag.tag, 'g');
                message = message.replace(regex, "<span class='p-1' style='color: #FFF; background-color: #EB694A; border-radius: 10px; line-height: 2.5em;'>" + tag.description + "</span>");
            });
            return message;
        },
        async getAttachmentById(attachmentID) {
            try {
                const response = await this.$axios.get(`attachments/${attachmentID}`);
                this.attachments.push(response.data.data);
            } catch (error) {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar o anexo.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            }
        },
        async editDefaultMessage(defaultMessageID) {
            const response = await this.$axios.get('default-messages/check-message-usage/'+defaultMessageID);
            if(response.data.result){
                this.$swal({
                    title: 'Atenção',
                    text: 'Essa mensagem padrão não pode ser editada, pois já foi utilizada em uma mensagem.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            }
            this.$router.push({ name: 'defaultMessageEdit', params: { id: defaultMessageID }});
        },
        filterDefaultMessages() {
            const normalizeString = str => {
                return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            };

            const normalizedFilter = normalizeString(this.filters.filter.toLowerCase());

            this.fiilteredDefaultMessages = this.defaultMessages.filter(defaultMessage => {
                const normalizedTitle = normalizeString(defaultMessage.title.toLowerCase());
                return normalizedTitle.includes(normalizedFilter);
            });
        }
    },
    watch: {
        'filters.filter': function () {
            this.filterDefaultMessages()
        }
    }
}
</script>

<style>
    #default-messages {
        height: calc(100vh - 125px);
    }

    .form-control {
        background-color: #262625 !important;
        border: 1px solid #535252 !important;
        color: #E5E2E1 !important;
        border-radius: 10px !important;
        padding-left: 20px !important;
    }

    input::-webkit-input-placeholder {
        color: #E5E2E1 !important;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url('data:image/svg+xml;utf8,<svg fill="white" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
        background-repeat: no-repeat;
        background-position-x: calc(100% - 15px);
        background-position-y: center;
        padding-left: 20px !important;
    }
</style>
