<template>
    <div id="integration">
        <DefaultPageComponent
        :loading="loading"
        :title="hasExported ? messages.find(e=>e.id==message.default_message_id)?.title : 'Nova mensagem'"
        :haveBackButton="true"
        @onBack="$router.push('/messages')"
        >

        <template #header>
            
        </template>

        <template #filters>
            <div v-if="hasExported" style="z-index:2; position:absolute; top: 138px; width: calc(100% - 60px); height:185px; background-color:#D9D9D940;"></div>
            <b-row>
                <b-col class="form-group" style="min-width: 200px;">
                    <label for="">Assessor mensagem:</label>
                    <select @change="changeAdvisor" class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; min-height: 56px;" v-model="message.advisor_id">
                        <option value="">Selecione o assessor</option>
                        <option v-for="advisor in advisors" :key="advisor.id" :value="advisor.id">{{ advisor.first_name + " " + advisor.last_name }} </option>
                    </select>
                </b-col>
                <b-col class="form-group" style="min-width: 200px;">
                    <label for="">Mensagem:</label>
                    <select @change="selectedMessage(message.default_message_id)" class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; min-height: 56px;" v-model="message.default_message_id">
                        <option value="">Selecione uma mensagem</option>
                        <option v-for="message in messages" :key="message.id" :value="message.id">{{ message.title }} </option>
                    </select>
                </b-col>
                <b-col></b-col>
                <b-col class="form-group d-flex justify-content-end align-items-center" style="min-width: 200px; z-index: 3;">
                    <CustomButton
                    :disabled="!message.default_message_id"
                    :loading="loading"
                    @click="showMessage"
                    >
                        Visualizar
                    </CustomButton>
                </b-col>
            </b-row>
            <b-row v-if="tags.length > 0" class="pt-4">
                <div class="form-group d-flex align-items-start justify-content-start">
                    <b-col v-for="tag in tags.filter(e=>!e.type)" :key="tag.id">
                        <label for="">{{ tag.description }} ({{ tag.tag }}):</label>
                        <input @input="verifyTags" :disabled="tag.id==2" type="text" class="form-control form-control-sm" style="box-shadow: none; min-width: 100%; max-width: 100%; min-height: 56px;" :id="`input_${tag.description}`" v-model="tag.value">
                    </b-col>
                    <b-col v-if="filteredMessages.length > 0 " class="d-flex align-items-center justify-content-end">
                        <CustomButton
                        @click="filteredMessages = []; message.file_importation_path=null;"
                        > Excluir importação
                        </CustomButton>
                    </b-col>
                </div>
            </b-row>
        </template>

        <!-- CONTENT -->
        <div style="min-height:100%; width:100%; background-color: #262625;" :style="hasExported ? 'overflow:hidden;' : ''">
            <div v-if="hasExported" style="z-index:2; position:absolute; width:100%; height:100%; background-color:#D9D9D940;"></div>
            <!-- <b-overlay :show="loading" bg-color="black" spinner-variant="light" rounded="sm" style="height:100%;"> -->
                <div style="height: 100%;" class="p-3">
                    <b-col style="height:100%; width:100%;">
                        <b-row>
                            <div style="width: 100%;">
                                <b-table class="custom-table" borderless :fields="filteredMessages.length==0 ? columns : [...expectedFields, ...columns.filter(e=>!e['customerName'])]" :items="filteredMessages" sticky-header show-empty>
                                    <template #empty="">
                                        <b-col class="text-center mb-3">
                                            Não há dados disponíveis
                                        </b-col>
                                    </template>
                                    <template #head(customer)="data">
                                        <b-col class="text-left p-0">
                                            {{data.label}}
                                        </b-col>
                                    </template>
                                    <template #head(value)="data">
                                        <b-col class="text-right p-0">
                                            {{data.label}}
                                        </b-col>
                                    </template>
                                    <template #head(phone)="data">
                                        <b-col class="text-left p-0">
                                            {{data.label}}
                                        </b-col>
                                    </template>
                                    <template #head(whatsapp_group)="data">
                                        <b-col class="text-left p-0">
                                            {{data.label}}
                                        </b-col>
                                    </template>
                                    <template #cell(value)="row">
                                        <b-col class="text-right p-0">
                                            {{ 'R$ ' + row.item.value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                                        </b-col>
                                    </template>
                                    <template #cell(type)="row">
                                        <b-col class="text-left p-0" v-if="row.item.type">
                                            Sim
                                        </b-col>
                                        <b-col class="text-left p-0" v-else>
                                            Não
                                        </b-col>
                                    </template>
                                    <template #cell(actions)="row">
                                        <div class="d-flex justify-content-end align-items-center">
                                            <div style="display: flex; justify-content: center; align-itens: center;" class="text-center pb-3">
                                                <div style="display: flex; justify-content: center; align-items: center;">
                                                    <!-- <b-form-checkbox switch size="sm" class="mr-2" style="min-width: 80px;" v-model="row.item.status">
                                                        {{ row.item.status ? 'Ativo' : 'Inativo' }}
                                                    </b-form-checkbox> -->
                                                    <b-button 
                                                        style="width: 39px; height: 40px; background-color: #242323; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252; box-shadow: none;"
                                                        @click="editTag(row.item.id)"
                                                    >
                                                        <img src="@/assets/pencil.png" />
                                                    </b-button>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </b-table>
                            </div>
                        </b-row>
                        <b-row v-if="filteredMessages.length === 0">
                            <b-col class="mt-4 mb-4 pt-4 d-flex align-items-center justify-content-center">
                                <span style="font-size: 20px; font-family: bold; color: #535252;">
                                    Baixar modelo clicando <a style="color: #EB694A; cursor: pointer;" @click="downloadFile">AQUI</a>
                                </span>
                            </b-col>
                        </b-row>
                        <b-row v-if="filteredMessages.length === 0">
                            <b-col class="mt-4 d-flex align-items-center justify-content-center">
                                <CustomButton
                                :disabled="!message.default_message_id"
                                :loading="importLoading"
                                @click="importFile"
                                > Importar Planilha
                                </CustomButton>
                                <input 
                                    type="file"
                                    ref="fileInputMessage"
                                    style="display: none;"
                                    @change="handleFileImportMessage"
                                />
                            </b-col>
                        </b-row>
                    </b-col>
                </div>
            <!-- </b-overlay> -->
        </div>

        <template #footer>
            <b-row>
                <b-col style="max-width:max-content;">
                    <CustomButton
                    :disabled="true"
                    @click="$router.push({ name: 'messagesView' })"
                    > {{ hasExported ? 'Fechar' : 'Cancelar' }}
                    </CustomButton>
                </b-col>
                <b-col class="d-flex justify-content-end">
                    
                    <!-- <CancelButton @click="$router.push({ name: 'messagesView' })"> Cancelar </CancelButton> -->
                    
                    <CustomButton v-if="hasExported"
                    :loading="exportLoading || sendLoading"
                    @click="hasExported=false;"
                    > Editar
                    </CustomButton>

                    <CustomButton class="mx-3"
                    :disabled="!verifyExport"
                    :loading="exportLoading || sendLoading"
                    @click="exportOrder"
                    > Exportar Ordem
                    </CustomButton>
                    <!-- <button 
                        style="width: 240px; min-height: 56px; font-size: 16px; font-weight: 600; border-radius: 10px; background: transparent; border: 1px solid #EB694A; color: #EB694A;"
                        class="d-flex align-items-center justify-content-center mr-3"
                        @click="exportOrder"
                    >
                        <span v-if="!exportLoading">
                            Exportar Ordem
                        </span>
                        <span v-else>
                            <b-spinner small />
                        </span>
                    </button> -->

                    <CustomButton
                    :loading="sendLoading"
                    :disabled="!verifySend"
                    @click="openSendConfirm"
                    > Enviar Mensagens
                    </CustomButton>

                    <!-- <button @click="openSendConfirm" :disabled="!verifySend"
                        style="width: 240px; min-height: 56px; font-size: 16px; font-weight: 600; border-radius: 10px; background: transparent; border: 1px solid #EB694A; color: #EB694A;"
                        class="d-flex align-items-center justify-content-center"
                    >
                        <span v-if="!sendLoading">
                            Enviar Mensagens
                        </span>
                        <span v-else>
                            <b-spinner small />
                        </span>
                    </button> -->
                </b-col>
            </b-row>
        </template>

        </DefaultPageComponent>
        
        <div style="position: fixed; top: 10%; left: 20%; width: 60vw; height: min-content; background: #1C1B1B; z-index: 10000; border-radius: 25px;" class="p-4" v-if="modals.showMessage">
            <b-row style="position: absolute; width: calc(100% - 20px); top:10px; z-index:10;">
                <b-col style="font-size: 24px; font-weight: 400;" class="text-right">
                    <span style="cursor: pointer;" @click="modals.showMessage = false;">x</span>
                </b-col>
            </b-row>
            <b-row style="min-height: 60vh;" class="px-3">
                <b-col>
                    <div>
                        Título
                    </div>
                    <div class="mt-3 mb-3">
                        <b-input style="box-shadow: none;" v-model="defaultMessage.title" disabled></b-input>
                    </div>
                    <div>
                        Mensagem
                    </div>
                    <div style="height: 100%;" class="mt-2">
                        <!-- <b-form-textarea style="height: calc(100% - 125px); box-shadow: none;" v-model="defaultMessage.message" disabled></b-form-textarea> -->
                        <div style="white-space:pre-wrap; height: calc(100% - 125px); box-shadow: none; border: 1px #535252 solid;" class="px-2" v-html="defaultMessage.message"></div>
                    </div>
                </b-col>
                <b-col>
                    <div>
                        Anexos
                    </div>
                    <div style="height: 93%; border: 1px solid #535252; overflow-y: auto;" class="mt-3">
                        <div style="max-height: 100%; height: 57vh;">
                            <div v-for="attachment in attachments" :key="attachment.id">
                                <div class="p-3">
                                    {{ attachment.title }}
                                </div>
                                <div class="text-center">
                                    <div class="py-3">
                                    <img :src="attachment.file_path" alt="Attachment Image" width="350">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div class="modal-overlay" v-if="modals.showMessage" @click="modals.showMessage = false;"></div>
    </div>  
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import CustomButton from '@/components/CustomButton.vue';
// import CancelButton from '@/components/CancelButton.vue';
export default {
    name: 'MessageAddView',
    components:{
        DefaultPageComponent,
        CustomButton,
        // CancelButton,
    },
    data () {
        return {
            columns: [
                {
                    'customerName': 
                    {
                        label: 'Cliente',
                    }
                },
                // {
                //     'value': 
                //     {
                //         label: 'Valor',
                //     }
                // },
                {
                    'phone': 
                    {
                        label: 'Telefone',
                    }
                },
                {
                    'whatsapp_group': 
                    {
                        label: 'Grupo WhatsApp',
                    }
                },
            ],
            loading: false,
            importLoading: false,
            exportLoading: false,
            sendLoading: false,
            hasExported: false,
            tagsHasValue: false,
            messages: [],
            advisors: [],
            validIds: [67, 70, 30, 24],
            tags: [],
            message: {
                default_message_id: '',
                advisor_id: '',
                user_id: '',
                parameters: '',
                type: '',
                message_sent: 0,
                file_importation_path: '',
                file_order_path: '',
            },
            itemMessage: {
                message_id: '',
                message: '',
                customer_id: '',
                value: '',
            },
            attachamentMessages: {
                message_id: '',
                attachament_id: '',
            },
            filteredMessages: [],
            filters: {
                messageId: '',
                advisorId: '',
            },
            modals: {
                showMessage: false,
            },
            defaultMessage: {},
            attachments: [],
            temp_id: null,
            expectedFields: []
        }
    },
    mounted () {
        this.getAdvisors();
        this.getMessages();
    },
    computed:{
        verifyExport(){
            return (
                this.message.file_importation_path && 
                this.tagsHasValue
            )
        },
        verifySend(){
            return (
                this.message.default_message_id &&
                this.message.advisor_id &&
                this.message.file_importation_path &&
                this.tagsHasValue &&
                this.hasExported
            )
        },
    },
    methods: {
        verifyTags(){
            if(this.tags.filter(e=>!e.type).every(tag=>tag.value)) this.tagsHasValue = true;
            else this.tagsHasValue = false;
        },
        getAdvisors () {
            this.$axios.get('advisors').then((response) => {
                const filteredAdvisors = response.data.data.filter(advisor => 
                    this.validIds.includes(advisor.id)
                );
                this.advisors = filteredAdvisors;
            });
        },
        getMessages () {
            this.$axios.get('default-messages').then((response) => {
                this.messages = response.data.data;
            });
        },
        changeAdvisor(){
            var advisor = this.advisors.find(e=>e.id==this.message.advisor_id);
            var advisorName = advisor ? advisor.first_name+" "+advisor.last_name : '';
            var idx = this.tags.findIndex(e=>e.id==2) 
            if(idx>=0) this.tags[idx].value = advisorName;
            this.verifyTags();
        },
        getTag(tagID) {
            this.$axios.get(`tags/${tagID}`).then((response) => {
                this.tags.push(response.data.data);
                var advisor = this.advisors.find(e=>e.id==this.message.advisor_id);
                var advisorName = advisor ? advisor.first_name+" "+advisor.last_name : ''
                this.tags.forEach(tag => {
                    if(tag.id==2) tag.value = advisorName;
                    else tag.value = ''
                });
                this.tags.sort((a,b)=>{
                    if(a.id==2) return -1;
                    if(b.id==2) return 1;
                    var intA = parseInt(a.tag.replace(/\D/g, ''))
                    var intB = parseInt(b.tag.replace(/\D/g, ''))
                    return intA - intB;
                });
                this.verifyTags();
            }).catch((error) => {
                console.log(error);
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar a tag.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
                return;
            });
        },
        showMessage () {
            if (this.message.default_message_id === '') {
                this.$swal({
                    title: 'Selecione uma mensagem',
                    text: 'Antes de visualizar, por favor, selecione uma mensagem na lista de opções em "Mensagem".',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });

                return;
            }
            this.getDefaultMessageById();
            this.modals.showMessage = true;
        },
        getDefaultMessageById () {
            this.$axios.get(`default-messages/${this.message.default_message_id}`).then((response) => {
                this.attachments = [];
                this.defaultMessage = response.data.data;
                if (this.defaultMessage.attachments.length > 0) {
                    this.defaultMessage.attachments.forEach((attachment) => this.getAttachmentById(attachment.attachment_id))
                }
                if (this.tags.length > 0) {
                    this.defaultMessage.message = this.replaceTags(this.defaultMessage.message, this.tags);
                }
                this.defaultMessage.message = this.defaultMessage.message.replace(/\/n/g, '<br>');
            });
        },
        replaceTags(message, tags) {
            tags.forEach(tag => {
                const regex = new RegExp(tag.tag, 'g');
                message = message.replace(regex, "<span class='p-1' style='color: #FFF; background-color: #EB694A; border-radius: 10px; line-height: 2.5em;'>" + tag.description + "</span>");
            });
            return message;
        },
        getAttachmentById(attachmentID) {
            this.$axios.get(`attachments/${attachmentID}`).then((response) => {
                this.attachments.push(response.data.data);
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar o anexo.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
                return;
            });
        },
        async downloadFile () {
            if (this.message.default_message_id === '') {
                this.$swal({
                    title: 'Selecione uma mensagem',
                    text: 'Antes de baixar o modelo, por favor, selecione uma mensagem na lista de opções em "Mensagem".',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            }

            const response = await this.$axios.get('/messages/download/'+this.message.default_message_id);
            
            this.$axios({
                url: response.data.file_url,
                method: 'GET',
                responseType: 'blob',
            })
                .then(data => {
                    const url = window.URL.createObjectURL(new Blob([data.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Modelo - Importação de Mensageria.xlsx');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(error => {
                    console.error('Error downloading file:', error);
                });
        },
        verifyArchiveFormat(file){
            if(!file?.name.split('.').pop().includes('xlsx')){
                this.$swal({
                    title: 'Arquivo inválido',
                    text: 'Verifique se o arquivo selecionado está no formato Excel .xlsx',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return false;
            }
            return true;
        },
        handleFileImportMessage (event) {
            const file = event.target.files[0];
            if(!this.verifyArchiveFormat(file)) {
                this.loading=false;
                return;
            }

            if (file) {
                this.loading = true;
                const formData = new FormData();
                formData.append('sheet', file);
                formData.append(
                    'expectedFields', 
                    this.messages.find(e=>e.id==this.message.default_message_id)
                    .tags.filter(e=>e.tag_info.type)
                    .map(e=>{
                        return e.tag_info.tag+' '+e.tag_info.description;
                    })
                );

                this.$axios.post('messages/import', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(response => {
                        if (response.status == 200) {
                            this.message.file_importation_path = response.data.file_importation_path;
                            this.filteredMessages = response.data.data;
                            this.expectedFields = this.messages.find(e=>e.id==this.message.default_message_id)
                                .tags.filter(e=>e.tag_info.type)
                                .map(e=>{
                                    return e.tag_info.description;
                                })
                        } 
                        else { 
                            console.log(response);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.message.file_importation_path = '';
                        var text = "";
                        if(error.response.status == 422 && error.response.data.message[0]?.[0]?.msg){
                            text+="<div style=\"text-align:start;\">";
                            text+="Encontramos inconsistências no arquivo importado. Por favor, revise as mensagens abaixo para corrigir os problemas:";
                            text+="<br>";text+="<br>";
                            text+="<ul>";
                            error.response.data.message[0]?.forEach(e=> text+="<li>"+e.msg+"</li>" );
                            text+="</ul>";
                            text+="<br>";
                            text+="Certifique-se de corrigir esses problemas e tente importar o arquivo novamente.";
                            text+="</div>";
                            this.$swal({
                                title: 'Inconsistências encontradas',
                                html: text,
                                icon: 'warning',
                                confirmButtonText: 'OK',
                                confirmButtonColor: '#C44C30',
                                allowOutsideClick: false,
                                customClass: {
                                    confirmButton: 'custom-confirm-button-class',
                                }
                            });
                        }
                        else {
                            text+="<div style=\"white-space:pre-wrap; text-align:start;\">";
                            text+= error.response.data.message[0]?.[0]?.msg || 'Ocorreu um erro ao executar a ação, tente novamente.';
                            text+="</div>";
                            this.$swal({
                                title: error.response.data.message[0]?.[0]?.title || 'Atenção',
                                html: text,
                                icon: 'warning',
                                confirmButtonText: 'OK',
                                confirmButtonColor: '#C44C30',
                                allowOutsideClick: false,
                                customClass: {
                                    confirmButton: 'custom-confirm-button-class',
                                }
                            });
                        }
                    })
                    .finally(() => this.loading = false)
            } else {
                this.loading = false;
            }
        },
        export() {
            if (!this.message.file_importation_path) {
                this.$swal({
                    title: 'Importação de planilha obrigatória',
                    text: 'Para gerar a ordem é obrigatória a importação do arquivo contendo os códigos dos clientes com os seus valores.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            }
        },
        importFile () {
            if (this.message.default_message_id === '') {
                this.$swal({
                    title: 'Selecione uma mensagem',
                    text: 'Antes de importar a planilha, por favor, selecione uma mensagem na lista de opções em "Mensagem".',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });

                return;
            }

            this.$refs.fileInputMessage.click();
        },
        selectedMessage (defaultMessageId) {
            this.tagsHasValue=false;
            this.tags = [];
            const defaultMessage = this.messages.find(message => message.id === defaultMessageId);
            this.message.type = defaultMessage.type;
            if (defaultMessage.tags.length > 0) {
                defaultMessage.tags.forEach((tag => {
                    this.getTag(tag.tag_id);
                }));
            }
        },
        async exportOrder () {
            if (this.filteredMessages.length === 0) {
                this.$swal({
                    title: 'Importação de planilha obrigatória',
                    text: 'Para gerar a ordem é obrigatória a importação do arquivo contendo os códigos dos clientes com os seus valores.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });

                return;
            }

            if(!this.tagsHasValue){
                this.$swal({
                    title: 'Tags não preenchidas',
                    text: 'Para exportar uma ordem, é necessário preencher todas as tags.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });

                return;
            }

            var link = null;
            if(!this.hasExported){
                if(this.temp_id) {
                    await this.$axios.delete('messages/' + this.temp_id);
                    this.temp_id = null;
                    const responsePost = await this.saveMessage();
                    this.temp_id = responsePost?.message?.id;
                    link = responsePost?.file_url;
                }
                else {
                    const responsePost = await this.saveMessage();
                    this.temp_id = responsePost?.message?.id;
                    link = responsePost?.file_url;
                }
                await this.exportDownloadByLink(link)
                this.hasExported=true;
            }
            else{
                if(this.temp_id){
                    await this.exportDownload(this.temp_id)
                    this.hasExported=true;
                }
            }
        },
        async exportDownload(id){
            var file_url = '';
            const response = await this.$axios('messages/export/' + id)
            file_url = response.data.file_url
            
            this.$axios({
                url: file_url,
                method: 'GET',
                responseType: 'blob',
            })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file_url.split('/').pop());
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.error('Error downloading file:', error);
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível realizar o download.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
            });
        },
        async exportDownloadByLink(file_url){
            this.$axios({
                url: file_url,
                method: 'GET',
                responseType: 'blob',
            })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file_url.split('/').pop());
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.error('Error downloading file:', error);
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível realizar o download.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
            });
        },
        openSendConfirm(){
            if(!this.verifySend){
                this.$swal({
                    title: 'Atenção',
                    text: 'Campo(s) obrigatório(s) ainda não preenchido(s)!',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            }
            else if(!this.hasExported){
                this.$swal({
                    title: 'Envio requer exportação de ordem',
                    text: 'Para o envio das mensagens é necessário que a ordem seja exportada primeiramente.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            }
            this.$swal({
                title: 'Confirmação de Envio de Mensagens',
                text: `Por favor, esteja ciente de que ao clicar em 'Enviar', o processo será irreversível e as mensagens serão enviadas aos clientes. Você tem certeza de que deseja prosseguir com esta ação?`,
                icon: 'warning',
                confirmButtonText: 'Enviar',
                confirmButtonColor: '#EB694A',
                customClass: {
                    confirmButton: 'custom-confirm-button-class',
                    cancelButton: 'custom-confirm-button-class',
                }
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.saveIntegration();
                }
            });
        },
        async saveIntegration () {
            try{
                this.loading = true;
                this.sendLoading = true;

                await this.$axios.put('messages/send/'+this.temp_id);

                this.$swal({
                    title: 'Mensagens enviadas',
                    text: 'As mensagens enviadas com sucesso!',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                    focusConfirm: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                })
                .then(() => {
                    this.$router.push('/messages');       
                });

            }
            catch(error){
                console.log(error)
                this.$swal({
                    title: 'Erro',
                    text: 'Houve um erro ao enviar as mensagens!',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                });
            }
            finally {
                this.loading = false;
                this.sendLoading = false;
            }
        },
        async saveMessage(){
            try{
                this.loading = true;
                this.sendLoading = true;

                this.message.parameters = this.tags.filter(e=>!e.type);

                var parameters = {};
                this.message.parameters.forEach(p=>{
                    parameters[p.tag] = {
                        label: p.description,
                        value: p.value
                    }
                })

                const modal = {
                    default_message_id: this.message.default_message_id,
                    advisor_id: this.message.advisor_id,
                    file_importation_path: this.message.file_importation_path,
                    parameters: parameters,
                    items_message: this.filteredMessages.map(m=>{
                        return {
                            customer_id: m.customerId,
                            value: m.value,
                        }
                    })
                }

                const response = await this.$axios.post('messages', modal);
                this.hasExported = true;
                return response.data;
            }
            catch(error){
                console.log(error);
                this.$swal({
                    title: 'Erro',
                    text: 'Houve um erro ao exportar a ordem!',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                });
                this.temp_id = null;
                this.hasExported = false;
            }
            finally {
                this.loading = false;
                this.sendLoading = false;
            }
        },
    }
}
</script>

<style>
    #integration {
        height: calc(100vh - 125px);
    }
</style>
