<template>
    <div>
        <b-navbar id="header" toggleable="lg" type="dark" variant="dark">
            <b-navbar-brand href="#">
                <b-button id="iconNavBar" v-b-toggle.sidebar>
                    <b-icon icon="list" />
                </b-button>
                <img src="@/assets/logo.svg" alt="Logo WFLOW" style="margin-left: 10px; height: 35px;">
            </b-navbar-brand>

            <b-row style="min-width:max-content; font-size:18px;" align-v="center">

                <div v-if="typeof acessos=='number'">
                    <span style="color: white;">
                        {{ (acessos==1 ? acessos+' acesso registrado' : acessos+' acessos registrados' )+' nos últimos 6 meses. Último acesso: ' + getFormattedDate(penultimo_acesso) }} 
                    </span>
                </div>

                <div class="px-2"></div>

                <NotificationComponent
                ref="notificationComponent"
                :userId="user.id"
                @showDetail="showNotification"
                />
                
                <div class="px-2"></div>

                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav class="ml-auto" style="display: flex; align-items: center; justify-content: center;">
                        <b-nav-item-dropdown :text="user.first_name + ' ' + user.last_name" right id="menuUser">
                            <b-dropdown-item to="/profile">
                                <b-icon icon="person" class="mr-2"></b-icon>
                                Perfil
                            </b-dropdown-item>
                            <b-dropdown-item @click="handleLogout">
                                <b-icon icon="door-closed" class="mr-2"></b-icon>
                                Sair
                            </b-dropdown-item>
                        </b-nav-item-dropdown>
                    </b-navbar-nav>
                </b-collapse>
            </b-row>
            <NotificationModal
            ref="notificationModal"
            :notification="notification"
            />
        </b-navbar>
    </div>
</template>

<script>
import Cookie from '@/service/cookie'
import { mapState } from 'vuex';
import NotificationComponent from './NotificationComponent.vue';
import NotificationModal from './NotificationModal.vue';

export default {
    name: 'NavbarComponent',
    components:{
        NotificationComponent,
        NotificationModal,
    },
    data () {
        return {
            notification: {
                id: null,
                user_id: null,
                template_id: null,
                title: null,
                message: null,
                is_read: null,
                is_deleted: null,
                created_at: null,
            },
            acessos: null,
        }
    },
    mounted () {
        const intervalId = setInterval(()=>{
            if(this.user){
                clearInterval(intervalId);
                this.getAccessCount();
            }
        }, 0)
    },
    computed: {
        ...mapState('user', {
            user: (state) => state.user,
        }),
    },
    methods: {
        async showNotification(id){
            try{
                const response = await this.$axios.put('notification/'+id, { is_read: 1 })
                this.notification = response.data.notification;
                this.$refs.notificationModal.show();
                this.$refs.notificationComponent.getNotifications();
            }
            catch(error){
                console.log(error)
                this.$swal({
                    title: 'Erro',
                    text: `Não foi possível carregar a notificação.`,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C7300C',
                })
            }
        },
        async getAccessCount(){
            const response = await this.$axios.get("logs/access-count?user_id="+this.user.id+"&description=Login")
            this.acessos = response.data.acessos;
            this.penultimo_acesso = response.data.penultimo_acesso;
        },
        handleLogout () {
            this.saveLog();
            Cookie.deleteToken();
            this.$store.commit('user/STORE_USER', '');
            this.$router.push({ name: 'login' });
        },
        saveLog () {
            let objLog = {
                description: 'Logout'
            }

            this.$axios.post('logs', objLog).then((response) => {
                if(response.status != 201) {
                    console.log(response);
                }
            }).catch((error) => {
                console.log(error);
            });
        },
        getFormattedDate(date){
            if(!date) return '---';
            var day = date.substring(8,10);
            var month = date.substring(5,7);
            var year = date.substring(0,4);
            var hour = date.substring(11,13);
            var minute = date.substring(14,16);
            var dateBR = day+'/'+month+'/'+year+' às '+hour+':'+minute;
            return dateBR;
        }
    }
}
</script>

<style>
    /* Navbar */
    #header {
        height: 62px;
        background-color: #000 !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    #iconNavBar {
        background-color: #000 !important; 
        border: 0 !important;
        box-shadow: none !important;
        color: #FFF !important;
    }

    #menuUser a {
        color: #FFF;
    }

    #menuUser a:hover {
        color: #CCC;
    }

    #menuUser ul {
        margin-top: 10px;
        background-color: #C34D2F;
    }

    #menuUser li a {
        font-size: 16px;
        background-color: #C34D2F;
        color: #FFF;
    }
</style>
