<template>
    <div id="goals" style="height:100%; width:100%;">
        <b-col class="px-0 mx-0" style="height:100%; width:100%;">
            <DefaultPageComponent
            :loading="loading"
            :title="'Clientes Alvos da Campanha: '+title"
            haveBackButton
            @onBack="back"
            haveContentMargin
            >

            <!-- CONTENT -->
            <b-col class="h-100" style="max-height: calc(100vh - 420px) !important;">
                <b-row class="mb-3 pt-3" align-h="between">
                    <b-col>
                        <div class="form-group" :style="readonly? '' : 'opacity:0.5;'">
                            <label for="">Categoria da campanha:</label>
                            <input disabled 
                            class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;" 
                            v-model="type">
                        </div>
                    </b-col>
                    <b-col>
                        <InputDatePicker
                        :inputId="'inputStart'"
                        @input="(value)=>{ dateStart=value; getMinDate(dateStart) }"
                        label="Data de início:"
                        placeholder="Selecione"
                        :value="dateStart"
                        :initialDate="dateEnd"
                        :maxDate="maxDate"
                        resetButton="Cancelar"
                        :disabled="readonly"
                        />
                    </b-col>
                    <b-col>
                        <InputDatePicker
                        :inputId="'inputEnd'"
                        @input="(value)=>{ dateEnd=value; getMaxDate(dateEnd) }"
                        label="Data de término:"
                        placeholder="Selecione"
                        :value="dateEnd"
                        :initialDate="dateStart"
                        :minDate="minDate"
                        resetButton="Cancelar"
                        :disabled="readonly"
                        />
                    </b-col>
                    <b-col></b-col>
                </b-row>

                <b-row class="mx-0 mt-3 mb-1">
                    <span style="font-size:18px; font-weight:600;"> Clientes e Ativos </span>
                </b-row>
                <b-table class="custom-table" style="max-height:100% !important" borderless sticky-header 
                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                :fields="type=='Renda Fixa' ? rendaFixaColumns : columns" :items="listActives" show-empty>
                    <template #empty="">
                        <b-col class="text-center pb-3">
                            Não há ativos da campanha em sua carteira
                        </b-col>
                    </template>
                    <template #head(customer)="data">
                        <b-row class="mx-0" align-h="start" align-v="center">
                            {{ data.label }} <b-icon icon="arrow-up-short" :style="'transition:0.25s;' + (sortBy==data.column&&!sortDesc ? 'rotate: 180deg;' : '')"></b-icon>
                        </b-row>
                    </template>
                    <template #head(customer_full_name)="data">
                        <b-row class="mx-0" align-h="start" align-v="center">
                            {{ data.label }} <b-icon icon="arrow-up-short" :style="'transition:0.25s;' + (sortBy==data.column&&!sortDesc ? 'rotate: 180deg;' : '')"></b-icon>
                        </b-row>
                    </template>
                    <template #head(subproduct)="data">
                        <b-row class="mx-0" align-h="start" align-v="center">
                            {{ data.label }} <b-icon icon="arrow-up-short" :style="'transition:0.25s;' + (sortBy==data.column&&!sortDesc ? 'rotate: 180deg;' : '')"></b-icon>
                        </b-row>
                    </template>
                    <template #head(n_document_fund)="data">
                        <b-row class="mx-0" align-h="start" align-v="center">
                            {{ data.label }} <b-icon icon="arrow-up-short" :style="'transition:0.25s;' + (sortBy==data.column&&!sortDesc ? 'rotate: 180deg;' : '')"></b-icon>
                        </b-row>
                    </template>
                    <template #head(active)="data">
                        <b-row class="mx-0" align-h="start" align-v="center">
                            {{ data.label }} <b-icon icon="arrow-up-short" :style="'transition:0.25s;' + (sortBy==data.column&&!sortDesc ? 'rotate: 180deg;' : '')"></b-icon>
                        </b-row>
                    </template>
                    <template #head(due_date)="data">
                        <b-row class="mx-0" align-h="end" align-v="center">
                            {{ data.label }} <b-icon icon="arrow-up-short" :style="'transition:0.25s;' + (sortBy==data.column&&!sortDesc ? 'rotate: 180deg;' : '')"></b-icon>
                        </b-row>
                    </template>
                    <template #head(roa_funds)="data">
                        <b-row class="mx-0" align-h="end" align-v="center">
                            {{ data.label }} <b-icon icon="arrow-up-short" :style="'transition:0.25s;' + (sortBy==data.column&&!sortDesc ? 'rotate: 180deg;' : '')"></b-icon>
                        </b-row>
                    </template>
                    <template #head(quantity)="data">
                        <b-row class="mx-0" align-h="end" align-v="center">
                            {{ data.label }} <b-icon icon="arrow-up-short" :style="'transition:0.25s;' + (sortBy==data.column&&!sortDesc ? 'rotate: 180deg;' : '')"></b-icon>
                        </b-row>
                    </template>
                    <template #head(net)="data">
                        <b-row class="mx-0" align-h="end" align-v="center">
                            {{ data.label }} <b-icon icon="arrow-up-short" :style="'transition:0.25s;' + (sortBy==data.column&&!sortDesc ? 'rotate: 180deg;' : '')"></b-icon>
                        </b-row>
                    </template>
                </b-table>
            </b-col>

            <template #footer>
                <b-row class="m-0" align-h="between">
                    <CustomButton
                    @click="back"
                    :disabled="true"
                    > Voltar
                    </CustomButton>
                </b-row>
            </template>

            </DefaultPageComponent>

        </b-col>
    </div>
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import CustomButton from '@/components/CustomButton.vue';
import InputDatePicker from '@/components/InputDatePicker.vue';
export default {
    name: "CampaignCustomersView",
    components:{
        DefaultPageComponent,
        CustomButton,
        InputDatePicker,
    },
    data(){
        return {
            loading: false,
            loadingSubmit: false,

            readonly: false,
            items: [],
            listActives: [],

            minDate: null,
            maxDate: null,

            sortBy: null,
            sortDesc: null,

            dateStart: null,
            dateEnd: null,
            title: null,
            description: null,

            detail: null,
            advisor_id: null,
            type: null,

            rendaFixaColumns: [
                {
                    'customer': 
                    {
                        label: 'Código',
                        class: 'text-left',
                        sortable: true,
                    }
                },
                {
                    'customer_full_name': 
                    {
                        label: 'Nome',
                        class: 'text-left',
                        sortable: true,
                    }
                },
                {
                    'subproduct': 
                    {
                        label: 'Sub categoria',
                        class: 'text-left',
                        sortable: true,
                    }
                },
                {
                    'active': 
                    {
                        label: 'Ativo',
                        class: 'text-left',
                        sortable: true,
                    }
                },
                {
                    'due_date': 
                    {
                        label: 'Data de vencimento',
                        class: 'text-right',
                        sortable: true,
                        formatter: (value) => {
                            return this.$options.filters.formatDate(value);
                        },
                    }
                },
                {
                    'quantity': 
                    {
                        label: 'Quantidade',
                        class: 'text-right',
                        sortable: true,
                    }
                },
                {
                    'net': 
                    {
                        label: 'NET',
                        class: 'text-right',
                        sortable: true,
                    }
                },
            ],
            columns: [
                {
                    'customer': 
                    {
                        label: 'Código',
                        class: 'text-left',
                        sortable: true,
                    }
                },
                {
                    'customer_full_name': 
                    {
                        label: 'Nome',
                        class: 'text-left',
                        sortable: true,
                    }
                },
                {
                    'subproduct': 
                    {
                        label: 'Sub categoria',
                        class: 'text-left',
                        sortable: true,
                    }
                },
                {
                    'n_document_fund': 
                    {
                        label: 'CNPJ',
                        class: 'text-left',
                        sortable: true,
                        formatter: (value) => {
                            return this.$options.filters.formatCnpj(value);
                        },
                    }
                },
                {
                    'active': 
                    {
                        label: 'Ativo',
                        class: 'text-left',
                        sortable: true,
                    }
                },
                {
                    'roa_funds': 
                    {
                        label: 'ROA',
                        class: 'text-right',
                        sortable: true,
                    }
                },
                {
                    'quantity': 
                    {
                        label: 'Quantidade',
                        class: 'text-right',
                        sortable: true,
                    }
                },
                {
                    'net': 
                    {
                        label: 'NET',
                        class: 'text-right',
                        sortable: true,
                    }
                },
            ],
        }
    },
    mounted(){
        if(!this.$route.params.type || !this.$route.params.detailId || !this.$route.params.advisor_id || !this.$route.params.detail) 
            this.$router.push({name:'campaigns'})

        this.getDetail(this.$route.params.detail);
        this.type = this.$route.params.type;
        this.advisor_id = this.$route.params.advisor_id;
        this.readonly = true;

        if(this.$route.params.detailId)
            this.getDetails(this.$route.params.detailId);
    },
    computed:{
        isValid(){
            return (
                this.dateStart
                && this.dateEnd
                && this.title
                && this.description
            );
        },
    },
    methods: {
        async getDetails(id){
            var typeId = null;
            if(this.type=="Renda Fixa") typeId = 4;
            if(this.type=="Fundos") typeId = 3;
            if(this.type=="Previdência") typeId = 1;

            var endpoint = "campaigns/advisors/portfolio-items?campaign_id="+id;
            endpoint+="&classification_id="+typeId;
            endpoint+="&advisor_id="+this.advisor_id;

            const response = await this.$axios.get(endpoint)

            this.listActives = response.data
        },
        getDetail(item){
            this.detail = item;
            this.dateStart = this.detail.start_date
            this.dateEnd = this.detail.end_date
            this.title = this.detail.title
            this.description = this.detail.description
        },
        back(){
            if(this.readonly)
                this.$router.push({ name:'campaigns' })
            else
                this.$router.push({ name:'newCampaign', params: {type: this.type, listActives: this.listActives} })
        },
        async getMinDate(date){
            if(!date) this.minDate=null;
            else this.minDate = new Date(date).toISOString().split('T')[0];
        },
        async getMaxDate(date){
            if(!date) this.maxDate=null;
            else this.maxDate = new Date(date).toISOString().split('T')[0];
        },
    },
}
</script>

<style scoped>
 * /deep/ th{
    transform:translateY(-1px);
}
* /deep/ td{
    border:none !important;
    background-color: #2F2E2E !important;
}
/* * /deep/ table tbody tr td{
    padding: 5px 10px !important;
} */
* /deep/ .close{
    color: white !important;
}
* /deep/ .modal-header{
    border: none !important;
}
* /deep/ .modal-body{
    background-color: #2B2B2B !important;
}
* /deep/ textarea::placeholder{
    color:#E5E2E1 !important;
}
</style>