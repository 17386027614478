<template>
    <b-row style="min-height: 98px;" class="px-4">
        <b-col cols="3" class="d-flex align-items-center p-0">
            <span style="font-size: 22px; font-wight: 600;"> {{ title }} </span>
        </b-col>

        <slot></slot>

        <b-col class="px-5" style="border: 1px dashed #535252; display: flex; align-items: center; justify-content: space-between">
            <div v-if="loading || disabled" @click="clickDisabled" style="width:100%;height:100%; position:absolute;top:0px;left:0px; z-index:2;"></div>
            <span v-if="!file">
                <!-- :borderColor="'transparent'"
                :bgColor="'#EB694A20'"
                :textColor="'#EB694A'" -->
                <GlassButton
                :loading="loading"
                :disabled="disabled"
                @click="openFileImport"
                > 
                    <span> Escolher arquivo ... </span>
                </GlassButton>
                <!-- <a href="#" class="ml-4">
                    Arraste o arquivo aqui
                </a> -->
                <input 
                    type="file"
                    :ref="inputRef"
                    style="display: none;"
                    @change="handleFileImport"
                />
            </span>
            <b-row v-else align-v="center" align-h="between" class="mx-0 w-100">
                <div>
                    <b-icon icon="check-circle" class="mr-2" style="color:#00FF29"></b-icon>
                    {{ file.name }}
                </div>
                <b-button variant="danger" @click="clear"
                style="background:transparent !important; color:red !important; border:none !important;"
                >
                    <b-icon icon="x-circle"></b-icon>
                </b-button>
            </b-row>
            <!-- <span>
                <img src="@/assets/calc.png" alt="" class="mr-2" v-if="quantity === 0">
                <img src="@/assets/check_circle_green_outline.png" alt="" class="mr-2" v-else>
                <label :style="quantity === 0 ? '' : 'color: #4CAF50;'">{{ quantity }} / 1</label>
            </span> -->
        </b-col>
    </b-row>
</template>

<script>
import GlassButton from './GlassButton.vue';
export default {
    name: 'ImportationComponent',
    components:{
        GlassButton,
    },
    props: {
        title: {
            type: String,
            default: 'Tabelas'
        },
        inputRef: {
            type: String,
            default: 'inputFile'
        },
        allowedFormats: {
            type: Array,
            default: ()=>{ return ['xlsx', 'xls', 'csv']; }
        },
        disabled: Boolean,
        showDelete: Boolean,
    },
    data(){
        return {
            loading: false,
            file: null,
        }
    },
    methods: {
        clickDisabled(){
            if(this.disabled || this.loading) {
                this.$emit('clickDisabled')
                return;
            }
        },
        openFileImport (){
            if (this.loading || this.disabled) return;
            this.$refs[this.inputRef].click();
        },
        clear(){
            this.file=null;
            this.$emit('change', this.file)
        },
        handleFileImport(event){
            const file = event.target.files[0];
            if(this.allowedFormats.find(e=>e==file.name.split('.').pop())){
                this.file = file;
                this.$emit('change', this.file)
            }
            else{
                this.$swal({
                    title: 'Arquivo inválido',
                    text: 'Arquivo inválido, por favor, verifique o modelo de importação.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            }
        },
        
    },
}
</script>

<style scoped>

</style>