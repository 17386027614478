<template>
    <div id="goals" style="height:100%; width:100%;">
        <b-col class="px-0 mx-0" style="height:100%; width:100%;">
            <DefaultPageComponent
            :loading="loading"
            title="Campanhas"
            :haveBackButton="true"
            @onBack="$router.push({name: 'dashboard'})"
            :haveNextButton="user.role==1"
            :buttonText="'Nova Campanha'"
            @onTap="showNewModal"
            :haveContentMargin="true"
            >

            <template #filters>
                <b-row>
                    <b-col>
                        <div class="form-group">
                            <label for="">Categoria da campanha:</label>
                            <select class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;" v-model="type">
                                <option v-for="item in listTypes" :key="item.id" :value="item.id"> {{ item.name }} </option>
                            </select>
                        </div>
                    </b-col>
                    <b-col>
                        <div class="form-group">
                            <label for="">Status:</label>
                            <select class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;" v-model="status">
                                <option v-for="item in listStatus" :key="item.id" :value="item.id">{{ item.name }} </option>
                            </select>
                        </div>
                    </b-col>
                    <b-col></b-col>
                    <b-col></b-col>
                    <b-col>
                        <b-row class="m-0" align-h="end">
                            <GlassButton
                            :loading="loading"
                            @click="getItems"
                            > 
                                <img src="@/assets/search.png" />
                                Pesquisar
                            </GlassButton>
                        </b-row>
                    </b-col>
                </b-row>
            </template>

            <!-- CONTENT -->
            <b-table class="custom-table" borderless sticky-header :fields="columns" :items="items" show-empty>
                <template #empty="">
                    <b-col class="text-center">
                        Não há dados disponíveis
                    </b-col>
                </template>

                <template #cell(type)="row">
                    <b-row align-h="start" class="m-0">
                        {{ listTypes.find(e=>e.id==row.item.type).name }}
                    </b-row>
                </template>

                <template #cell(description)="row">
                    <b-row align-h="center" class="m-0">
                        <b-button
                        style="width: 35px; height: 30px; background-color: #242323; color:#EB694A; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252;"
                        @click="showDescription(row.index)"
                        >
                            <b-icon icon="eye-fill"></b-icon>
                        </b-button>
                    </b-row>
                </template>
                
                <template #cell(advisor_id)="row">
                    <b-row style="min-width:150px;" align-h="start" class="m-0">
                        <b-col>
                            <div class="form-group" :style="row.item.advisor_id==user.advisor_id&&advOptions.length==1 ? 'opacity:0.5;' : ''">
                                <label for="">Assessor:</label>
                                <select class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;" 
                                v-model="row.item.advisor_id" :disabled="row.item.advisor_id==user.advisor_id&&advOptions.length==1">
                                    <option v-for="item in advOptions" :key="item.id" :value="item.id">{{ item.first_name+' '+item.last_name }} </option>
                                </select>
                            </div>
                        </b-col>
                    </b-row>
                </template>
                
                <template #head(customers)="data">
                    <b-row v-if="data" align-h="center" class="m-0 pb-1" style="font-size:25px;">
                        <b-icon id="info-icon" icon="info-circle" style="color:rgb(235, 105, 74);"></b-icon>
                        <b-tooltip target="info-icon" triggers="hover" variant="light">
                           <b style="font-size:17px;"> Clique em 'Ver clientes' para listar clientes com ativos relacionados a esta campanha. </b>
                        </b-tooltip>
                    </b-row>
                </template>
                <template #cell(customers)="row">
                    <b-row align-h="center" class="m-0">
                        <CustomButton
                        bgColor="#28A745"
                        borderColor="#28A745"
                        variant="success"
                        :not_clickable="!row.item.advisor_id"
                        @click="goToCustomers(row.item)"
                        > Ver clientes </CustomButton>
                    </b-row>
                </template>

                <template #cell(actions)="row">
                    <b-row align-h="end" class="m-0">
                        <b-button
                        style="width: 35px; height: 30px; background-color: #242323; color:#EB694A; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252;"
                        @click="goToDetails(row.item)"
                        >
                        <!-- @click="showItem(row.index)" -->
                            <b-icon icon="eye-fill"></b-icon>
                        </b-button>
                    </b-row>
                </template>
            </b-table>

            <template #footer>
                <div></div>
            </template>

            </DefaultPageComponent>

        </b-col>

        <card-modal title="Selecione a categoria da campanha" ref="newModalRef" style="z-index:2;">
            <b-col>
                <b-row class="mx-4 pt-4 pb-5" align-h="between" align-v="center">
                    <CustomButton
                    @click="newType='Renda Fixa'"
                    variant="success"
                    :disabled="newType!='Renda Fixa'"
                    bgColor="#28A745"
                    borderColor="#28A745"
                    textColor="#28A745"
                    > Renda Fixa
                    </CustomButton>

                    <div class="px-3"></div>

                    <CustomButton
                    @click="newType='Fundos'"
                    variant="success"
                    :disabled="newType!='Fundos'"
                    bgColor="#28A745"
                    borderColor="#28A745"
                    textColor="#28A745"
                    > Fundos
                    </CustomButton>

                    <div class="px-3"></div>

                    <CustomButton
                    @click="newType='Previdência'"
                    variant="success"
                    :disabled="newType!='Previdência'"
                    bgColor="#28A745"
                    borderColor="#28A745"
                    textColor="#28A745"
                    > Previdência
                    </CustomButton>
                </b-row>
                <b-row class="mx-4 pb-3" align-h="end">
                    <CancelButton
                    @click="$refs.newModalRef.closeModal();"
                    > Cancelar
                    </CancelButton>

                    <div class="mx-3"></div>
                    
                    <CustomButton
                    :loading="loadingRouter"
                    @click="goToNew"
                    :disabled="!newType"
                    > OK
                    </CustomButton>
                </b-row>
            </b-col>
        </card-modal>

        <card-modal :title="detail?.title" ref="modalRef" style="z-index:2;">
            <div class="row d-flex justify-content-center align-items-center">
                <b-col class="mx-4">
                    <b-row class="pb-2">
                        Descrição
                    </b-row>
                    <b-row class="p-4"
                    style="
                    border: 1px solid #535252; border-radius: 10px; background-color: #262625; 
                    max-height:50vh; overflow-y: auto; max-width:80vw;
                    white-space: pre-wrap;
                    ">{{ detail?.description }}</b-row>
                </b-col>
            </div>
        </card-modal>

    </div>
</template>

<script>
import CardModal from './CardModal.vue';
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import GlassButton from '@/components/GlassButton.vue';
import CustomButton from '@/components/CustomButton.vue';
import CancelButton from '@/components/CancelButton.vue';
import { mapState } from 'vuex';

export default {
    name: "CampaignsView",
    components:{
        CancelButton,
        CustomButton,
        GlassButton,
        'card-modal': CardModal,
        DefaultPageComponent,
    },
    data(){
        return {
            loading: false,
            loadingSubmit: false,
            loadingRouter: false,
            listStatus: [
                {id: "all", name: "Todos"},
                {id: "active", name: "Ativas"},
                {id: "expired", name: "Vencidas"},
            ],
            listTypes: [
                { name: "Todas", id: null },
                { name: "Renda Fixa", id: 1 },
                { name: "Fundos", id: 2 },
                { name: "Previdência", id: 3 },
            ],
            status: 'active',
            type: null,
            newType: null,
            detail: null,
            advisor: null,
            items: [],
            advisors: [],
            advOptions: [],
            columns: [
                {
                    'type': 
                    {
                        label: 'Tipo',
                        class: 'text-left',
                    }
                },
                {
                    'title': 
                    {
                        label: 'Título',
                        class: 'text-left',
                    }
                },
                {
                    'description': 
                    {
                        label: 'Descrição',
                        class: 'text-center pb-3',
                    }
                },
                {
                    'start_date': 
                    {
                        label: 'Início',
                        class: 'text-left',
                        formatter: (value) => {
                            if(!value) return "---";
                            return this.formatDateBR(value);
                        }
                    }
                },
                {
                    'end_date': 
                    {
                        label: 'Fim',
                        class: 'text-left',
                        formatter: (value) => {
                            if(!value) return "---";
                            return this.formatDateBR(value);
                        }
                    }
                },
                {
                    'advisor_id': 
                    {
                        // label: 'Ações',
                        label: '',
                        class: 'text-left pb-3',
                    }
                },
                {
                    'customers': 
                    {
                        // label: 'Ações',
                        label: '',
                        class: 'text-left pb-3',
                    }
                },
                {
                    'actions': 
                    {
                        // label: 'Ações',
                        label: '',
                        class: 'text-right pb-3',
                    }
                },
            ],
        }
    },
    mounted(){
        this.getAdvisors();
        this.getItems();
        if(this.$route.params.showModal){
            this.showNewModal();
            this.newType=this.$route.params.type;
        }
    },
    computed: {
        ...mapState('user', {
            user: (state) => state.user,
        }),
    },
    methods: {
        async getItems(){
            try{
                this.loading=true;
                var endpoint = 'campaigns';
                if(this.status) endpoint+='?status='+this.status;
                if(this.type) endpoint+='&type='+this.type;
    
                const response = await this.$axios.get(endpoint);
                this.items = response.data;
                if(this.advisors.length>0)
                    this.getAdvOptions();
            }
            catch(error){
                console.log(error);
            }
            finally{ this.loading=false; }
        },
        async getAdvisors(){
            try{
                this.loading=true;
                var endpoint = 'advisors';
                const response = await this.$axios.get(endpoint);
                this.advisors = response.data.data;
                this.getAdvOptions();
            }
            catch(error){
                console.log(error);
            }
            finally{ this.loading=false; }
        },
        getAdvOptions(){
            var listHunters = this.user.hunter_ids;
            var filtered = this.advisors.filter(e=>listHunters.includes(e.id));
            if(this.user.hunter_ids.length>0){
                this.items.forEach(i=>i.advisor_id=filtered[0]?.id);
                this.advOptions = this.advisors.filter(a=>this.user.hunter_ids.includes(a.id));
                this.items.forEach(i=>i.advisor_id = this.advOptions.find(e=>e.id==this.user.advisor_id).id)
                if(!this.items.some(i=>i.advisor_id)) this.items.forEach(i=>i.advisor_id=this.advOptions[0]);
            }
            else{
                this.items.forEach(i=>i.advisor_id=this.user.advisor_id);
                this.advOptions = this.advisors.filter(a=>a.id==this.user.advisor_id);
                this.items.forEach(i=>i.advisor_id = this.advOptions.find(e=>e.id==this.user.advisor_id).id)
                if(!this.items.some(i=>i.advisor_id)) this.items.forEach(i=>i.advisor_id = this.advOptions[0]);
            }
        },
        showNewModal(){
            this.newType=null;
            this.$refs.newModalRef.openModal();
        },
        goToNew(){
            if(!this.newType){
                this.$swal({
                    title: 'Atenção',
                    text: 'Selecione a categoria da campanha.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                });
                return;
            }
            this.loadingRouter = true;
            this.$router.push({ name: "newCampaign", params: {type: this.newType} })
        },
        goToDetails(item){
            this.loading = true;
            this.$router.push({ name: "newCampaignTwo", params: {readonly: true, detailId: item.id, type: this.listTypes.find(e=>e.id==item.type).name} })
        },
        goToCustomers(item){
            this.loading = true;
            this.$router.push({ name: "campaignCustomers", params: {detail: item, detailId: item.id, type: this.listTypes.find(e=>e.id==item.type).name, advisor_id: item.advisor_id} })
        },
        showDescription(idx){
            const item = {...this.items[idx]};
            this.detail = item;
            this.$refs.modalRef.openModal();
        },
        showItem(idx){
            const item = {...this.items[idx]};
            this.detail = item;
        },
        formatDateBR(dateString) {
            if (!dateString) {
                return '';
            }

            const [year, month, day] = dateString.split('-');
            const formattedDate = `${day}/${month}/${year}`;

            return formattedDate;
        },
    },
}
</script>

<style scoped>
* /deep/ .position-absolute{
    z-index: 2 !important;
}

* /deep/ th {
    padding-bottom: 0px !important;
}
</style>