<template>
    <div class="d-flex align-items-center justify-content-center" style="height: 100%; width: 100%;">
        <div style="height: 100%; width: 100%;">
            <b-form @submit.stop.prevent="onSubmit" style="height: 100%; width: 100%;">
            <DefaultPageComponent
            :loading="loading"
            :title="'Edição de assessor'"
            :haveBackButton="true"
            @onBack="$router.push({name: 'advisor'})"
            >

            <!-- CONTENT -->
            <div class="card-body" style="background-color: #1C1B1B; color: #000; height: calc(100% - 62px); border-radius: 0px 0px 8px 8px;">
                <div class="pb-2" style="color:white;">Dados do Assessor</div>
                

                <b-row class="mx-0">
                        
                        <!-- Imagem -->
                        <b-col class="p-0" style="min-width:208px; max-width:208px; min-height: 208px; max-height: 208px;">
                            <div class="w-100 h-100 d-flex align-items-center justify-content-center" 
                            style="background-color:#262625 !important; border:1px solid #535252 !important; border-radius:10px;">
                                
                                <b-button @click="openImageModal" :style="image?' position:absolute; top:10px; right:25px; width:50px !important;height:50px !important;':''"
                                variant="link" style="padding:0px !important; border-radius:100%">
                                    <div class="d-flex align-items-center justify-content-center"  :style="image?' width:50px !important;height:50px !important; font-size:20px;':''"
                                    style="width:150px; height:150px; background-color:#535252 !important; border-radius:100%; font-size:80px; color:#262625 !important;">
                                        <b-icon :icon="image ? 'pencil-fill' : 'person-plus-fill'"></b-icon>
                                    </div>
                                </b-button>

                                <img v-if="image" :src="image" alt="Foto do assessor"
                                class="w-100 h-100" style="border:1px solid #535252 !important; border-radius:10px;">
                            </div>
                        </b-col>
                        
                        <b-col>
                            <b-row class="py-2">
                                <b-col>
                                    <b-form-group id="inputGroupFirstName" label="Nome" label-for="inputGroupFirstName">
                                        <b-form-input
                                            id="inputFirstName"
                                            name="inputFirstName"
                                            v-model="advisor.first_name"
                                            style="min-height:56px;"
                                            v-validate="{ required: true }"
                                            :state="validateState('inputFirstName')"
                                            aria-describedby="inputFirstNameFeedback"
                                            data-vv-as="Nome"
                                            :disabled="loading"
                                            required
                                        ></b-form-input>

                                        <b-form-invalid-feedback id="inputFirstNameFeedback">{{ veeErrors.first('inputFirstName') }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="py-2">
                                <b-col>
                                    <b-form-group id="inputGroupNDocument" label="CPF" label-for="inputGroupNDocument">
                                        <b-form-input
                                            id="inputNDocument"
                                            name="inputNDocument"
                                            v-model="advisor.n_document"
                                            style="min-height:56px;"
                                            v-validate="{ required: true, cpf: true }"
                                            :state="validateState('inputNDocument')"
                                            aria-describedby="inputNDocumentFeedback"
                                            data-vv-as="CPF"
                                            v-mask="'###.###.###-##'"
                                            :disabled="loading"
                                            required
                                        ></b-form-input>

                                        <b-form-invalid-feedback id="inputNDocumentFeedback">{{ veeErrors.first('inputNDocument') }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="py-2">
                                <b-col>
                                    <b-form-group id="inputGroupCell" label="Célula" label-for="inputGroupCell">
                                        <b-form-select
                                            id="inputCell"
                                            name="inputCell"
                                            class="form-control"
                                            v-model="advisor.cell_id"
                                            style="min-height:56px;"
                                            v-validate="{}"
                                            :state="validateState('inputCell')"
                                            aria-describedby="inputCellFeedback"
                                            data-vv-as="Célula"
                                            :disabled="loading"
                                        >
                                            <b-form-select-option v-for="cell in cells" :value="cell.id" :key="cell.id">{{ cell.cell }}</b-form-select-option>
                                        </b-form-select>

                                        <b-form-invalid-feedback id="inputCellFeedback">{{ veeErrors.first('inputCell') }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>

                        <b-col>
                            <b-row class="py-2">
                                <b-col>
                                    <b-form-group id="inputGroupLastName" label="Sobrenome" label-for="inputGroupLastName">
                                        <b-form-input
                                            id="inputLastName"
                                            name="inputLastName"
                                            v-model="advisor.last_name"
                                            style="min-height:56px;"
                                            v-validate="{ required: true }"
                                            :state="validateState('inputLastName')"
                                            aria-describedby="inputLastNameFeedback"
                                            data-vv-as="Sobrenome"
                                            :disabled="loading"
                                            required
                                        ></b-form-input>

                                        <b-form-invalid-feedback id="inputLastNameFeedback">{{ veeErrors.first('inputLastName') }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="py-2">
                                <b-col>
                                    <b-form-group id="inputGroupEmail" label="E-mail" label-for="inputGroupEmail">
                                        <b-form-input
                                            id="inputEmail"
                                            name="inputEmail"
                                            v-model="advisor.email"
                                            style="min-height:56px;"
                                            v-validate="{ required: true, email: true }"
                                            :state="validateState('inputEmail')"
                                            aria-describedby="inputEmailFeedback"
                                            data-vv-as="E-email"
                                            :disabled="loading"
                                            required
                                        ></b-form-input>

                                        <b-form-invalid-feedback id="inputEmailFeedback">{{ veeErrors.first('inputEmail') }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="py-2">
                                <b-col>
                                    <b-form-group id="inputGroupCompany" label="Unidade" label-for="inputGroupCompany">
                                        <b-form-select
                                            id="inputCompany"
                                            name="inputCompany"
                                            class="form-control"
                                            v-model="advisor.company_id"
                                            style="min-height:56px;"
                                            v-validate="{}"
                                            :state="validateState('inputCompany')"
                                            aria-describedby="inputCompanyFeedback"
                                            data-vv-as="Unidade"
                                            :disabled="loading"
                                        >
                                            <b-form-select-option v-for="company in companies" :value="company.id" :key="company.id">{{ company.city }} / {{ company.state.acronym }}</b-form-select-option>
                                        </b-form-select>

                                        <b-form-invalid-feedback id="inputCompanyFeedback">{{ veeErrors.first('inputCompany') }}</b-form-invalid-feedback>
                                    </b-form-group>
                                    <b-row>
                                        <b-col class="my-3">
                                            <b-form-group id="inputGroupType" label="Tipo" label-for="inputGroupType">
                                                <b-form-select
                                                    id="inputType"
                                                    name="inputType"
                                                    class="form-control"
                                                    v-model="advisor.title"
                                                    style="min-height:56px;"
                                                    v-validate="{}"
                                                    :state="validateState('inputType')"
                                                    aria-describedby="inputTypeFeedback"
                                                    data-vv-as="Célula"
                                                    :disabled="loading"
                                                >
                                                    <b-form-select-option value="null" key="null">Selecione</b-form-select-option>
                                                    <b-form-select-option value="0" key="0">Assessor</b-form-select-option>
                                                    <b-form-select-option value="1" key="1">Parceiro</b-form-select-option>
                                                </b-form-select>

                                                <b-form-invalid-feedback id="inputTypeFeedback">{{ veeErrors.first('inputType') }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>

                    <!-- <b-row class="py-2 mx-0">
                        <b-col style="max-width:20%; min-width:250px;"></b-col>
                        <b-col></b-col>
                        <b-col>
                            <b-form-group id="inputGroupType" label="Tipo" label-for="inputGroupType">
                                <b-form-select
                                    id="inputType"
                                    name="inputType"
                                    class="form-control"
                                    v-model="advisor.title"
                                    style="min-height:56px;"
                                    v-validate="{}"
                                    :state="validateState('inputType')"
                                    aria-describedby="inputTypeFeedback"
                                    data-vv-as="Célula"
                                    :disabled="loading"
                                >
                                    <b-form-select-option value="null" key="null">Selecione</b-form-select-option>
                                    <b-form-select-option value="0" key="0">Assessor</b-form-select-option>
                                    <b-form-select-option value="1" key="1">Parceiro</b-form-select-option>
                                </b-form-select>

                                <b-form-invalid-feedback id="inputTypeFeedback">{{ veeErrors.first('inputType') }}</b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-row> -->

                <div class="py-4 row d-flex align-items-center justify-content-between mb-3 mx-0" style="border-bottom:1px solid #535252">
                    <div class="form-group" style="max-width:fit-content">
                        <span style="color:white;">Parceiros comerciais</span>
                    </div>

                    <GlassButton
                    :loading="loading"
                    @click="showModalCommercialPartnerForm()"
                    > 
                        <span style="font-size:30px;"><b-icon icon="plus"></b-icon></span>
                        Adicionar
                    </GlassButton>
                </div>
                <div class="row d-flex justify-content-center align-items-center">
                    <div class="col mt-3">
                        <b-table striped borderless small sticky-header :fields="columns" :items="commercialPartnersInMemory" style="max-height: 60vh !important;" show-empty empty-text="Sem registros para mostrar no momento..." head-variant="dark">
                            <template #cell(actions)="row">
                                <div class="d-flex justify-content-end align-items-center">
                                    <b-button 
                                        size="sm"
                                        @click="showModalCommercialPartnerForm(row.item)" class="px-2"
                                        style="color: #EB694A; border:1px solid #474646; border-radius:4px; box-shadow: none; background-color: transparent; font-size:16px;"
                                    >
                                        <b-icon icon="pencil-fill"></b-icon>
                                    </b-button>
                                    <div style="width:10px;"></div>
                                    <b-button 
                                        size="sm"
                                        @click="removeCommercialPartner(row.index)" class="px-2"
                                        style="color: #EB694A; border:1px solid #474646; border-radius:4px; box-shadow: none; background-color: transparent; font-size:16px;"
                                    >
                                        <b-icon icon="trash-fill"></b-icon>
                                    </b-button>
                                </div>
                            </template>
                        </b-table>
                    </div>
                </div>
                    
            </div>

            <template #footer>
                <div class="row d-flex align-items-center justify-content-end m-0 p-0">
                    <CancelButton
                    @click="$router.push({name: 'advisor'})"
                    >
                        Cancelar
                    </CancelButton>
                    <CustomButton
                    :type="'submit'"
                    :disabled="!isFormValid"
                    :loading="loading || loadingSubmit"
                    @click="onSubmit"
                    > 
                        {{ 'Salvar' }}
                    </CustomButton>
                </div>
            </template>

            </DefaultPageComponent>
            </b-form>
        </div>
        <card-modal :title="type=='post' ? 'Adicionar parceiro comercial' : 'Editar parceiro comercial'" ref="modalCommercialPartnerForm">
            <template #header>
                <div v-if="type=='put'" class="px-2">
                    <GlassButton
                    :loading="loading"
                    :disabled="false"
                    @click="addAdvisorFinalToModal"
                    :width="'max-content'"
                    >   Adicionar assessor final    
                    </GlassButton>
                </div>
            </template>
            <div v-if="renderModal" class="row d-flex justify-content-center align-items-center" style="color: #000;">
                <div class="col mt-2">
                    <b-form @submit.stop.prevent="onSubmitCommercialPartnerForm">
                        <div class="row d-flex justify-content-left mx-0">
                            <!-- <div class="col-md-2"> -->
                            <div class="col-md-3 px-2">
                                <b-form-group id="inputGroupCode" label="Código" label-for="inputGroupCode">
                                    <b-form-input
                                        @change="validadeModalForm()"
                                        id="inputCommercialPartnerCode"
                                        name="inputCommercialPartnerCode"
                                        v-model="commercialPartner.code"
                                        style="min-height:56px;"
                                        v-validate="{ required: true }"
                                        :state="validateState('inputCommercialPartnerCode')"
                                        aria-describedby="inputCommercialPartnerCodeFeedback"
                                        data-vv-as="Código"
                                        :disabled="loading || type=='put'"
                                    ></b-form-input>

                                    <b-form-invalid-feedback id="inputCommercialPartnerCodeFeedback">{{ veeErrors.first('inputCommercialPartnerCode') }}</b-form-invalid-feedback>
                                </b-form-group>
                            </div>
                            <!-- <div class="col-md-3 px-2">
                                <b-form-group id="inputGroupOpenedDate" label="Data de abertura" label-for="inputGroupOpenedDate">
                                    <b-form-input
                                        id="inputOpenedDate"
                                        name="inputOpenedDate"
                                        type="date"
                                        v-model="commercialPartner.openedDate"
                                        style="min-height:56px;"
                                        v-validate="{ required: true }"
                                        class="form-control"
                                        :state="validateState('inputOpenedDate')"
                                        aria-describedby="inputOpenedDateFeedback"
                                        data-vv-as="Unidade"
                                        :disabled="loading"
                                    >
                                    </b-form-input>

                                    <b-form-invalid-feedback id="inputOpenedDateFeedback">{{ veeErrors.first('inputOpenedDate') }}</b-form-invalid-feedback>
                                </b-form-group>
                            </div>
                            <div class="col-md-3 px-2">
                                <b-form-group id="inputGroupActiveDate" label="Data de ativação" label-for="inputGroupActiveDate">
                                    <b-form-input
                                        id="inputActiveDate"
                                        name="inputActiveDate"
                                        type="date"
                                        v-model="commercialPartner.activeDate"
                                        style="min-height:56px;"
                                        v-validate="{ required: true }"
                                        class="form-control"
                                        :state="validateState('inputActiveDate')"
                                        aria-describedby="inputActiveDateFeedback"
                                        data-vv-as="Unidade"
                                        :disabled="loading"
                                    >
                                    </b-form-input>

                                    <b-form-invalid-feedback id="inputActiveDateFeedback">{{ veeErrors.first('inputActiveDate') }}</b-form-invalid-feedback>
                                </b-form-group>
                            </div> -->
                            <!-- <div class="col-md-4 px-2"> -->
                            <div class="col-md-9 px-2">
                                <b-form-group id="inputGroupCommercialPartnerId" label="Nome" label-for="inputGroupCommercialPartnerId">
                                    <b-form-select
                                        @change="validadeModalForm()"
                                        id="inputCommercialPartnerId"
                                        v-model="commercialPartner.id"
                                        name="inputCommercialPartnerId"
                                        style="min-height:56px;"
                                        v-validate="{ required: true }"
                                        class="form-control"
                                        :state="validateState('inputCommercialPartnerId')"
                                        aria-describedby="inputCommercialPartnerIdFeedback"
                                        data-vv-as="Unidade"
                                        :disabled="loading || type=='put'"
                                    >
                                        <b-form-select-option v-for="item in commercialPartners" :value="item.id" :key="item.id">{{ item.trade_name }}</b-form-select-option>
                                    </b-form-select>

                                    <b-form-invalid-feedback id="inputCommercialPartnerIdFeedback">{{ veeErrors.first('inputCommercialPartnerId') }}</b-form-invalid-feedback>
                                </b-form-group>
                            </div>
                        </div>
                        <div v-if="type=='put'" style="max-height: 50vh; overflow-y: auto;">
                            <div class="row d-flex justify-content-left mx-0 mt-3" v-for="item,idx in commercialPartner.hunter_relationship_farmer" :key="idx">
                                <div class="col px-2">
                                    <b-form-group id="inputGroupAdvisorBaseId" label="Assessor Base" label-for="inputGroupAdvisorBaseId">
                                        <b-form-input
                                            id="inputAdvisorBaseId"
                                            name="inputAdvisorBaseId"
                                            v-model="item.hunter_master_name"
                                            style="min-height:56px;"
                                            class="form-control"
                                            aria-describedby="inputAdvisorBaseIdFeedback"
                                            data-vv-as="Assessor Base"
                                            disabled
                                        />

                                        <b-form-invalid-feedback id="inputAdvisorBaseIdFeedback">{{ 'Campo obrigatório.' }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </div>
                                <div class="col px-2">
                                    <b-form-group id="inputGroupAdvisorFinderId" label="Assessor Finder" label-for="inputGroupAdvisorFinderId">
                                        <b-form-input
                                            id="inputAdvisorFinderId"
                                            name="inputAdvisorFinderId"
                                            v-model="item.hunter_name"
                                            style="min-height:56px;"
                                            class="form-control"
                                            aria-describedby="inputAdvisorFinderIdFeedback"
                                            data-vv-as="Assessor Finder"
                                            disabled
                                        />

                                        <b-form-invalid-feedback id="inputAdvisorFinderIdFeedback">{{ 'Campo obrigatório.' }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </div>
                                <div class="col px-2">
                                    <b-form-group id="inputGroupAdvisorFinalId" label="Assessor Final" label-for="inputGroupAdvisorFinalId">
                                        <b-form-select
                                            @change="validadeModalForm()"
                                            id="inputAdvisorFinalId"
                                            name="inputAdvisorFinalId"
                                            v-model="item.farmer_id"
                                            style="min-height:56px;"
                                            class="form-control"
                                            aria-describedby="inputAdvisorFinalIdFeedback"
                                            data-vv-as="Assessor Final"
                                            :disabled="advisor.advisorCommercialPartner.length==0"
                                        >
                                            <b-form-select-option value=""> Selecione </b-form-select-option>
                                            <b-form-select-option v-for="adv in advisors.filter(a=>a.id==item.farmer_id || !commercialPartner.hunter_relationship_farmer.find(h=>h.farmer_id==a.id))" :value="adv.id" :key="adv.id">{{ adv.first_name+' '+adv.last_name }}</b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                </div>
                                <div class="col-1 px-2 d-flex justify-content-center align-items-center">
                                    <b-button v-if="idx!=0"
                                        @change="validadeModalForm()"
                                        size="sm"
                                        @click="removeCommercialPartnerByModal(idx)" class="p-2"
                                        style="color: #EB694A; border:1px solid #474646; border-radius:4px; box-shadow: none; background-color: transparent; font-size:16px;"
                                    >
                                        <b-icon icon="trash-fill"></b-icon>
                                    </b-button>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex align-items-center justify-content-end mx-2 mt-3">
                            <CancelButton
                            @click="$refs.modalCommercialPartnerForm.closeModal()"
                            >
                                Cancelar
                            </CancelButton>
                            <CustomButton
                            @click="onSubmitCommercialPartnerForm"
                            :disabled="!isModalFormValid"
                            :loading="loading || loadingSubmit"
                            >   {{ type=='post' ? 'Adicionar' : 'Salvar' }}
                            </CustomButton>
                        </div>
                    </b-form>
                </div>
            </div>
        </card-modal>

        <ImageModal ref="imageModal" :imageProp="image" @change="getImage"/>
    </div>
</template>

<script>
import CardModal from './CardModal.vue';
// import DefaultHeader from '@/components/DefaultHeader.vue';
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
// import GlassButton from '@/components/GlassButton.vue';
import CustomButton from '@/components/CustomButton.vue';
import CancelButton from '@/components/CancelButton.vue';
import GlassButton from '@/components/GlassButton.vue';
import ImageModal from '@/components/ImageModal.vue';

export default {
    name: 'AdvisorFormView',
    components: {
        DefaultPageComponent,
        ImageModal,
        // DefaultHeader,
        'card-modal': CardModal,
        // GlassButton,
        CustomButton,
        CancelButton,
        GlassButton,
    },
    async mounted() {
        await this.getCompanies();
        await this.getCells();
        await this.getCommercialPartners();
        await this.getAdvisors();
        await this.getAdvisor();
    },
    created() {
        this.$validator.localize('en', {
            custom: {
                inputFirstName: {
                    required: 'Campo obrigatório.',
                },
                inputLastName: {
                    required: 'Campo obrigatório.',
                },
                inputEmail: {
                    required: 'Campo obrigatório.',
                    email: 'E-mail inválido.',
                },
                inputNDocument: {
                    required: 'Campo obrigatório.',
                    cpf: 'CPF inválido.',
                },
                inputCommercialPartnerId: {
                    required: 'Campo obrigatório.',
                },
                inputCommercialPartnerCode: {
                    required: 'Campo obrigatório.',
                },
            },
        });
    },
    data() {
        return {
            loading: false,
            formIsValid: false,
            isModalFormValid: false,
            companies: [],
            cells: [],
            advisors: [],
            commercialPartners: [],
            advisor: {
                wflow_code: '',
                first_name: '',
                last_name: '',
                email: '',
                n_document: '',
                company_id: '',
                cell_id: '',
                title: '',
                hub_code: '',
            },
            loadingSubmit: false,
            columns: [
                {
                    'code': 
                    {
                        label: 'Código',
                        class: 'text-right'
                    }
                },
                {
                    'trade_name': 
                    {
                        label: 'Parceiro comercial',
                        class: 'text-left'
                    }
                },
                {
                    'hunter_name': 
                    {
                        label: 'Finder',
                        class: 'text-left'
                    }
                },
                {
                    'farmer_name': 
                    {
                        label: 'Final',
                        class: 'text-left'
                    }
                },
                // {
                //     'openedDate': 
                //     {
                //         label: 'Data de abertura',
                //         class: 'text-left',
                //         formatter: (value)=>{
                //             if(!value) return '---';
                //             return value.substring(8,10)+'/'+value.substring(5,7)+'/'+value.substring(0,4)
                //         }
                //     }
                // },
                // {
                //     'activeDate': 
                //     {
                //         label: 'Data de ativação',
                //         class: 'text-left',
                //         formatter: (value)=>{
                //             if(!value) return '---';
                //             return value.substring(8,10)+'/'+value.substring(5,7)+'/'+value.substring(0,4)
                //         }
                //     }
                // },
                {
                    'actions': 
                    {
                        label: '',
                        class: 'text-right',
                    }
                },
            ],
            commercialPartner: {
                code: '',
                id: '',
                name: '',
            },
            commercialPartnersInMemory: [],
            type: 'post',
            renderModal: true,
            image: null,
        }
    },
    computed: {
        isFormValid(){
            return (
                this.advisor.first_name
                && this.advisor.last_name
                && this.advisor.n_document
                && this.advisor.email
                // && this.commercialPartnersInMemory.length>0
            )
        },
    },
    methods: {
        getImage(img){
            this.image = img;
        },
        openImageModal(){
            this.$refs.imageModal.show();
        },
        validadeModalForm(){
            this.isModalFormValid = !!(
                this.commercialPartner.code
                && this.commercialPartner.id
                && this.commercialPartner.hunter_relationship_farmer.every(item=>{
                    return (
                        item.hunter_master_id
                        && item.hunter_id
                        && item.farmer_id
                    );
                })
            )
        },
        async getCompanies() {
            this.loading = true;
            this.$axios.get('companies').then((response) => {
                this.companies = response.data.data;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar às unidades.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            })
            .finally(()=>{
                this.loading=false;
            })
        },
        async getCells() {
            this.loading = true;
            this.$axios.get('cells').then((response) => {
                this.cells = response.data.data;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar às células.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            })
            .finally(()=>{
                this.loading=false;
            })
        },
        async getAdvisors() {
            this.loading = true;
            this.$axios.get('advisors').then((response) => {
                this.advisors = response.data.data;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar os assessores.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            })
            .finally(()=>{
                this.loading=false;
            })
        },
        async getCommercialPartners() {
            this.loading = true;
            await this.$axios.get('commercial-partner').then((response) => {
                this.commercialPartners = response.data.data;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar os parceiro comercial.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            })
            .finally(()=>{
                this.loading=false;
            })
        },
        getListTable(){
            var temp = [];
            if(this.commercialPartnersInMemory.length==0)
                this.advisor.advisorCommercialPartner.forEach(item => {
                    temp.push(...item.hunter_relationship_farmer.map(subitem => {
                        return {
                            subId: subitem.id,
                            code: item.code,
                            id: item.commercialPartner.id, 
                            trade_name: item.commercialPartner.trade_name,
                            hunter_master_id: subitem.hunter_master_id,
                            hunter_id: subitem.hunter_id,
                            farmer_id: subitem.farmer_id,
                            hunter_master_name: subitem.hunter_master_name,
                            hunter_name: subitem.hunter_name,
                            farmer_name: subitem.farmer_name,
                        }
                    }));
                });
            this.commercialPartnersInMemory = this.removeDuplicates([...this.commercialPartnersInMemory, ...temp]);
        },
        getRequestList(){
            var temp = [];
            this.getListTable();
            this.commercialPartnersInMemory.forEach(item=>{
                temp.push(item)
            });
            var commercialPartners = [];
            temp.forEach(tempItem=>{
                var adv = this.advisor.advisorCommercialPartner.find(item=> item.code==tempItem.code && item.commercial_partner_id==tempItem.id)
                var obj = {id: adv?.id || '', commercial_partner_id: tempItem.id, code: tempItem.code, hunter_relationship_farmer: []};
                if(!commercialPartners.find(x=>obj.id==x.id && obj.code==x.code))
                    commercialPartners.push(obj);
            })
            commercialPartners.forEach(item=>{
                item.hunter_relationship_farmer = temp.filter(x=>item.commercial_partner_id==x.id && item.code==x.code).map(e=>{
                    return {
                        id: e.subId || '',
                        hunter_master_id: e.hunter_master_id,
                        hunter_id: e.hunter_id,
                        farmer_id: e.farmer_id,
                    }
                });
            })
            return [...commercialPartners];
        },
        async getAdvisor() {
            this.loading = true;
            this.$axios.get(`advisors/${this.$router.currentRoute.params.id}`).then((response) => {
                this.advisor = response.data.data;
                if (this.advisor.emails.length > 0)
                    this.advisor.email = response.data.data.emails[0].email;
                if (this.advisor.cellHistories.length > 0) {
                    this.advisor.cell_id = this.advisor.cellHistories[this.advisor.cellHistories.length - 1].cell.id;                   
                }
                this.image = this.advisor.image;
                this.getListTable();
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar o assessor.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
            })
            .finally(()=>{
                this.loading=false;
            })
        },
        validateState(ref) {
            if (
                this.veeFields[ref] &&
                (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        async validateForm() {
            let response = await this.$validator.validateAll(
                [
                    'inputFirstName', 
                    'inputLastName', 
                    'inputEmail', 
                    'inputNDocument'
                ]
            );
            
            if (response)
                this.formIsValid = true;
            else
                this.formIsValid = false;
        },
        showModalCommercialPartnerForm(item) {
            if (this.commercialPartners.length == 0) {
                this.$swal({
                    title: 'Informação',
                    text: 'Sem parceiros disponíveis para selecionar, todos já estão selecionados.',
                    icon: 'info',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            }
            if(item){
                this.type = 'put';
                this.commercialPartner = {};
                this.commercialPartner.code = item.code;
                this.commercialPartner.id = item.id;
                this.commercialPartner.trade_name = item.trade_name;
                var temp = [];
                if(this.advisor.advisorCommercialPartner.length==0)
                    this.commercialPartner.hunter_relationship_farmer = [
                        {
                            id: '',
                            subId: '',
                            hunter_master_id: this.advisor.id,
                            hunter_master_name: this.advisor.first_name+' '+this.advisor.last_name,
                            hunter_id: this.advisor.id,
                            hunter_name: this.advisor.first_name+' '+this.advisor.last_name,
                            farmer_id: this.advisor.id,
                            farmer_name: this.advisor.first_name+' '+this.advisor.last_name,
                        }
                    ];
                else{
                    var list = this.commercialPartnersInMemory.filter(e=>e.id==item.id && e.code==item.code).map(e=>{
                        e.idx = this.commercialPartnersInMemory.findIndex(x=>x==e);
                        return e;
                    });
                    temp.push(...list);
                    this.commercialPartner.hunter_relationship_farmer = this.removeDuplicates([...temp]);
                }
            }
            else{
                this.type = 'post';
                this.commercialPartner = {};
                this.commercialPartner.code = "";
                this.commercialPartner.id = "";
                this.commercialPartner.trade_name = "";
                this.commercialPartner.hunter_relationship_farmer = [
                    {
                        id: '',
                        subId: '',
                        hunter_master_id: this.advisor.id,
                        hunter_master_name: this.advisor.first_name+' '+this.advisor.last_name,
                        hunter_id: this.advisor.id,
                        hunter_name: this.advisor.first_name+' '+this.advisor.last_name,
                        farmer_id: this.advisor.id,
                        farmer_name: this.advisor.first_name+' '+this.advisor.last_name,
                    }
                ];
                // this.commercialPartner.openedDate = "";
                // this.commercialPartner.activeDate = "";
            }
            this.validadeModalForm();
            this.$refs.modalCommercialPartnerForm.openModal();
        },
        onSubmit() {
            this.$validator.validateAll().then(result => {
                if (!result) {
                    return;
                }

                // this.loadingSubmit = true;

                let objAdvisor = this.advisor;
                objAdvisor.n_document = objAdvisor.n_document.replace(/\D/g, '');

                if(this.commercialPartnersInMemory.length > 0)
                    objAdvisor.commercial_partners = this.getRequestList();

                if(this.advisor.company_id==0)
                    delete objAdvisor.company_id;
                
                this.$axios.put(`advisors/${this.$router.currentRoute.params.id}`, objAdvisor).then(async () => {

                    var file = this.dataURLtoFile(this.image, 'advisor_'+this.advisor.id)

                    const formData = new FormData();
                    formData.append('advisor_id', this.advisor.id);
                    formData.append('image', file);
                    await this.$axios.post('advisors/image', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })

                    this.$swal({
                        title: 'Sucesso',
                        text: 'Assessor salvo com sucesso.',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#28A745',
                        allowOutsideClick: false,
                        focusConfirm: false,
                        customClass: {
                            confirmButton: 'custom-confirm-button-class',
                        }
                    });
                    this.loadingSubmit = false;
                    this.$router.push({ name: 'advisor' });
                }).catch(() => {
                    this.$swal({
                        title: 'Erro',
                        text: 'Não foi possível salvar o assessor.',
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#C44C30',
                        allowOutsideClick: false,
                        customClass: {
                            confirmButton: 'custom-confirm-button-class',
                        }
                    });
                    this.loadingSubmit = false;
                });
            });
        },
        dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[arr.length - 1]), 
                n = bstr.length, 
                u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type:mime});
        },

        onSubmitCommercialPartnerForm() {
            if(this.type=="put"){
                if(this.advisor.advisorCommercialPartner.length>0 && !(this.commercialPartner.code && this.commercialPartner.id && this.commercialPartner.hunter_relationship_farmer.every(e=>e.farmer_id))){
                    this.$swal({
                        title: 'Atenção',
                        text: 'Para salvar, preencha o campo CÓDIGO, selecione o PARCEIRO COMERCIAL e ASSESSOR FINAL.',
                        icon: 'warning',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#C44C30',
                        allowOutsideClick: false,
                        customClass: {
                            confirmButton: 'custom-confirm-button-class',
                        }
                    });
                    return;
                }
                else if(this.advisor.advisorCommercialPartner.length==0 && !(this.commercialPartner.code && this.commercialPartner.id)) {
                    this.$swal({
                        title: 'Atenção',
                        text: 'Para salvar, preencha o campo CÓDIGO e selecione o PARCEIRO COMERCIAL.',
                        icon: 'warning',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#C44C30',
                        allowOutsideClick: false,
                        customClass: {
                            confirmButton: 'custom-confirm-button-class',
                        }
                    });
                    return;
                }
            }
            else if(this.type=='post'){
                if(!this.commercialPartner.id || !this.commercialPartner.code){
                    this.$swal({
                        title: 'Atenção',
                        text: 'Para adicionar, preencha o campo CÓDIGO e selecione o PARCEIRO COMERCIAL.',
                        icon: 'warning',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#C44C30',
                        allowOutsideClick: false,
                        customClass: {
                            confirmButton: 'custom-confirm-button-class',
                        }
                    });
                    return;
                }
            }

            if(!this.isModalFormValid) return;

            var inputs = [];
            if(this.type=='post')
                inputs = ['inputCommercialPartnerCode', 'inputCommercialPartnerId']
            else
                inputs = ['inputCommercialPartnerCode', 'inputCommercialPartnerId', 'inputAdvisorFinalId']
            
            this.$validator.validateAll(inputs).then(result => {
                    if (!result) {
                        return;
                }
                
                let idx = this.commercialPartners.findIndex(obj => obj.id === this.commercialPartner.id);
                this.commercialPartner.trade_name = this.commercialPartners[idx].trade_name;
                

                if(this.type=='post' && this.commercialPartnersInMemory.find(e=>e.id==this.commercialPartner.id && e.code==this.commercialPartner.code)){
                    this.$swal({
                        title: 'Atenção',
                        html: '<div> O code: <b>'+this.commercialPartner.code+'</b> com o Parceiro comercial: <b>'+this.commercialPartner.trade_name+'</b> já existe no cadastro.</div>',
                        icon: 'warning',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#C44C30',
                        allowOutsideClick: false,
                        customClass: {
                            confirmButton: 'custom-confirm-button-class',
                        }
                    });
                    return;
                }

                if(this.type=='post'){
                    this.commercialPartnersInMemory.push(...this.commercialPartner.hunter_relationship_farmer.map(subitem => {
                        var adv = this.advisors.find(e=>e.id==subitem.farmer_id)
                        return {
                            subId: subitem.id,
                            code: this.commercialPartner.code,
                            id: this.commercialPartner.id, 
                            advisor_commercial_partner_id: this.commercialPartner.id, 
                            trade_name: this.commercialPartner.trade_name,
                            hunter_master_id: subitem.hunter_master_id,
                            hunter_id: subitem.hunter_id,
                            farmer_id: subitem.farmer_id,
                            hunter_master_name: subitem.hunter_master_name,
                            hunter_name: subitem.hunter_name,
                            farmer_name: adv.first_name+' '+adv.last_name,
                        }
                    }));
                }
                else if(this.type=='put'){

                    var list = [];
                    this.commercialPartner.hunter_relationship_farmer.forEach(subitem=>{
                        // var idx = this.commercialPartnersInMemory.findIndex(e=>
                        //         e.id==this.commercialPartner.id
                        //         && e.code==this.commercialPartner.code
                        //         && e.subId==subitem.subId
                        // )
                        var adv = this.advisors.find(e=>e.id==subitem.farmer_id)
                        if(subitem.idx>=0){
                            this.commercialPartnersInMemory[subitem.idx].hunter_master_id = subitem.hunter_master_id;
                            this.commercialPartnersInMemory[subitem.idx].hunter_id = subitem.hunter_id;
                            this.commercialPartnersInMemory[subitem.idx].farmer_id = subitem.farmer_id;
                            this.commercialPartnersInMemory[subitem.idx].hunter_master_name = subitem.hunter_master_name;
                            this.commercialPartnersInMemory[subitem.idx].hunter_name = subitem.hunter_name;
                            this.commercialPartnersInMemory[subitem.idx].farmer_name = adv.first_name+' '+adv.last_name;
                        }
                        else{ 
                            list.push({
                                subId: subitem.id,
                                code: this.commercialPartner.code,
                                id: this.commercialPartner.id,
                                advisor_commercial_partner_id: this.commercialPartner.id,
                                trade_name: this.commercialPartner.trade_name,
                                hunter_master_id: subitem.hunter_master_id,
                                hunter_id: subitem.hunter_id,
                                farmer_id: subitem.farmer_id,
                                hunter_master_name: subitem.hunter_master_name,
                                hunter_name: subitem.hunter_name,
                                farmer_name: adv.first_name+' '+adv.last_name,
                            })
                        }
                    })

                    this.commercialPartnersInMemory.push(...list)
                }
                
                this.getListTable();
                this.$refs.modalCommercialPartnerForm.closeModal();
            })
        },
        async removeCommercialPartner(idx) {
            this.$swal({
                title: 'Excluir parceiro comercial ?',
                text: `Deseja realmente excluir do cadastro do assessor o código ${this.commercialPartnersInMemory[idx].code} do parceiro comercial ${this.commercialPartnersInMemory[idx].name} ?`,
                // text: `Deseja realmente excluir o parceiro comercial ${this.commercialPartnersInMemory[idx].name}?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Cancelar',
                confirmButtonColor: '#C7300C',
                cancelButtonText: 'Excluir',
                cancelButtonColor: '#28A745',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'custom-confirm-button-class',
                    cancelButton: 'custom-confirm-button-class',
                }
            }).then(async (result) => {
                if (!result.isConfirmed) {
                    var item = this.commercialPartnersInMemory[idx]
                    var idxAdv = this.advisor.advisorCommercialPartner.findIndex(e=>e.commercial_partner_id==item.id && e.code==item.code);
                    var idxItem = this.advisor.advisorCommercialPartner[idxAdv].hunter_relationship_farmer
                        .findIndex(e=>
                            e.hunter_master_id==item.hunter_master_id
                            && e.hunter_id==item.hunter_id
                            && e.farmer_id==item.farmer_id
                        );
                    this.advisor.advisorCommercialPartner[idxAdv].hunter_relationship_farmer.splice(idxItem, 1);
                    this.commercialPartnersInMemory.splice(idx, 1);
                    this.commercialPartners = [];
                    await this.getCommercialPartners();
                    for (let i = 0; i < this.commercialPartnersInMemory.length; i++) {
                        let idx = this.commercialPartners.findIndex(obj => obj.id === this.commercialPartnersInMemory[i].id);

                        if (idx > -1) {
                            this.commercialPartners.splice(idx, 1);
                        }
                    }
                }
            });
        },
        async removeCommercialPartnerByModal(idx) {
            var item = this.commercialPartner.hunter_relationship_farmer[idx]
            var adv = this.advisors.find(e=>e.id==item.farmer_id);
            var name = adv ? adv.first_name+' '+adv.last_name : 'selecionado';
            this.$swal({
                title: 'Excluir assessor final?',
                text: `Deseja realmente excluir o cadastro do assessor final ${name}?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Cancelar',
                confirmButtonColor: '#C7300C',
                cancelButtonText: 'Excluir',
                cancelButtonColor: '#28A745',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'custom-confirm-button-class',
                    cancelButton: 'custom-confirm-button-class',
                }
            }).then(async (result) => {
                if (!result.isConfirmed) {
                    var idxList = this.commercialPartnersInMemory.findIndex(c=>c.subId==item.subId)
                    this.commercialPartner.hunter_relationship_farmer.splice(idx,1)
                    this.commercialPartnersInMemory.splice(idxList,1)
                    this.validadeModalForm();
                    this.forceRenderModal()
                }
            });
        },
        addAdvisorFinalToModal(){
            this.commercialPartner.hunter_relationship_farmer.push(
                {
                    subId: '',
                    code: this.commercialPartner.code,
                    id: this.commercialPartner.id,
                    advisor_commercial_partner_id: this.commercialPartner.id,
                    trade_name: this.commercialPartner.trade_name,
                    hunter_master_id: this.advisor.id,
                    hunter_master_name: this.advisor.first_name+' '+this.advisor.last_name,
                    hunter_id: this.advisor.id,
                    hunter_name: this.advisor.first_name+' '+this.advisor.last_name,
                    farmer_id: '',
                    farmer_name: '',
                    // farmer_id: this.advisor.id,
                    // farmer_name: this.advisor.first_name+' '+this.advisor.last_name,
                }
            );
            this.validadeModalForm();
            this.forceRenderModal();
        },
        removeDuplicates(arr){
            const seen = new Set();
            return arr.filter(item => {
                const json = JSON.stringify(item);
                if (seen.has(json)) {
                    return false;
                } else {
                    seen.add(json);
                    return true;
                }
            });
        },
        async forceRenderModal(){
            this.renderModal = false;
            await this.$nextTick();
            this.renderModal = true;
        },
    },
    watch: {
        'advisor.first_name': 'validateForm',
        'advisor.last_name': 'validateForm',
        'advisor.email': 'validateForm',
        'advisor.n_document': 'validateForm',
        'advisor.company_id': 'validateForm',
        'advisor.cell_id': 'validateForm',
        'advisor.title': 'validateForm',
    }
}
</script>
