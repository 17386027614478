<template>
        <div>
        <form class="p-4 mt-4 d-flex flex-column justify-content-between align-items-center" 
        style="background: #000; border-radius: 8px; border-width: 4px; border-style: solid; border-color: #AC5037; width: 370px;">
            <div class="text-center">
                <img src="@/assets/logo.svg" alt="Logo WFLOW" height="48">
            </div>
            <b-row class="m-0 mt-3 text-center" style="color:white;">
                Informe abaixo o e-mail utilizado para login e receba o link para redefinição da senha
            </b-row>
            <div class="form-label-group w-100 mt-4">
                <input id="inputEmail" ref="inputEmail" type="email" class="form-control" v-model="credentials.email" :disabled="loading">
                <label for="inputEmail">E-mail</label>
                <b-row class="m-0 w-100" style="color:red; height:30px; font-size:14px;" align-h="start">
                    {{ errorMessage }}
                </b-row>
            </div>
            <CustomButton
            :loading="loading"
            @click="handleSubmit"
            :disabled="!isValid"
            :readonly="!isValid"
            > Redefinir senha
            </CustomButton>
        </form>
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
export default {
    name: "RecoverView",
    components: {
        CustomButton,
    },
    data(){
        return {
            errorMessage: null,
            loading: false,
            credentials: {
                email: null,
            },
        }
    },
    computed: {
        isValid(){
            return this.credentials.email;
        },
    },
    methods: {
        async handleSubmit(){
            try{
                this.loading=true;
                await this.$axios.post('forgot-password', { email: this.credentials.email })
                this.$swal({
                    title: 'Sucesso',
                    text: 'Enviamos seu link de redefinição de senha por e-mail!',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                })
                .then(()=>{
                    this.$router.push({name: 'login'})
                })
            }
            catch(error){
                this.errorMessage = "Não encontramos um usuário com esse e-mail.";
            }
            finally{ this.loading=false; }

        },
    },
}
</script>

<style scoped>

</style>