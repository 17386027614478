<template>
    <card-modal title="Notificação" ref="modalCommercialPartnerForm" >
        <b-col style="max-width: 80vw;">
            <b-row class="px-2" style="white-space: pre-wrap;"> {{ notification.title }} </b-row>
            <b-row class="px-2 py-3" style="white-space: pre-wrap;"> {{ notification.message }} </b-row>
            <b-row class="px-2 py-2" align-h="between">
                <div>
                    <CustomButton v-if="notification.template_id"
                    borderColor="#00FF00"
                    textColor="#00FF00"
                    @click="download"
                    :loading="loadingDownload"
                    disabled
                    >
                        Baixar planilha
                    </CustomButton>
                </div>

                <CustomButton
                @click="hide"
                >
                    Fechar
                </CustomButton>
            </b-row>
        </b-col>
    </card-modal>
</template>

<script>
import CardModal from '@/views/CardModal.vue';
import CustomButton from './CustomButton.vue';

export default {
    name: 'NotificationModal',
    components:{
        'card-modal': CardModal,
        CustomButton,
    },
    props: {
        notification: Object,
    },
    data(){
        return {
            loadingDownload: false,
        }
    },
    methods: {
        show(){
            this.$refs.modalCommercialPartnerForm.openModal();
        },
        hide(){
            this.$refs.modalCommercialPartnerForm.closeModal();
        },
        async download () {
            this.loadingDownload = true;
            const response = await this.$axios.get('customers/export-unregistered');
            var file_url = response.data.file_url

            this.$axios({
                url: file_url,
                method: 'GET',
                responseType: 'blob',
            })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file_url.split('/').pop());
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.error('Error downloading file:', error);
                this.$swal({
                    title: 'Erro',
                    text: `Não foi possível realizar o download da planilha.`,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C7300C',
                })
            })
            .finally(()=>{
                this.loadingDownload=false;
            })
        },
    }
}
</script>

<style>

</style>