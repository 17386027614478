<template>
    <div class="form-group">
        <label :for="inputId || 'inputPeriod'" style="z-index:2;">{{ label }}</label>
        <b-form-datepicker
        @input="input"
        v-model="model"
        :id="inputId || 'inputPeriod'"
        :ref="inputId || 'inputPeriod'"
        type="date"
        :placeholder="placeholder"
        class="form-control form-control-sm" 
        style="box-shadow: none; height: 56px; color-scheme: dark !important;"
        :value="value"
        :date-disabled-fn="disabledDateFn"
        :min="minDate"
        :max="maxDate"
        :disabled="disabled"
        :initial-date="initialDate"
        :reset-button="resetButton ? true : false"
        :label-reset-button="resetButton"
        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        locale="pt-BR"
        ></b-form-datepicker>
    </div>
</template>

<script>
export default {
    name: 'InputDatePicker',
    data(){
        return {
            model: null,
        }
    },
    async mounted(){
        const idInterval = setInterval(()=>{
            if(this.value) this.model = this.value;
        }, 250)
        setTimeout(()=>{
            clearInterval(idInterval);
        }, 5000)
    },
    created(){
        setInterval(()=>{
            const element = document.querySelector('.b-calendar-grid-help');
            if(element){
                element.setAttribute('style', 'color:#EB694A !important;');
                element.textContent = 'Use as setas para navegar no calendário.';
            }

            // indisponível = .bg-light com span .disabled
            // incompleto = div com data-date="xx-xx-xxxx" span
            // completo = div com data-date="xx-xx-xxxx" span


        }, 0)
    },
    methods:{
        input(){
            this.$emit('input', this.model)
        },
    },
    props:{
        inputId: {
            type: String,
            default: 'inputPeriod',
        },
        label: {
            type: String,
            default: 'Período',
        },
        placeholder: {
            type: String,
            default: 'Selecione',
        },
        value: {
            type: String,
            default: null,
        },
        initialDate: {
            type: String,
            default: null,
        },
        minDate: {
            type: String,
            default: '',
        },
        maxDate: {
            type: String,
            default: '',
        },
        disabledDateFn: {
            type: Function,
            default: ()=>{},
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        resetButton: {
            type: String,
            default: null,
        },
    }
}
</script>

<style scoped>
* /deep/ .dropdown-menu{
    background-color:black !important;
    border-radius: 20px;
}
* /deep/ .text-dark,
* /deep/ .text-muted{
    color: white !important;
}
* /deep/ .bg-light{
    background-color:#262625 !important;
    color: rgb(2, 239, 22) !important;
}
* /deep/ .bg-light .disabled{
    color: white !important;
}
* /deep/ .text-dark:hover{
    color:#262625 !important;
}
* /deep/ .dropdown label{
    position: absolute !important;
    top: 0px !important;
    left: 0px !important;
    padding-top: 15px !important;
    padding-bottom: 0px !important;
    height: 100% !important;
    border: none !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    display: block !important;
    text-overflow: ellipsis !important;
}

* /deep/ .btn-outline-secondary{
    background-color:#FFFFFF20 !important;
    color:#EB694A !important;
}
* /deep/ .btn-outline-secondary:hover{
    background-color: #FFFFFF50 !important;
}

* /deep/ .b-calendar-grid{
    padding:0px !important;
}

* /deep/ .custom-text-color,
* /deep/ .b-calendar-grid-caption
/* * /deep/ .font-weight-bold */
/* * /deep/ .btn-outline-light */
{
    color: #EB694A !important;
}

</style>