<template>
    <div v-if="renderComponent" id="customer" style="width:100%; height:100%;">
        <DefaultPageComponent
        :loading="loading"
        :title="'Importação de cliente'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'dashboard'})"
        >

        <!-- CONTENT -->
        <div class="py-4">
            <ImportationComponent  class="my-0 mx-0"
            :title="'Clientes XP'"
            :inputRef="'inputFileXPCustomer'"
            :disabled="isXPCustomerDisabled || loading"
            @change="getFileXPCustomer"
            :showDelete="true"
            ></ImportationComponent>
        </div>

        <template #footer>
            <b-row align-h="end" class="mx-0">
                <CustomButton
                :disabled="isDisabled"
                :loading="loading"
                @click="save"
                > Importar
                </CustomButton>
            </b-row>
        </template>

        </DefaultPageComponent>
    </div>  
</template>

<script>
import ImportationComponent from '@/components/ImportationComponent.vue';
import CustomButton from '@/components/CustomButton.vue';
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
export default {
    name: 'ImportCockpitView',
    components: {
        DefaultPageComponent,
        ImportationComponent,
        CustomButton,
    },
    data () {
        return {
            renderComponent: true,
            loading: false,
            isDisabled: true,
            today: null,
            fileXPCustomer: null,
            items: [],
        }
    },
    computed:{
        isXPCustomerValid(){
            return !!this.fileXPCustomer;
        },
    },
    methods: {
        async forceRender() {
            this.renderComponent = false;
            await this.$nextTick();
            this.renderComponent = true;
        },
        getFileXPCustomer(value){
            this.fileXPCustomer = value;
            this.verifyForm();
        },
        formatDateBR(dateString) {
            if (!dateString) {
                return '';
            }

            const [year, month, day] = dateString.split('-');
            const formattedDate = `${day}/${month}/${year}`;

            return formattedDate;
        },
        clear(){
            this.fileXPCustomer = null;
            this.forceRender();
        },
        async save(){
            if(this.isDisabled){
                this.$swal({
                    title: 'Atenção',
                    text: 'Não há arquivo selecionado para importação.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                });
                return;
            }
            try{
                this.loading=true;
                const XPCustomer = await this.saveXPCustomerFileImport();
                await Promise.all([
                    XPCustomer,
                ]);
                this.$swal({
                    title: 'Sucesso',
                    text: 'Arquivo(s) importado(s) com sucesso.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                    focusConfirm: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                this.clear();
            }
            catch(error){
                console.log(error);
                this.$swal({
                    title: 'Atenção',
                    text: 'Houve um erro durante a importação de um arquivo!',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                });
            }
            finally{ this.loading=false; }  
        },
        async saveXPCustomerFileImport () {
            if(!this.fileXPCustomer) return;
            const formData = new FormData();
            formData.append('sheet', this.fileXPCustomer);
            const response = await this.$axios.post('customers/import', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            return response;
        },
        verifyForm(){
            if(
                this.fileXPCustomer
            ) this.isDisabled = false;
            else this.isDisabled = true;
        },
    }
}
</script>

<style>
    #dashboard {
        height: calc(100vh - 125px);
    }

    .form-control {
        background-color: #262625 !important;
        border: 1px solid #535252 !important;
        color: #E5E2E1 !important;
        border-radius: 10px !important;
    }
</style>
