<template>
    <div id="customer">
        <DefaultPageComponent
        :loading="loading"
        :title="'Clientes'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'dashboard'})"
        :haveNextButton="true"
        :buttonText="'Novo Cliente'"
        @onTap="$router.push('/customers/add')"
        :haveContentMargin="true"
        >
            <template #filters>
                <b-row>
                    <b-col cols="2">
                        <div class="form-group">
                            <label for="">Pesquisa por:</label>
                            <select class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;" v-model="filters.filterBy">
                                <option value="name">Nome</option>
                                <option value="cpf_n_document">CPF</option>
                                <option value="cnpj_n_document">CNPJ</option>
                            </select>
                        </div>
                    </b-col>
                    <b-col cols="5">
                        <div class="form-group">
                            <label for="">Pesquisar:</label>
                            <input type="text" class="form-control form-control-sm" id="inputUserName" placeholder="Digite aqui depois de selecionar" style="box-shadow: none; height: 56px;" v-model="filters.filter" v-if="!filters.filterBy.includes('n_document')">
                            <input type="text" class="form-control form-control-sm" id="inputUserName" placeholder="Digite aqui depois de selecionar" style="box-shadow: none; height: 56px;" v-model="filters.filter" v-mask="'###.###.###-##'" v-else-if="filters.filterBy.includes('cpf')">
                            <input type="text" class="form-control form-control-sm" id="inputUserName" placeholder="Digite aqui depois de selecionar" style="box-shadow: none; height: 56px;" v-model="filters.filter" v-mask="'##.###.###/####-##'" v-else-if="filters.filterBy.includes('cnpj')">
                        </div>
                    </b-col>
                    <b-col cols="3">
                        <div class="form-group">
                            <label for="">Parceiro comercial:</label>
                            <select class="form-control form-control-sm" id="inputUserSatus" style="box-shadow: none; height: 56px;" v-model="filters.customer_commercial_partner_id">
                                <option value="">Selecione o parceiro comercial</option>
                                <option v-for="commercialPartner in commercialPartners" :value="commercialPartner.id" :key="commercialPartner.id">{{ commercialPartner.trade_name }}</option>
                            </select>
                        </div>
                    </b-col>
                    <b-col cols="2">
                        <GlassButton
                        :loading="loading"
                        @click="getCustomers(1)"
                        :width="'100%'"
                        > 
                            <img src="@/assets/search.png" />
                            Pesquisar
                        </GlassButton>
                        <!-- <div class="form-group">
                            <button 
                                style="min-width: 100%; min-height: 56px; font-size: 16px; font-weight: 600; color: #EB694A; background: rgba(235, 105, 74, 0.12); border: none; border-radius: 10px;"
                                @click="getCustomers(1)"
                            >
                                <span v-if="!loading">
                                    <img src="@/assets/search.png" />
                                    Pesquisar
                                </span>
                                <span v-else>
                                    <b-spinner small />
                                </span>
                            </button>
                        </div> -->
                    </b-col>
                </b-row>
            </template>
            
            <!-- CONTENT -->
            <b-table class="custom-table" borderless :fields="columns" :items="customers" sticky-header>
                <template #head(n_document)="data">
                    <b-col class="text-right p-0">
                        {{data.label}}
                    </b-col>
                </template>
                <template #head(first_name)="data">
                    <b-col class="text-left p-0">
                        {{data.label}}
                    </b-col>
                </template>
                <template #head(last_name)="data">
                    <b-col class="text-left p-0">
                        {{data.label}}
                    </b-col>
                </template>
                <template #head(email)="data">
                    <b-col class="text-left p-0">
                        {{data.label}}
                    </b-col>
                </template>
                <template #head(phone)="data">
                    <b-col class="text-left p-0">
                        {{data.label}}
                    </b-col>
                </template>
                <template #head(customerCommercialPartnerCount)="data">
                    <b-col class="text-center p-0">
                        {{data.label}}
                    </b-col>
                </template>
                <template #head(actions)="data">
                    <b-col class="text-center p-0">
                        {{data.label}}
                    </b-col>
                </template>
                <template #cell(n_document)="row">
                    <div class="d-flex justify-content-end align-items-center text-right">
                        {{ formatCPForCNPJ(row.item.n_document) }}
                    </div>
                </template>
                <template #cell(customerCommercialPartnerCount)="row">
                    <div class="d-flex justify-content-center align-items-center">
                        <div style="display: flex; justify-content: center; align-itens: center;" class="text-center pb-3">
                            <b-button @click="showModalCommercialPartners(row.item.customerCommercialPartner)"
                            style="width: 39px; height: 40px; background-color: #242323; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252; box-shadow: none;">
                                <span style="background-color: #EB694A; border-radius: 2px; color: #000; font-size: 14px; font-weight: 700;" class="px-1">
                                    {{ row.item.customerCommercialPartner.length }}
                                </span>
                            </b-button>
                        </div>
                    </div>
                </template>
                <template #cell(actions)="row">
                    <div class="d-flex justify-content-center align-items-center">
                        <div style="display: flex; justify-content: center; align-itens: center;" class="text-center pb-3">
                            <div style="display: flex; justify-content: center; align-items: center;">
                                <b-form-checkbox switch size="sm" class="mr-2" style="min-width: 80px;" v-model="row.item.status">
                                    {{ row.item.status ? 'Ativo' : 'Inativo' }}
                                </b-form-checkbox>
                                <b-button 
                                    style="width: 39px; height: 40px; background-color: #242323; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252; box-shadow: none;"
                                    @click="$router.push({ name: 'customerEdit', params: { id: row.item.id }})"
                                >
                                    <img src="@/assets/pencil.png" />
                                </b-button>
                            </div>
                        </div>
                    </div>
                </template>
            </b-table>

            <template #footer>
                <b-row align-h="center" align-v="center">
                    <DefaultButton
                    class="mr-2"
                    :disabled="page < 2"
                    @click="getCustomers(1)"
                    >
                        <span>
                            <b-icon icon="arrow-bar-left"></b-icon>
                            Primeira
                        </span>
                    </DefaultButton>
                    <DefaultButton
                    class="mr-2"
                    :disabled="page < 2"
                    @click="getCustomers(page-1)"
                    >
                        <span>
                            <b-icon icon="arrow-left"></b-icon>
                            Anterior
                        </span>
                    </DefaultButton>
                    <DefaultButton
                    class="mr-2"
                    :disabled="page === last_page"
                    @click="getCustomers(page+1)"
                    >
                        <span>
                            Próxima
                            <b-icon icon="arrow-right"></b-icon>
                        </span>
                    </DefaultButton>
                    <DefaultButton
                    :disabled="page === last_page"
                    @click="getCustomers(last_page)"
                    >
                        <span>
                            Última
                            <b-icon icon="arrow-bar-right"></b-icon>
                        </span>
                    </DefaultButton>
                </b-row>
            </template>
        </DefaultPageComponent>
        
        <card-modal title="Serviços" ref="modalCommercialPartners">
            <div class="row d-flex justify-content-center align-items-center">
                <div class="col mt-2">
                    <b-table striped borderless small sticky-header :fields="columnsCommercialPartner" :items="commercialPartnersInCustomer" style="max-height: 60vh !important;" show-empty empty-text="Não há dados disponíveis"></b-table>
                </div>
            </div>
        </card-modal>
    </div>  
</template>

<script>
// import DefaultHeader from '@/components/DefaultHeader.vue';
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import GlassButton from '@/components/GlassButton.vue';
import DefaultButton from '@/components/DefaultButton.vue';
import CardModal from './CardModal.vue';
export default {
    name: 'CustomerView',
    components: {
        // DefaultHeader,
        'card-modal': CardModal,
        DefaultPageComponent,
        DefaultButton,
        GlassButton,
    },
    data () {
        return {
            columns: [
                {
                    'n_document': 
                    {
                        label: 'CPF / CNPJ',
                    }
                },
                {
                    'first_name': 
                    {
                        label: 'Nome',
                    }
                },
                {
                    'last_name': 
                    {
                        label: 'Sobrenome',
                    }
                },
                {
                    'email': 
                    {
                        label: 'E-mail',
                    }
                },
                {
                    'phone': 
                    {
                        label: 'Telefone',
                        formatter: (value) => {
                            if(!value) return '';
                            return this.formatSimplePhone(value);
                        }
                    }
                },
                {
                    'group_name': 
                    {
                        label: 'WhatsApp Grupo',
                        class: 'text-left'
                    }
                },
                {
                    'customerCommercialPartnerCount': 
                    {
                        label: 'Serviços',
                    }
                },
                {
                    'actions': 
                    {
                        label: 'Ações',
                        class: 'text-right',
                    }
                },
            ],
            loading: false,
            customers: [],
            page: 1,
            last_page: 1,
            commercialPartners: [],
            filters: {
                filterBy: 'name',
                filter: '',
                customer_commercial_partner_id: '',
            },
            commercialPartnersInCustomer: [],
            columnsCommercialPartner: [
                {
                    'code': 
                    {
                        label: 'Código',
                        class: 'text-right pb-2'
                    }
                },
                {
                    'commercialPartner.trade_name': 
                    {
                        label: 'Serviços',
                        class: 'text-left pb-2'
                    }
                },
                {
                    'customerRelationshipAdvisor.hunter_master_name': 
                    {
                        label: 'Assessor Base',
                        class: 'text-left pb-2'
                    }
                },
                {
                    'customerRelationshipAdvisor.hunter_name': 
                    {
                        label: 'Assessor Finder',
                        class: 'text-left pb-2'
                    }
                },
                {
                    'customerRelationshipAdvisor.farmer_name': 
                    {
                        label: 'Assessor Final',
                        class: 'text-left pb-2'
                    }
                },
                // {
                //     'commercialPartner.openedDate': 
                //     {
                //         label: 'Data de abertura',
                //         class: 'text-left'
                //     }
                // },
                // {
                //     'commercialPartner.activeDate': 
                //     {
                //         label: 'Data de ativação',
                //         class: 'text-left'
                //     }
                // },
            ],
        }
    },
    mounted () {
        this.saveLog();
        this.getCommercialPartners();
        this.getCustomers(1);
    },
    methods: {
        saveLog () {
            let objLog = {
                description: 'Cadastro cliente'
            }

            this.$axios.post('logs', objLog).then((response) => {
                if(response.status != 201) {
                    console.log(response);
                }
            }).catch((error) => {
                console.log(error);
            });
        },
        async getCommercialPartners() {
            this.$axios.get('commercial-partner').then((response) => {
                this.commercialPartners = response.data.data;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar os parceiros comerciais.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            });
        },
        async getCustomers(page) {
            this.loading = true;

            this.page = page;

            let url = `customers`;

            if (this.filters.filterBy == 'name') {
                url += `?name=${this.filters.filter}`;
            } else {
                if (this.filters.filter.trim() != '')
                    url += `?n_document=${this.filters.filter.replace(/\D/g, '')}`;
            }

            if (this.filters.customer_commercial_partner_id !== '') {
                if (url !== `customers`)
                    url += `&customer_commercial_partner_id=${this.filters.customer_commercial_partner_id}`;
                else 
                    url += `?customer_commercial_partner_id=${this.filters.customer_commercial_partner_id}`;
            }

            if (url !== `customers`)
                url += `&page=${this.page}`;
            else
                url += `?page=${this.page}`;

            this.$axios.get(url).then((response) => {
                this.customers = response.data.data;
                this.page = response.data.meta.current_page;
                this.last_page = Math.ceil(response.data.meta.total / response.data.meta.per_page);
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar os clientes.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            }).finally(() => {
                this.loading = false;
            })
        },
        showModalCommercialPartners(items){
            if (items.length > 0) {
                this.commercialPartnersInCustomer = items;
                this.$refs.modalCommercialPartners.openModal();
            }
            return;
        },
        formatCPForCNPJ(value) {
            if (value && value.length === 11) {
                return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
            }
            else if (value && value.length===14){
                return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
            }
            return value;
        },
        formatPhone(phone) {
            if (phone == undefined)
                return "";
            let completePhone = phone.area_code + phone.number;
            if (completePhone && completePhone.length === 10) {
                return completePhone.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
            }
            if (completePhone && completePhone.length === 11) {
                return completePhone.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
            }
            return phone;
        },
        formatSimplePhone(phone) {
            if (phone == undefined)
                return "";
            if (phone && phone.length === 10) {
                return phone.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
            }
            if (phone && phone.length === 11) {
                return phone.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
            }
            return phone;
        },
    }
}
</script>

<style>
    #customer {
        height: calc(100vh - 125px);
    }

    .form-control {
        background-color: #262625 !important;
        border: 1px solid #535252 !important;
        color: #E5E2E1 !important;
        border-radius: 10px !important;
        padding-left: 20px !important;
        height:56px;
    }

    input::-webkit-input-placeholder {
        color: #E5E2E1 !important;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url('data:image/svg+xml;utf8,<svg fill="white" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
        background-repeat: no-repeat;
        background-position-x: calc(100% - 15px);
        background-position-y: center;
        padding-left: 20px !important;
    }
</style>
