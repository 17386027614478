<template>
    <div>
        <div v-if="loadingScreen">
            <form class=" p-4 mt-4 d-flex flex-column justify-content-between align-items-center" 
            style="background: #000; border-radius: 8px; border-width: 4px; border-style: solid; border-color: #AC5037; width: 370px;">
                <div class="text-center">
                    <img src="@/assets/logo.svg" alt="Logo WFLOW" height="48">
                </div>
                <b-row class="m-0 my-5 text-center" style="color:white; font-size: 18px; font-weight:600;">
                    <b-spinner style="color:white;"></b-spinner>
                </b-row>
            </form>
        </div>
        <div v-else-if="expired">
            <form class=" p-4 mt-4 d-flex flex-column justify-content-between align-items-center" 
            style="background: #000; border-radius: 8px; border-width: 4px; border-style: solid; border-color: #AC5037; width: 370px;">
                <div class="text-center">
                    <img src="@/assets/logo.svg" alt="Logo WFLOW" height="48">
                </div>
                <b-row class="m-0 my-4 text-center" style="color:white; font-size: 18px; font-weight:600;">
                    Este link de redefinição de senha é inválido ou expirado. Solicite um novo para continuar.
                </b-row>
                <CustomButton
                @click="$router.push({name: 'recoverPassword'})"
                :width="'100%'"
                > Solicitar novo link
                </CustomButton>
            </form>
        </div>

        <div v-else>
            <form class="pt-3 px-4 pb-4 mt-4 d-flex flex-column justify-content-between align-items-center" 
            style="background: #000; border-radius: 8px; border-width: 4px; border-style: solid; border-color: #AC5037; width: 370px;">
                <div class="text-center mb-3 mt-3">
                    <img class="mb-2" src="@/assets/logo.svg" alt="Logo WFLOW" height="48">
                </div>
                <b-row class="m-0 text-center" style="color:white; font-size: 20px; font-weight:600;">
                    Modificar a senha
                </b-row>
                <div class="form-label-group w-100 mt-4">
                    <input id="inputEmail" ref="inputEmail" type="email" class="form-control" v-model="credentials.email" disabled style="background-color: #FFFFFF70 !important;">
                    <label for="inputEmail" s>E-mail</label>
                </div>



                <b-col class="p-0 w-100">
                    <b-row class="m-0 w-100">
                        <div class="form-label-group w-100">
                            <input type="password" class="form-control form-control-sm" id="inputPassword" style="box-shadow: none; min-height:56px;" v-model="credentials.password">
                            <label for="inputPassword">Senha</label>
                        </div>
                    </b-row>
                    <b-row class="m-0 w-100">
                        <div class="form-label-group w-100">
                            <input type="password" class="form-control form-control-sm" id="inputConfirmPassword" style="box-shadow: none; min-height:56px;" v-model="credentials.confirmPassword">
                            <label for="inputConfirmPassword">Confirmar senha</label>
                        </div>
                    </b-row>
                </b-col>
                <b-row v-if="!isValidEqual && this.credentials.confirmPassword" class="mx-0 w-100" style="color: #D01F1F;" align-h="start">
                    <span style="transform:translateY(-10px);"> As senhas devem ser iguais </span>
                </b-row>
                <b-row class="mx-0 my-3 w-100">
                    <b-col>
                        <b-row>
                            <h3 class="form-subtitle" style="font-size:18px; font-weight:600; color: #5C5C62;"> Sua senha deve conter</h3>
                        </b-row>


                        <b-row v-if="isValidLength" style="color: #19B377">
                            <b-icon class="h5 mr-2" icon="check2"></b-icon><span style="transform: translateY(-2px)"> Mínimo 6 caracteres </span>
                        </b-row>
                        <b-row v-else style="color: #D01F1F">
                            <b-icon class="h5 mr-2" icon="x-circle"></b-icon><span style="transform: translateY(-2px)"> Mínimo 6 caracteres </span>
                        </b-row>


                        <b-row v-if="isValidNumber" style="color: #19B377">
                            <b-icon class="h5 mr-2" icon="check2"></b-icon><span style="transform: translateY(-2px)"> Pelo menos um número </span> 
                        </b-row>
                        <b-row v-else style="color: #D01F1F">
                            <b-icon class="h5 mr-2" icon="x-circle"></b-icon><span style="transform: translateY(-2px)"> Pelo menos um número </span> 
                        </b-row>


                        <b-row v-if="isValidSymbol" style="color: #19B377">
                            <b-icon class="h5 mr-2" icon="check2"></b-icon><span style="transform: translateY(-2px)"> Pelo menos um caractere especial </span> 
                        </b-row>
                        <b-row v-else style="color: #D01F1F">
                            <b-icon class="h5 mr-2" icon="x-circle"></b-icon><span style="transform: translateY(-2px)"> Pelo menos um caractere especial </span> 
                        </b-row>


                        <b-row v-if="isValidUpper" style="color: #19B377">
                            <b-icon class="h5 mr-2" icon="check2"></b-icon><span style="transform: translateY(-2px)"> Pelo menos uma letra maiúscula </span> 
                        </b-row>
                        <b-row v-else style="color: #D01F1F">
                            <b-icon class="h5 mr-2" icon="x-circle"></b-icon><span style="transform: translateY(-2px)"> Pelo menos uma letra maiúscula </span> 
                        </b-row>
                    </b-col>
                </b-row>



                <CustomButton
                :loading="loading"
                @click="handleSubmit"
                :disabled="!isValid"
                :readonly="!isValid"
                :width="'100%'"
                > Redefinir senha
                </CustomButton>
            </form>
        </div>
    </div>
</template>

<script>
import CustomButton from '@/components/CustomButton.vue'
import cookie from '@/service/cookie';
export default {
    name: "NewPasswordView",
    components: {
        CustomButton,
    },
    data(){
        return {
            token: null,
            expired: false,
            loadingScreen: false,
            loading: false,
            credentials: {
                email: null,
                password: null,
                confirmPassword: null,
            },
        }
    },
    computed: {
        isValidLength(){
            return (this.credentials.password?.length>=6);
        },
        isValidNumber(){
            return (this.credentials.password?.replace(/\D/g, '')?.length>0);
        },
        isValidSymbol(){
            return /\W|_/.test(this.credentials.password);
        },
        isValidUpper(){
            return /[A-Z]/.test(this.credentials.password);
        },
        isValidEqual(){
            return (this.credentials.password == this.credentials.confirmPassword);
        },
        isValid(){
            return (
                this.isValidLength &&
                this.isValidNumber &&
                this.isValidSymbol &&
                this.isValidUpper &&
                this.isValidEqual
            );
        },
    },
    created(){
        if(this.$router.currentRoute.params.token && this.$router.currentRoute.query['email']){
            this.token = this.$router.currentRoute.params.token;
            this.credentials.email = this.$router.currentRoute.query['email'];
        }
        else this.$router.push({name: 'login'})
        this.checkToken();
    },
    methods: {
        async checkToken(){
            try{
                this.loadingScreen=true;
                const response = await this.$axios.post('check-token', { token: this.token, email: this.credentials.email });
                console.log(response);
            }
            catch(error){
                this.expired=true;
            }
            finally{ this.loadingScreen=false; }
        },
        async handleSubmit(){
            try{
                this.loading=true;
                const response = await this.$axios.post('reset-password',{
                    token: this.token,
                    email: this.credentials.email,
                    password: this.credentials.password,
                });
                const token = `${response.data.token_type} ${response.data.access_token}`;
                cookie.setToken(token);
                this.$store.commit('user/STORE_USER', response.data.data);

                this.$swal({
                    title: 'Sucesso',
                    text: 'Redefinição de senha realizada com sucesso!',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                })
                .then(()=>{
                    this.saveLog();
                    this.$router.push({ name: 'dashboard' });
                })
            }
            catch(error){
                this.$swal({
                    title: 'Erro',
                    text: 'Este link de redefinição de senha é inválido ou expirado. Solicite um novo para continuar.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                })
            }
            finally{ this.loading=false; }

        },
        saveLog () {
            let objLog = {
                description: 'Login'
            }

            this.$axios.post('logs', objLog).then((response) => {
                if(response.status != 201) {
                    console.log(response);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    },
}
</script>

<style scoped>

</style>