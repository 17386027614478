<template>
    <b-modal id="imageModal" centered hide-footer hide-header>
        <b-col class="py-2" style="color:white !important; font-size:18px;">
            <!-- <p> Por favor, selecione uma imagem e posicione o rosto dentro da área pontilhada. </p>
            <ul>
                <li> Clique em "Selecionar imagem" para escolher uma foto do seu dispositivo. </li>
                <li> Use a área pontilhada como guia para centralizar o rosto. </li>
                <li> Certifique-se de que o rosto esteja totalmente visível dentro do círculo. </li>
            </ul> -->

            <b-row class="pt-3" align-h="center" align-v="center">
                <cropper
                ref="cropper"
                class="cropper"
                :stencil-props="{
                    previewClass: 'preview',
                    handlers: {},
                    resizable: false,
                    movable:false,
                    aspectRatio: 1,
                }"
                imageRestriction="fill-area"
                :src="image.src"
                @change="changeImage"
                />

                <input 
                style="display:none;"
                type="file"
                ref="imageInput"
                @change="loadImage"
                >
            </b-row>

            <b-row class="pb-2 mb-3" align-h="center">
                <b-row class="pt-2" style="max-width:350px; background-color: #00000060" align-h="between">
                    <b-button @click="zoom(2)" class="p-0 px-3 text-white" variant="link"> <b-icon icon="zoom-in"></b-icon> </b-button>
                    <b-button @click="zoom(0)" class="p-0 px-3 text-white" variant="link"> <b-icon icon="zoom-out"></b-icon> </b-button>
                    <b-button @click="move(0,-50)" style="font-size: 24px;" class="p-0 px-2 text-white" variant="link"> <b-icon icon="arrow-up-short"></b-icon> </b-button>
                    <b-button @click="move(0,50)" style="font-size: 24px;" class="p-0 px-2 text-white" variant="link"> <b-icon icon="arrow-down-short"></b-icon> </b-button>
                    <b-button @click="move(-50,0)" style="font-size: 24px;" class="p-0 px-2 text-white" variant="link"> <b-icon icon="arrow-left-short"></b-icon> </b-button>
                    <b-button @click="move(50,0)" style="font-size: 24px;" class="p-0 px-2 text-white" variant="link"> <b-icon icon="arrow-right-short"></b-icon> </b-button>
                </b-row>
            </b-row>

            <b-row class="mx-3" align-h="center" align-v="center">
                <b-col>
                    <CustomButton width="100%"
                    @click="openImage"
                    bgColor="#28A745"
                    borderColor="#28A745"
                    variant="success"
                    > Selecionar Imagem </CustomButton>
                </b-col>
            </b-row>

            <div class="py-2"></div>

            <b-row class="mx-3" align-h="center" align-v="center">
                <b-col>
                    <CustomButton width="100%"
                    @click="hide"
                    disabled
                    > Cancelar </CustomButton>
                </b-col>
                
                <b-col>
                    <CustomButton width="100%"
                    @click="save"
                    > Salvar </CustomButton>
                </b-col>
            </b-row>
        </b-col>
    </b-modal>
</template>

<script>
import CustomButton from './CustomButton.vue';
import { Cropper } from 'vue-advanced-cropper';

import 'vue-advanced-cropper/dist/style.css';

export default {
    name: 'ImageModal',
    components: {
        Cropper,
        CustomButton,
    },
    props:{
        imageProp: String,
    },
    data(){
        return {
            image: {
                type: null,
                src: null,
            },
            tempImage: null,
        }
    },
    mounted(){
        const id = setInterval(()=>{
            if(this.imageProp){
                clearInterval(id);
                this.image.src = this.imageProp;
                this.tempImage = this.imageProp;
            }
        }, 50)

        setTimeout(()=>{
            clearInterval(id);
        },2000)
    },
    methods:{
        changeImage(value){
            this.tempImage = value.canvas.toDataURL();
        },
        pickImage() {
			const { canvas } = this.$refs.cropper.getResult();
            var image = null;
			if (canvas) {
				canvas.toBlob(blob => {
                    image = blob;
				}, this.image.type);
                return image
			}
		},
        show(){
            this.$bvModal.show('imageModal');
        },
        hide(){
            this.$bvModal.hide('imageModal');
        },
        openImage(){
            this.$refs.imageInput.click();
        },
        save(){
            this.$emit('change', this.tempImage);
            this.hide();
        },
        zoom(value){
            this.$refs.cropper.zoom(value)
        },
        move(x, y){
            this.$refs.cropper.move(x, y)
        },
		reset() {
			this.image = {
				src: null,
				type: null
			}
		},
		loadImage(event) {
			// Reference to the DOM input element
			const { files } = event.target;
			// Ensure that you have a file before attempting to read it
			if (files && files[0]) {
				// 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
				if (this.image.src) {
					URL.revokeObjectURL(this.image.src)
				}
				// 2. Create the blob link to the file to optimize performance:
				const blob = URL.createObjectURL(files[0]);
				
				// 3. The steps below are designated to determine a file mime type to use it during the 
				// getting of a cropped image from the canvas. You can replace it them by the following string, 
				// but the type will be derived from the extension and it can lead to an incorrect result:
				//
				// this.image = {
				//    src: blob;
				//    type: files[0].type
				// }
				
				// Create a new FileReader to read this image binary data
				const reader = new FileReader();
				// Define a callback function to run, when FileReader finishes its job
				reader.onload = (e) => {
					// Note: arrow function used here, so that "this.image" refers to the image of Vue component
					this.image = {
						// Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
						src: blob,
						// Determine the image type to preserve it during the extracting the image from canvas:
						type: getMimeType(e.target.result, files[0].type),
					};
				};
				// Start the reader job - read file as a data url (base64 format)
				reader.readAsArrayBuffer(files[0]);
			}
		},
    },
    destroyed() {
		// Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
		if (this.image.src) {
			URL.revokeObjectURL(this.image.src)
		}
	}
}

function getMimeType(file, fallback = null) {
	const byteArray = (new Uint8Array(file)).subarray(0, 4);
    let header = '';
    for (let i = 0; i < byteArray.length; i++) {
       header += byteArray[i].toString(16);
    }
	switch (header) {
        case "89504e47":
            return "image/png";
        case "47494638":
            return "image/gif";
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
            return "image/jpeg";
        default:
            return fallback;
    }
}

</script>

<style scoped>
* /deep/ .modal-body{
    background-color:#1C1B1B !important;
}

.cropper {
	height: 350px;
	width: 350px;
	background: #262625;
    cursor: move;
}

.preview {
    cursor: move;
    border: 3px dashed #28A745;
}
</style>