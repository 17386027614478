<template>
    <div id="goals" style="height:100%; width:100%;">
        <b-col class="px-0 mx-0" style="height:100%; width:100%;">
            <DefaultPageComponent
            :loading="loading"
            title="Regras pendentes"
            :haveBackButton="true"
            @onBack="$router.push({name: 'dashboard'})"
            :haveContentMargin="true"
            >

            <!-- CONTENT -->
            <b-col class="p-4" style="border-radius:10px; border: 1px solid #555; min-height:100%;">

                <b-row class="m-0 pb-3">
                    <span style="font-size:18px; font-weight:500;">Custódia não mapeada</span>
                </b-row>
                <b-table class="custom-table" borderless sticky-header :fields="columns" :items="data?.net" show-empty>
                    <template #empty="">
                        <b-col class="text-center pb-3">
                            Não existem regras pendentes
                        </b-col>
                    </template>

                    <template #cell(classification)="row">
                        <div>
                            <b-form-select @change="forceRender(); row.item.subclassification='';"
                            class="form-control" style="min-height:56px;"
                            v-model="row.item.classification"
                            >
                                <option disabled value=""> Selecione </option>
                                <option v-for="item,idx in classifications.filter(c=>c.type=='CLASSIFICATION')" :key="idx" 
                                    :value="item"> {{ item.name }} </option>
                            </b-form-select>
                        </div>
                    </template>
                    <template #cell(subclassification)="row">
                        <div v-if="renderSelect">
                            <b-form-select @change="verifyForm"
                            v-model="row.item.subclassification"
                            class="form-control" style="min-height:56px;"
                            :disabled="!row.item.classification || classifications.filter(c=>c.type=='SUBCLASSIFICATION' && c?.classification_id==row.item.classification?.classification_id).length==0"
                            :style="!row.item.classification || classifications.filter(c=>c.type=='SUBCLASSIFICATION' && c?.classification_id==row.item.classification?.classification_id).length==0 ? 'opacity:0.5;' : ''"
                            >
                                <option disabled value=""> Selecione </option>
                                <option v-for="item,idx in classifications.filter(c=>c.type=='SUBCLASSIFICATION' && c?.classification_id==row.item.classification?.classification_id)" :key="idx" 
                                    :value="item"> {{ item.name }} </option>
                            </b-form-select>
                        </div>
                    </template>
                </b-table>

                <div class="py-3"></div>

                <b-row class="m-0 pb-3">
                    <span style="font-size:18px; font-weight:500;">Receita não mapeada</span>
                </b-row>
                <b-table class="custom-table" borderless sticky-header :fields="columns" :items="data?.receives" show-empty>
                    <template #empty="">
                        <b-col class="text-center pb-3">
                            Não existem regras pendentes
                        </b-col>
                    </template>

                    <template #cell(classification)="row">
                        <div>
                            <b-form-select @change="forceRender(); row.item.subclassification='';"
                            class="form-control" style="min-height:56px;"
                            v-model="row.item.classification"
                            >
                                <option disabled value=""> Selecione </option>
                                <option v-for="item,idx in classifications.filter(c=>c.type=='CLASSIFICATION')" :key="idx" 
                                    :value="item"> {{ item.name }} </option>
                            </b-form-select>
                        </div>
                    </template>
                    <template #cell(subclassification)="row">
                        <div v-if="renderSelect">
                            <b-form-select @change="verifyForm"
                            v-model="row.item.subclassification"
                            class="form-control" style="min-height:56px;"
                            :disabled="!row.item.classification || classifications.filter(c=>c.type=='SUBCLASSIFICATION' && c?.classification_id==row.item.classification?.classification_id).length==0"
                            :style="!row.item.classification || classifications.filter(c=>c.type=='SUBCLASSIFICATION' && c?.classification_id==row.item.classification?.classification_id).length==0 ? 'opacity:0.5;' : ''"
                            >
                                <option disabled value=""> Selecione </option>
                                <option v-for="item,idx in classifications.filter(c=>c.type=='SUBCLASSIFICATION' && c?.classification_id==row.item.classification?.classification_id)" :key="idx" 
                                    :value="item"> {{ item.name }} </option>
                            </b-form-select>
                        </div>
                    </template>
                </b-table>
            </b-col>

            <template #footer>
                <b-row class="m-0" align-h="end">
                    <CustomButton v-if="renderSelect"
                    :disabled="!isFormValid"
                    @click="save"
                    > Definir regras
                    </CustomButton>
                </b-row>
            </template>

            </DefaultPageComponent>

        </b-col>
    </div>
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import CustomButton from '@/components/CustomButton.vue';
export default {
    name: "PendingRulesView",
    components:{
        DefaultPageComponent,
        CustomButton,
    },
    data(){
        return {
            loading: false,
            loadingSubmit: false,
            renderSelect: true,
            isFormValid: false,
            data: null,
            classifications: [],
            columns: [
                {
                    'product': 
                    {
                        label: 'Produto',
                        class: 'text-left',
                        formatter: (value)=>{
                            return value || '---'
                        }
                    }
                },
                {
                    'subproduct': 
                    {
                        label: 'Sub produto',
                        class: 'text-left',
                        formatter: (value)=>{
                            return value || '---'
                        }
                    }
                },
                {
                    'n_document_fund': 
                    {
                        label: 'CNPJ',
                        class: 'text-left',
                        formatter: (value)=>{
                            return value || '---'
                        }
                    }
                },
                {
                    'active': 
                    {
                        label: 'Ativo',
                        class: 'text-left',
                        formatter: (value)=>{
                            return value || '---'
                        }
                    }
                },
                {
                    'issuer': 
                    {
                        label: 'Emissor',
                        class: 'text-left',
                        formatter: (value)=>{
                            return value || '---'
                        }
                    }
                },
                {
                    'classification': 
                    {
                        label: 'Classificação',
                        class: 'text-left',
                        thStyle: {
                            'max-width':'300px', 
                            'width':'300px', 
                            'min-width':'300px',
                        }
                    }
                },
                {
                    'subclassification': 
                    {
                        label: 'Subclassificação',
                        class: 'text-left',
                        thStyle: {
                            'max-width':'300px', 
                            'width':'300px', 
                            'min-width':'300px',
                        }
                    }
                },
                // {
                //     'actions': 
                //     {
                //         label: 'Ações',
                //         class: 'text-right',
                //     }
                // },
            ],
        }
    },
    created(){
        this.getClassifications();
        this.getData();
    },
    // computed:{
    //     isDisabled(){
    //         return this.loading || this.loadingSubmit || !this.file;
    //     },
    // },
    methods: {
        verifyForm(){
            this.isFormValid = (this.data.net.some(e=>e.classification && e.subclassification) || this.data.receives.some(e=>e.classification && e.subclassification));
        },
        async forceRender(){
            this.renderSelect=false;
            await this.$nextTick();
            this.renderSelect=true;
            this.verifyForm();
        },
        async getData(){
            try{
                this.loading=true;
                var endpoint = 'diversifications/pending-rules';
                // if(this.selectedYear) endpoint+='?year='+this.selectedYear;
                // if(this.advisor) endpoint+='&advisor='+this.advisor;
                // if(this.cell) endpoint+='&cell='+this.cell;

                const response = await this.$axios.get(endpoint);
                this.data = response.data;
                this.data.net.forEach(e=>{
                    e.classification='';
                    e.subclassification='';
                })
                this.data.receives.forEach(e=>{ 
                    e.classification='';
                    e.subclassification='';
                })
            }
            catch(error){
                console.log(error);
            }
            finally{ this.loading=false; }
        },
        async getClassifications(){
            const response = await this.$axios.get('classifications');
            this.classifications = response.data.filter(e=>e.name.trim().toUpperCase()!='RECEITAS NÃO MAPEADAS' && e.name.trim().toUpperCase()!='CUSTÓDIAS NÃO MAPEADAS');
            this.classifications.sort((a,b)=>a.position-b.position);
        },
        async save(){
            if(!this.isFormValid) {
                this.$swal({
                    title: 'Atenção',
                    text: 'A importação do arquivo é obrigatória.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return false;
            }
            try{
                this.loading = true;
                this.loadingSubmit = true;

                var temp = [];
                
                temp.push(
                    ...this.data.net.filter(e=>e.classification&&e.subclassification),
                    ...this.data.receives.filter(e=>e.classification&&e.subclassification),
                )
                
                var list = temp.map(e=>{
                    return {
                        ids: e.ids.includes(',') ? e.ids.split(',') : [e.ids],
                        classification_id: e.classification.classification_id,
                        sub_classification_id: e.subclassification.subclassification_id,
                        product: e.product,
                        subproduct: e.subproduct,
                        n_document_fund: e.n_document_fund,
                        active: e.active,
                        issuer: e.issuer,
                    }
                });

                const responses = list.map(async e=>{
                    const response = await this.$axios.put('diversifications/new-rule', e);
                    response;
                })
                
                await Promise.all([...responses])

                this.$swal({
                    title: 'Sucesso',
                    text: 'Definição de regras realizada com sucesso.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                    focusConfirm: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });

                this.getData();
                this.getClassifications();
            }
            catch(error){
                console.log(error);
                this.$swal({
                    title: 'Atenção',
                    text: 'Houve um erro com ao definir as regras!',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            }
            finally {
                this.loading = false;
                this.loadingSubmit = false;
            }
        },
    },
}
</script>

<style scoped>
/* * /deep/ .modal-card{
    max-width:fit-content !important;
} */
</style>