<template>
    <div style="height: 100%;">
        <DefaultPageComponent
        :loading="loading"
        :title="'Células'"
        :haveBackButton="true"
        @onBack="$router.push({name: 'dashboard'})"
        :haveNextButton="true"
        :buttonText="'Nova célula'"
        @onTap="$router.push({name: 'cellsNew'})"
        :haveContentMargin="true"
        >

        <template #filters>
            <div class="row d-flex justify-content-center align-items-center">
                <div class="col-6">
                    <div class="form-group">
                        <label for="inputUserName">Célula</label>
                        <input type="email" class="form-control form-control-sm" id="inputUserName" style="box-shadow: none; min-height:56px;">
                    </div>
                </div>
                <b-col></b-col>
                <div class="col-md-3 d-flex justify-content-end">
                    <GlassButton
                    :loading="loading"
                    @click="()=>{}"
                    > 
                        <img src="@/assets/search.png" />
                        Pesquisar
                    </GlassButton>
                </div>
            </div>
        </template>

        <!-- CONTENT -->
        <b-table class="custom-table" borderless sticky-header :fields="columns" :items="cells" show-empty empty-text="Sem registros para mostrar no momento...">
            <template #cell(actions)="row">
                <div class="d-flex justify-content-end align-items-center">
                    <b-button 
                        style="color: #EB694A; width: 39px; height: 40px; background-color: #242323; display: flex; justify-content: center; align-items: center; font-size: 0.875rem; border-color: #535252; box-shadow: none;"
                        @click="editCell(row.item.id)"
                    >
                        <b-icon icon="pencil-fill"></b-icon>
                    </b-button>
                </div>
            </template>
        </b-table>

        <template #footer>
            <div></div>
        </template>

        </DefaultPageComponent>
    </div>
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import GlassButton from '@/components/GlassButton.vue';
export default {
    name: 'CellView',
    components:{
        DefaultPageComponent,
        GlassButton,
    },
    mounted() {
        this.saveLog();
        this.getCells();
    },
    data() {
        return {
            loading: false,
            columns: [
                {
                    'id': 
                    {
                        label: 'ID',
                        class: 'text-left'
                    }
                },
                {
                    'cell': 
                    {
                        label: 'Célula',
                        class: 'text-left'
                    }
                },
                {
                    'description': 
                    {
                        label: 'Descrição',
                        class: 'text-left'
                    }
                },
                {
                    'advisor_name': 
                    {
                        label: 'Líder',
                        class: 'text-left'
                    }
                },
                {
                    'ACTIONS': 
                    {
                        label: '',
                        class: 'text-right',
                    }
                },
            ],
            cells: [],
        }
    },
    methods: {
        saveLog () {
            let objLog = {
                description: 'Cadastro célula'
            }

            this.$axios.post('logs', objLog).then((response) => {
                if(response.status != 201) {
                    console.log(response);
                }
            }).catch((error) => {
                console.log(error);
            });
        },
        getCells() {
            this.loading=true;
            this.$axios.get('cells').then((response) => {
                this.cells = response.data.data;
            }).catch(() => {
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar células.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
                return;
            })
            .finally(()=>{
                this.loading=false;
            })
        },
        editCell(cellID) {
            this.$router.push({ name: 'cellsEdit', params: { id: cellID }});
        },
    }
}
</script>

<style>
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff !important;
    border-color: #28A745 !important;
    background-color: #28A745 !important;
    box-shadow: none !important;
}

.custom-control-input:not(checked) ~ .custom-control-label::before {
    border-color: #CCC !important;
    box-shadow: none !important;
}

tr:nth-child(odd) {
    background-color: #DDD !important;
}
</style>
